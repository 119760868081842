/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import { BaseLicense } from '@/models/license.schema';
import { StoreService } from '@/common/api';
import { RemovableRef, StorageSerializers, useLocalStorage } from '@vueuse/core';

export const useLicenseStore = defineStore('license', {
  state: () => ({
    license: useLocalStorage('state.license', null as BaseLicense | null, { serializer: StorageSerializers.object }) as RemovableRef<BaseLicense | null>,
  }),

  actions: {
    async getLicense({ organizationUuid }: Record<string, unknown>) {
      const { data } = await StoreService.get(`processes/organizations/${organizationUuid}/license`);
      this.license = data;
    },

    reset() {
      this.license = null;
    },
  },
});
