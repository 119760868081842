/* eslint-disable no-underscore-dangle */
import iconFiles from '@/assets/icons/active/files.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import i18n from '@/plugins/i18n';
import EmitterMixin from '@ckeditor/ckeditor5-utils/src/emittermixin';
import mix from '@ckeditor/ckeditor5-utils/src/mix';

export default class CbmFileUploadPlugin extends Plugin {
  init() {
    const { editor } = this;

    const options = editor.config.get('cbmFileUpload');

    if (!options) {
      return;
    }

    if (!options.botUuid) {
      console.warn('cbm-file-upload-adapter-missing-botuuid');
      return;
    }

    editor.ui.componentFactory.add('fileUpload', () => {
      const button = new ButtonView();

      button.label = i18n.t('labels.files');
      button.icon = iconFiles;
      button.tooltip = true;
      button.withText = true;
      button.class = 'c-editor__button--custom';

      this.listenTo(button, 'execute', () => {
        editor.model.document.fire('fileUpload');
      });

      return button;
    });
  }
}

mix(CbmFileUploadPlugin, EmitterMixin);
