
import { defineComponent } from 'vue';

/**
 * Inspired from https://github.com/primefaces/primevue/blob/master/src/components/avatar/Avatar.vue.
 */
export default defineComponent({
  props: {
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'base',
    },
    shape: {
      type: String,
      default: 'circle', // square, circle
    },

    clickable: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: String,
      default: null,
    },
  },

  computed: {
    containerClass(): (string | Record<string, boolean>)[] {
      return ['ui-avatar', {
        'ui-avatar--image': this.image !== null,
        'ui-avatar--circle': this.shape === 'circle',
        'ui-avatar--sm': this.size === 'sm',
        'ui-avatar--lg': this.size === 'lg',
        'ui-avatar--xl': this.size === 'xl',
        'ui-avatar--clickable': this.clickable,
      }];
    },
  },
});
