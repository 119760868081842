// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function SpecialCharactersExtended(editor: any) {
  editor.plugins.get('SpecialCharacters').addItems('Emojis', [
    {
      character: '😀',
      title: 'grinning face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😃',
      title: 'grinning face with big eyes',
      category: 'Smileys & Emotion',
    },
    {
      character: '😄',
      title: 'grinning face with smiling eyes',
      category: 'Smileys & Emotion',
    },
    {
      character: '😁',
      title: 'beaming face with smiling eyes',
      category: 'Smileys & Emotion',
    },
    {
      character: '😆',
      title: 'grinning squinting face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😅',
      title: 'grinning face with sweat',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤣',
      title: 'rolling on the floor laughing',
      category: 'Smileys & Emotion',
    },
    {
      character: '😂',
      title: 'face with tears of joy',
      category: 'Smileys & Emotion',
    },
    {
      character: '🙂',
      title: 'slightly smiling face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🙃',
      title: 'upside-down face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🫠',
      title: 'melting face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😉',
      title: 'winking face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😊',
      title: 'smiling face with smiling eyes',
      category: 'Smileys & Emotion',
    },
    {
      character: '😇',
      title: 'smiling face with halo',
      category: 'Smileys & Emotion',
    },
    {
      character: '🥰',
      title: 'smiling face with hearts',
      category: 'Smileys & Emotion',
    },
    {
      character: '😍',
      title: 'smiling face with heart-eyes',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤩',
      title: 'star-struck',
      category: 'Smileys & Emotion',
    },
    {
      character: '😘',
      title: 'face blowing a kiss',
      category: 'Smileys & Emotion',
    },
    {
      character: '😗',
      title: 'kissing face',
      category: 'Smileys & Emotion',
    },
    {
      character: '☺️',
      title: 'smiling face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😚',
      title: 'kissing face with closed eyes',
      category: 'Smileys & Emotion',
    },
    {
      character: '😙',
      title: 'kissing face with smiling eyes',
      category: 'Smileys & Emotion',
    },
    {
      character: '🥲',
      title: 'smiling face with tear',
      category: 'Smileys & Emotion',
    },
    {
      character: '😋',
      title: 'face savoring food',
      category: 'Smileys & Emotion',
    },
    {
      character: '😛',
      title: 'face with tongue',
      category: 'Smileys & Emotion',
    },
    {
      character: '😜',
      title: 'winking face with tongue',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤪',
      title: 'zany face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😝',
      title: 'squinting face with tongue',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤑',
      title: 'money-mouth face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤗',
      title: 'smiling face with open hands',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤭',
      title: 'face with hand over mouth',
      category: 'Smileys & Emotion',
    },
    {
      character: '🫢',
      title: 'face with open eyes and hand over mouth',
      category: 'Smileys & Emotion',
    },
    {
      character: '🫣',
      title: 'face with peeking eye',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤫',
      title: 'shushing face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤔',
      title: 'thinking face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🫡',
      title: 'saluting face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤐',
      title: 'zipper-mouth face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤨',
      title: 'face with raised eyebrow',
      category: 'Smileys & Emotion',
    },
    {
      character: '😐',
      title: 'neutral face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😑',
      title: 'expressionless face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😶',
      title: 'face without mouth',
      category: 'Smileys & Emotion',
    },
    {
      character: '🫥',
      title: 'dotted line face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😶‍🌫️',
      title: 'face in clouds',
      category: 'Smileys & Emotion',
    },
    {
      character: '😏',
      title: 'smirking face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😒',
      title: 'unamused face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🙄',
      title: 'face with rolling eyes',
      category: 'Smileys & Emotion',
    },
    {
      character: '😬',
      title: 'grimacing face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😮‍💨',
      title: 'face exhaling',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤥',
      title: 'lying face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😌',
      title: 'relieved face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😔',
      title: 'pensive face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😪',
      title: 'sleepy face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤤',
      title: 'drooling face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😴',
      title: 'sleeping face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😷',
      title: 'face with medical mask',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤒',
      title: 'face with thermometer',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤕',
      title: 'face with head-bandage',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤢',
      title: 'nauseated face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤮',
      title: 'face vomiting',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤧',
      title: 'sneezing face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🥵',
      title: 'hot face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🥶',
      title: 'cold face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🥴',
      title: 'woozy face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😵',
      title: 'face with crossed-out eyes',
      category: 'Smileys & Emotion',
    },
    {
      character: '😵‍💫',
      title: 'face with spiral eyes',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤯',
      title: 'exploding head',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤠',
      title: 'cowboy hat face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🥳',
      title: 'partying face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🥸',
      title: 'disguised face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😎',
      title: 'smiling face with sunglasses',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤓',
      title: 'nerd face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🧐',
      title: 'face with monocle',
      category: 'Smileys & Emotion',
    },
    {
      character: '😕',
      title: 'confused face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🫤',
      title: 'face with diagonal mouth',
      category: 'Smileys & Emotion',
    },
    {
      character: '😟',
      title: 'worried face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🙁',
      title: 'slightly frowning face',
      category: 'Smileys & Emotion',
    },
    {
      character: '☹️',
      title: 'frowning face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😮',
      title: 'face with open mouth',
      category: 'Smileys & Emotion',
    },
    {
      character: '😯',
      title: 'hushed face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😲',
      title: 'astonished face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😳',
      title: 'flushed face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🥺',
      title: 'pleading face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🥹',
      title: 'face holding back tears',
      category: 'Smileys & Emotion',
    },
    {
      character: '😦',
      title: 'frowning face with open mouth',
      category: 'Smileys & Emotion',
    },
    {
      character: '😧',
      title: 'anguished face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😨',
      title: 'fearful face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😰',
      title: 'anxious face with sweat',
      category: 'Smileys & Emotion',
    },
    {
      character: '😥',
      title: 'sad but relieved face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😢',
      title: 'crying face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😭',
      title: 'loudly crying face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😱',
      title: 'face screaming in fear',
      category: 'Smileys & Emotion',
    },
    {
      character: '😖',
      title: 'confounded face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😣',
      title: 'persevering face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😞',
      title: 'disappointed face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😓',
      title: 'downcast face with sweat',
      category: 'Smileys & Emotion',
    },
    {
      character: '😩',
      title: 'weary face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😫',
      title: 'tired face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🥱',
      title: 'yawning face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😤',
      title: 'face with steam from nose',
      category: 'Smileys & Emotion',
    },
    {
      character: '😡',
      title: 'pouting face',
      category: 'Smileys & Emotion',
    },
    {
      character: '😠',
      title: 'angry face',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤬',
      title: 'face with symbols on mouth',
      category: 'Smileys & Emotion',
    },
    {
      character: '😈',
      title: 'smiling face with horns',
      category: 'Smileys & Emotion',
    },
    {
      character: '👿',
      title: 'angry face with horns',
      category: 'Smileys & Emotion',
    },
    {
      character: '💀',
      title: 'skull',
      category: 'Smileys & Emotion',
    },
    {
      character: '☠️',
      title: 'skull and crossbones',
      category: 'Smileys & Emotion',
    },
    {
      character: '💩',
      title: 'pile of poo',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤡',
      title: 'clown face',
      category: 'Smileys & Emotion',
    },
    {
      character: '👹',
      title: 'ogre',
      category: 'Smileys & Emotion',
    },
    {
      character: '👺',
      title: 'goblin',
      category: 'Smileys & Emotion',
    },
    {
      character: '👻',
      title: 'ghost',
      category: 'Smileys & Emotion',
    },
    {
      character: '👽',
      title: 'alien',
      category: 'Smileys & Emotion',
    },
    {
      character: '👾',
      title: 'alien monster',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤖',
      title: 'robot',
      category: 'Smileys & Emotion',
    },
    {
      character: '😺',
      title: 'grinning cat',
      category: 'Smileys & Emotion',
    },
    {
      character: '😸',
      title: 'grinning cat with smiling eyes',
      category: 'Smileys & Emotion',
    },
    {
      character: '😹',
      title: 'cat with tears of joy',
      category: 'Smileys & Emotion',
    },
    {
      character: '😻',
      title: 'smiling cat with heart-eyes',
      category: 'Smileys & Emotion',
    },
    {
      character: '😼',
      title: 'cat with wry smile',
      category: 'Smileys & Emotion',
    },
    {
      character: '😽',
      title: 'kissing cat',
      category: 'Smileys & Emotion',
    },
    {
      character: '🙀',
      title: 'weary cat',
      category: 'Smileys & Emotion',
    },
    {
      character: '😿',
      title: 'crying cat',
      category: 'Smileys & Emotion',
    },
    {
      character: '😾',
      title: 'pouting cat',
      category: 'Smileys & Emotion',
    },
    {
      character: '🙈',
      title: 'see-no-evil monkey',
      category: 'Smileys & Emotion',
    },
    {
      character: '🙉',
      title: 'hear-no-evil monkey',
      category: 'Smileys & Emotion',
    },
    {
      character: '🙊',
      title: 'speak-no-evil monkey',
      category: 'Smileys & Emotion',
    },
    {
      character: '💋',
      title: 'kiss mark',
      category: 'Smileys & Emotion',
    },
    {
      character: '💌',
      title: 'love letter',
      category: 'Smileys & Emotion',
    },
    {
      character: '💘',
      title: 'heart with arrow',
      category: 'Smileys & Emotion',
    },
    {
      character: '💝',
      title: 'heart with ribbon',
      category: 'Smileys & Emotion',
    },
    {
      character: '💖',
      title: 'sparkling heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '💗',
      title: 'growing heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '💓',
      title: 'beating heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '💞',
      title: 'revolving hearts',
      category: 'Smileys & Emotion',
    },
    {
      character: '💕',
      title: 'two hearts',
      category: 'Smileys & Emotion',
    },
    {
      character: '💟',
      title: 'heart decoration',
      category: 'Smileys & Emotion',
    },
    {
      character: '❣️',
      title: 'heart exclamation',
      category: 'Smileys & Emotion',
    },
    {
      character: '💔',
      title: 'broken heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '❤️‍🔥',
      title: 'heart on fire',
      category: 'Smileys & Emotion',
    },
    {
      character: '❤️‍🩹',
      title: 'mending heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '❤️',
      title: 'red heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '🧡',
      title: 'orange heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '💛',
      title: 'yellow heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '💚',
      title: 'green heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '💙',
      title: 'blue heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '💜',
      title: 'purple heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤎',
      title: 'brown heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '🖤',
      title: 'black heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '🤍',
      title: 'white heart',
      category: 'Smileys & Emotion',
    },
    {
      character: '💯',
      title: 'hundred points',
      category: 'Smileys & Emotion',
    },
    {
      character: '💢',
      title: 'anger symbol',
      category: 'Smileys & Emotion',
    },
    {
      character: '💥',
      title: 'collision',
      category: 'Smileys & Emotion',
    },
    {
      character: '💫',
      title: 'dizzy',
      category: 'Smileys & Emotion',
    },
    {
      character: '💦',
      title: 'sweat droplets',
      category: 'Smileys & Emotion',
    },
    {
      character: '💨',
      title: 'dashing away',
      category: 'Smileys & Emotion',
    },
    {
      character: '🕳️',
      title: 'hole',
      category: 'Smileys & Emotion',
    },
    {
      character: '💣',
      title: 'bomb',
      category: 'Smileys & Emotion',
    },
    {
      character: '💬',
      title: 'speech balloon',
      category: 'Smileys & Emotion',
    },
    {
      character: '👁️‍🗨️',
      title: 'eye in speech bubble',
      category: 'Smileys & Emotion',
    },
    {
      character: '🗨️',
      title: 'left speech bubble',
      category: 'Smileys & Emotion',
    },
    {
      character: '🗯️',
      title: 'right anger bubble',
      category: 'Smileys & Emotion',
    },
    {
      character: '💭',
      title: 'thought balloon',
      category: 'Smileys & Emotion',
    },
    {
      character: '💤',
      title: 'zzz',
      category: 'Smileys & Emotion',
    },
    /* {
      character: '👋',
      title: 'waving hand',
      category: 'People & Body',
    },
    {
      character: '🤚',
      title: 'raised back of hand',
      category: 'People & Body',
    },
    {
      character: '🖐️',
      title: 'hand with fingers splayed',
      category: 'People & Body',
    },
    {
      character: '✋',
      title: 'raised hand',
      category: 'People & Body',
    },
    {
      character: '🖖',
      title: 'vulcan salute',
      category: 'People & Body',
    },
    {
      character: '🫱',
      title: 'rightwards hand',
      category: 'People & Body',
    },
    {
      character: '🫲',
      title: 'leftwards hand',
      category: 'People & Body',
    },
    {
      character: '🫳',
      title: 'palm down hand',
      category: 'People & Body',
    },
    {
      character: '🫴',
      title: 'palm up hand',
      category: 'People & Body',
    },
    {
      character: '👌',
      title: 'OK hand',
      category: 'People & Body',
    },
    {
      character: '🤌',
      title: 'pinched fingers',
      category: 'People & Body',
    },
    {
      character: '🤏',
      title: 'pinching hand',
      category: 'People & Body',
    },
    {
      character: '✌️',
      title: 'victory hand',
      category: 'People & Body',
    },
    {
      character: '🤞',
      title: 'crossed fingers',
      category: 'People & Body',
    },
    {
      character: '🫰',
      title: 'hand with index finger and thumb crossed',
      category: 'People & Body',
    },
    {
      character: '🤟',
      title: 'love-you gesture',
      category: 'People & Body',
    },
    {
      character: '🤘',
      title: 'sign of the horns',
      category: 'People & Body',
    },
    {
      character: '🤙',
      title: 'call me hand',
      category: 'People & Body',
    },
    {
      character: '👈',
      title: 'backhand index pointing left',
      category: 'People & Body',
    },
    {
      character: '👉',
      title: 'backhand index pointing right',
      category: 'People & Body',
    },
    {
      character: '👆',
      title: 'backhand index pointing up',
      category: 'People & Body',
    },
    {
      character: '🖕',
      title: 'middle finger',
      category: 'People & Body',
    },
    {
      character: '👇',
      title: 'backhand index pointing down',
      category: 'People & Body',
    },
    {
      character: '☝️',
      title: 'index pointing up',
      category: 'People & Body',
    },
    {
      character: '🫵',
      title: 'index pointing at the viewer',
      category: 'People & Body',
    },
    {
      character: '👍',
      title: 'thumbs up',
      category: 'People & Body',
    },
    {
      character: '👎',
      title: 'thumbs down',
      category: 'People & Body',
    },
    {
      character: '✊',
      title: 'raised fist',
      category: 'People & Body',
    },
    {
      character: '👊',
      title: 'oncoming fist',
      category: 'People & Body',
    },
    {
      character: '🤛',
      title: 'left-facing fist',
      category: 'People & Body',
    },
    {
      character: '🤜',
      title: 'right-facing fist',
      category: 'People & Body',
    },
    {
      character: '👏',
      title: 'clapping hands',
      category: 'People & Body',
    },
    {
      character: '🙌',
      title: 'raising hands',
      category: 'People & Body',
    },
    {
      character: '🫶',
      title: 'heart hands',
      category: 'People & Body',
    },
    {
      character: '👐',
      title: 'open hands',
      category: 'People & Body',
    },
    {
      character: '🤲',
      title: 'palms up together',
      category: 'People & Body',
    },
    {
      character: '🤝',
      title: 'handshake',
      category: 'People & Body',
    },
    {
      character: '🙏',
      title: 'folded hands',
      category: 'People & Body',
    },
    {
      character: '✍️',
      title: 'writing hand',
      category: 'People & Body',
    },
    {
      character: '💅',
      title: 'nail polish',
      category: 'People & Body',
    },
    {
      character: '🤳',
      title: 'selfie',
      category: 'People & Body',
    },
    {
      character: '💪',
      title: 'flexed biceps',
      category: 'People & Body',
    },
    {
      character: '🦾',
      title: 'mechanical arm',
      category: 'People & Body',
    },
    {
      character: '🦿',
      title: 'mechanical leg',
      category: 'People & Body',
    },
    {
      character: '🦵',
      title: 'leg',
      category: 'People & Body',
    },
    {
      character: '🦶',
      title: 'foot',
      category: 'People & Body',
    },
    {
      character: '👂',
      title: 'ear',
      category: 'People & Body',
    },
    {
      character: '🦻',
      title: 'ear with hearing aid',
      category: 'People & Body',
    },
    {
      character: '👃',
      title: 'nose',
      category: 'People & Body',
    },
    {
      character: '🧠',
      title: 'brain',
      category: 'People & Body',
    },
    {
      character: '🫀',
      title: 'anatomical heart',
      category: 'People & Body',
    },
    {
      character: '🫁',
      title: 'lungs',
      category: 'People & Body',
    },
    {
      character: '🦷',
      title: 'tooth',
      category: 'People & Body',
    },
    {
      character: '🦴',
      title: 'bone',
      category: 'People & Body',
    },
    {
      character: '👀',
      title: 'eyes',
      category: 'People & Body',
    },
    {
      character: '👁️',
      title: 'eye',
      category: 'People & Body',
    },
    {
      character: '👅',
      title: 'tongue',
      category: 'People & Body',
    },
    {
      character: '👄',
      title: 'mouth',
      category: 'People & Body',
    },
    {
      character: '🫦',
      title: 'biting lip',
      category: 'People & Body',
    },
    {
      character: '👶',
      title: 'baby',
      category: 'People & Body',
    },
    {
      character: '🧒',
      title: 'child',
      category: 'People & Body',
    },
    {
      character: '👦',
      title: 'boy',
      category: 'People & Body',
    },
    {
      character: '👧',
      title: 'girl',
      category: 'People & Body',
    },
    {
      character: '🧑',
      title: 'person',
      category: 'People & Body',
    },
    {
      character: '👱',
      title: 'person: blond hair',
      category: 'People & Body',
    },
    {
      character: '👨',
      title: 'man',
      category: 'People & Body',
    },
    {
      character: '🧔',
      title: 'person: beard',
      category: 'People & Body',
    },
    {
      character: '🧔‍♂️',
      title: 'man: beard',
      category: 'People & Body',
    },
    {
      character: '🧔‍♀️',
      title: 'woman: beard',
      category: 'People & Body',
    },
    {
      character: '👨‍🦰',
      title: 'man: red hair',
      category: 'People & Body',
    },
    {
      character: '👨‍🦱',
      title: 'man: curly hair',
      category: 'People & Body',
    },
    {
      character: '👨‍🦳',
      title: 'man: white hair',
      category: 'People & Body',
    },
    {
      character: '👨‍🦲',
      title: 'man: bald',
      category: 'People & Body',
    },
    {
      character: '👩',
      title: 'woman',
      category: 'People & Body',
    },
    {
      character: '👩‍🦰',
      title: 'woman: red hair',
      category: 'People & Body',
    },
    {
      character: '🧑‍🦰',
      title: 'person: red hair',
      category: 'People & Body',
    },
    {
      character: '👩‍🦱',
      title: 'woman: curly hair',
      category: 'People & Body',
    },
    {
      character: '🧑‍🦱',
      title: 'person: curly hair',
      category: 'People & Body',
    },
    {
      character: '👩‍🦳',
      title: 'woman: white hair',
      category: 'People & Body',
    },
    {
      character: '🧑‍🦳',
      title: 'person: white hair',
      category: 'People & Body',
    },
    {
      character: '👩‍🦲',
      title: 'woman: bald',
      category: 'People & Body',
    },
    {
      character: '🧑‍🦲',
      title: 'person: bald',
      category: 'People & Body',
    },
    {
      character: '👱‍♀️',
      title: 'woman: blond hair',
      category: 'People & Body',
    },
    {
      character: '👱‍♂️',
      title: 'man: blond hair',
      category: 'People & Body',
    },
    {
      character: '🧓',
      title: 'older person',
      category: 'People & Body',
    },
    {
      character: '👴',
      title: 'old man',
      category: 'People & Body',
    },
    {
      character: '👵',
      title: 'old woman',
      category: 'People & Body',
    },
    {
      character: '🙍',
      title: 'person frowning',
      category: 'People & Body',
    },
    {
      character: '🙍‍♂️',
      title: 'man frowning',
      category: 'People & Body',
    },
    {
      character: '🙍‍♀️',
      title: 'woman frowning',
      category: 'People & Body',
    },
    {
      character: '🙎',
      title: 'person pouting',
      category: 'People & Body',
    },
    {
      character: '🙎‍♂️',
      title: 'man pouting',
      category: 'People & Body',
    },
    {
      character: '🙎‍♀️',
      title: 'woman pouting',
      category: 'People & Body',
    },
    {
      character: '🙅',
      title: 'person gesturing NO',
      category: 'People & Body',
    },
    {
      character: '🙅‍♂️',
      title: 'man gesturing NO',
      category: 'People & Body',
    },
    {
      character: '🙅‍♀️',
      title: 'woman gesturing NO',
      category: 'People & Body',
    },
    {
      character: '🙆',
      title: 'person gesturing OK',
      category: 'People & Body',
    },
    {
      character: '🙆‍♂️',
      title: 'man gesturing OK',
      category: 'People & Body',
    },
    {
      character: '🙆‍♀️',
      title: 'woman gesturing OK',
      category: 'People & Body',
    },
    {
      character: '💁',
      title: 'person tipping hand',
      category: 'People & Body',
    },
    {
      character: '💁‍♂️',
      title: 'man tipping hand',
      category: 'People & Body',
    },
    {
      character: '💁‍♀️',
      title: 'woman tipping hand',
      category: 'People & Body',
    },
    {
      character: '🙋',
      title: 'person raising hand',
      category: 'People & Body',
    },
    {
      character: '🙋‍♂️',
      title: 'man raising hand',
      category: 'People & Body',
    },
    {
      character: '🙋‍♀️',
      title: 'woman raising hand',
      category: 'People & Body',
    },
    {
      character: '🧏',
      title: 'deaf person',
      category: 'People & Body',
    },
    {
      character: '🧏‍♂️',
      title: 'deaf man',
      category: 'People & Body',
    },
    {
      character: '🧏‍♀️',
      title: 'deaf woman',
      category: 'People & Body',
    },
    {
      character: '🙇',
      title: 'person bowing',
      category: 'People & Body',
    },
    {
      character: '🙇‍♂️',
      title: 'man bowing',
      category: 'People & Body',
    },
    {
      character: '🙇‍♀️',
      title: 'woman bowing',
      category: 'People & Body',
    },
    {
      character: '🤦',
      title: 'person facepalming',
      category: 'People & Body',
    },
    {
      character: '🤦‍♂️',
      title: 'man facepalming',
      category: 'People & Body',
    },
    {
      character: '🤦‍♀️',
      title: 'woman facepalming',
      category: 'People & Body',
    },
    {
      character: '🤷',
      title: 'person shrugging',
      category: 'People & Body',
    },
    {
      character: '🤷‍♂️',
      title: 'man shrugging',
      category: 'People & Body',
    },
    {
      character: '🤷‍♀️',
      title: 'woman shrugging',
      category: 'People & Body',
    },
    {
      character: '🧑‍⚕️',
      title: 'health worker',
      category: 'People & Body',
    },
    {
      character: '👨‍⚕️',
      title: 'man health worker',
      category: 'People & Body',
    },
    {
      character: '👩‍⚕️',
      title: 'woman health worker',
      category: 'People & Body',
    },
    {
      character: '🧑‍🎓',
      title: 'student',
      category: 'People & Body',
    },
    {
      character: '👨‍🎓',
      title: 'man student',
      category: 'People & Body',
    },
    {
      character: '👩‍🎓',
      title: 'woman student',
      category: 'People & Body',
    },
    {
      character: '🧑‍🏫',
      title: 'teacher',
      category: 'People & Body',
    },
    {
      character: '👨‍🏫',
      title: 'man teacher',
      category: 'People & Body',
    },
    {
      character: '👩‍🏫',
      title: 'woman teacher',
      category: 'People & Body',
    },
    {
      character: '🧑‍⚖️',
      title: 'judge',
      category: 'People & Body',
    },
    {
      character: '👨‍⚖️',
      title: 'man judge',
      category: 'People & Body',
    },
    {
      character: '👩‍⚖️',
      title: 'woman judge',
      category: 'People & Body',
    },
    {
      character: '🧑‍🌾',
      title: 'farmer',
      category: 'People & Body',
    },
    {
      character: '👨‍🌾',
      title: 'man farmer',
      category: 'People & Body',
    },
    {
      character: '👩‍🌾',
      title: 'woman farmer',
      category: 'People & Body',
    },
    {
      character: '🧑‍🍳',
      title: 'cook',
      category: 'People & Body',
    },
    {
      character: '👨‍🍳',
      title: 'man cook',
      category: 'People & Body',
    },
    {
      character: '👩‍🍳',
      title: 'woman cook',
      category: 'People & Body',
    },
    {
      character: '🧑‍🔧',
      title: 'mechanic',
      category: 'People & Body',
    },
    {
      character: '👨‍🔧',
      title: 'man mechanic',
      category: 'People & Body',
    },
    {
      character: '👩‍🔧',
      title: 'woman mechanic',
      category: 'People & Body',
    },
    {
      character: '🧑‍🏭',
      title: 'factory worker',
      category: 'People & Body',
    },
    {
      character: '👨‍🏭',
      title: 'man factory worker',
      category: 'People & Body',
    },
    {
      character: '👩‍🏭',
      title: 'woman factory worker',
      category: 'People & Body',
    },
    {
      character: '🧑‍💼',
      title: 'office worker',
      category: 'People & Body',
    },
    {
      character: '👨‍💼',
      title: 'man office worker',
      category: 'People & Body',
    },
    {
      character: '👩‍💼',
      title: 'woman office worker',
      category: 'People & Body',
    },
    {
      character: '🧑‍🔬',
      title: 'scientist',
      category: 'People & Body',
    },
    {
      character: '👨‍🔬',
      title: 'man scientist',
      category: 'People & Body',
    },
    {
      character: '👩‍🔬',
      title: 'woman scientist',
      category: 'People & Body',
    },
    {
      character: '🧑‍💻',
      title: 'technologist',
      category: 'People & Body',
    },
    {
      character: '👨‍💻',
      title: 'man technologist',
      category: 'People & Body',
    },
    {
      character: '👩‍💻',
      title: 'woman technologist',
      category: 'People & Body',
    },
    {
      character: '🧑‍🎤',
      title: 'singer',
      category: 'People & Body',
    },
    {
      character: '👨‍🎤',
      title: 'man singer',
      category: 'People & Body',
    },
    {
      character: '👩‍🎤',
      title: 'woman singer',
      category: 'People & Body',
    },
    {
      character: '🧑‍🎨',
      title: 'artist',
      category: 'People & Body',
    },
    {
      character: '👨‍🎨',
      title: 'man artist',
      category: 'People & Body',
    },
    {
      character: '👩‍🎨',
      title: 'woman artist',
      category: 'People & Body',
    },
    {
      character: '🧑‍✈️',
      title: 'pilot',
      category: 'People & Body',
    },
    {
      character: '👨‍✈️',
      title: 'man pilot',
      category: 'People & Body',
    },
    {
      character: '👩‍✈️',
      title: 'woman pilot',
      category: 'People & Body',
    },
    {
      character: '🧑‍🚀',
      title: 'astronaut',
      category: 'People & Body',
    },
    {
      character: '👨‍🚀',
      title: 'man astronaut',
      category: 'People & Body',
    },
    {
      character: '👩‍🚀',
      title: 'woman astronaut',
      category: 'People & Body',
    },
    {
      character: '🧑‍🚒',
      title: 'firefighter',
      category: 'People & Body',
    },
    {
      character: '👨‍🚒',
      title: 'man firefighter',
      category: 'People & Body',
    },
    {
      character: '👩‍🚒',
      title: 'woman firefighter',
      category: 'People & Body',
    },
    {
      character: '👮',
      title: 'police officer',
      category: 'People & Body',
    },
    {
      character: '👮‍♂️',
      title: 'man police officer',
      category: 'People & Body',
    },
    {
      character: '👮‍♀️',
      title: 'woman police officer',
      category: 'People & Body',
    },
    {
      character: '🕵️',
      title: 'detective',
      category: 'People & Body',
    },
    {
      character: '🕵️‍♂️',
      title: 'man detective',
      category: 'People & Body',
    },
    {
      character: '🕵️‍♀️',
      title: 'woman detective',
      category: 'People & Body',
    },
    {
      character: '💂',
      title: 'guard',
      category: 'People & Body',
    },
    {
      character: '💂‍♂️',
      title: 'man guard',
      category: 'People & Body',
    },
    {
      character: '💂‍♀️',
      title: 'woman guard',
      category: 'People & Body',
    },
    {
      character: '🥷',
      title: 'ninja',
      category: 'People & Body',
    },
    {
      character: '👷',
      title: 'construction worker',
      category: 'People & Body',
    },
    {
      character: '👷‍♂️',
      title: 'man construction worker',
      category: 'People & Body',
    },
    {
      character: '👷‍♀️',
      title: 'woman construction worker',
      category: 'People & Body',
    },
    {
      character: '🫅',
      title: 'person with crown',
      category: 'People & Body',
    },
    {
      character: '🤴',
      title: 'prince',
      category: 'People & Body',
    },
    {
      character: '👸',
      title: 'princess',
      category: 'People & Body',
    },
    {
      character: '👳',
      title: 'person wearing turban',
      category: 'People & Body',
    },
    {
      character: '👳‍♂️',
      title: 'man wearing turban',
      category: 'People & Body',
    },
    {
      character: '👳‍♀️',
      title: 'woman wearing turban',
      category: 'People & Body',
    },
    {
      character: '👲',
      title: 'person with skullcap',
      category: 'People & Body',
    },
    {
      character: '🧕',
      title: 'woman with headscarf',
      category: 'People & Body',
    },
    {
      character: '🤵',
      title: 'person in tuxedo',
      category: 'People & Body',
    },
    {
      character: '🤵‍♂️',
      title: 'man in tuxedo',
      category: 'People & Body',
    },
    {
      character: '🤵‍♀️',
      title: 'woman in tuxedo',
      category: 'People & Body',
    },
    {
      character: '👰',
      title: 'person with veil',
      category: 'People & Body',
    },
    {
      character: '👰‍♂️',
      title: 'man with veil',
      category: 'People & Body',
    },
    {
      character: '👰‍♀️',
      title: 'woman with veil',
      category: 'People & Body',
    },
    {
      character: '🤰',
      title: 'pregnant woman',
      category: 'People & Body',
    },
    {
      character: '🫃',
      title: 'pregnant man',
      category: 'People & Body',
    },
    {
      character: '🫄',
      title: 'pregnant person',
      category: 'People & Body',
    },
    {
      character: '🤱',
      title: 'breast-feeding',
      category: 'People & Body',
    },
    {
      character: '👩‍🍼',
      title: 'woman feeding baby',
      category: 'People & Body',
    },
    {
      character: '👨‍🍼',
      title: 'man feeding baby',
      category: 'People & Body',
    },
    {
      character: '🧑‍🍼',
      title: 'person feeding baby',
      category: 'People & Body',
    },
    {
      character: '👼',
      title: 'baby angel',
      category: 'People & Body',
    },
    {
      character: '🎅',
      title: 'Santa Claus',
      category: 'People & Body',
    },
    {
      character: '🤶',
      title: 'Mrs. Claus',
      category: 'People & Body',
    },
    {
      character: '🧑‍🎄',
      title: 'mx claus',
      category: 'People & Body',
    },
    {
      character: '🦸',
      title: 'superhero',
      category: 'People & Body',
    },
    {
      character: '🦸‍♂️',
      title: 'man superhero',
      category: 'People & Body',
    },
    {
      character: '🦸‍♀️',
      title: 'woman superhero',
      category: 'People & Body',
    },
    {
      character: '🦹',
      title: 'supervillain',
      category: 'People & Body',
    },
    {
      character: '🦹‍♂️',
      title: 'man supervillain',
      category: 'People & Body',
    },
    {
      character: '🦹‍♀️',
      title: 'woman supervillain',
      category: 'People & Body',
    },
    {
      character: '🧙',
      title: 'mage',
      category: 'People & Body',
    },
    {
      character: '🧙‍♂️',
      title: 'man mage',
      category: 'People & Body',
    },
    {
      character: '🧙‍♀️',
      title: 'woman mage',
      category: 'People & Body',
    },
    {
      character: '🧚',
      title: 'fairy',
      category: 'People & Body',
    },
    {
      character: '🧚‍♂️',
      title: 'man fairy',
      category: 'People & Body',
    },
    {
      character: '🧚‍♀️',
      title: 'woman fairy',
      category: 'People & Body',
    },
    {
      character: '🧛',
      title: 'vampire',
      category: 'People & Body',
    },
    {
      character: '🧛‍♂️',
      title: 'man vampire',
      category: 'People & Body',
    },
    {
      character: '🧛‍♀️',
      title: 'woman vampire',
      category: 'People & Body',
    },
    {
      character: '🧜',
      title: 'merperson',
      category: 'People & Body',
    },
    {
      character: '🧜‍♂️',
      title: 'merman',
      category: 'People & Body',
    },
    {
      character: '🧜‍♀️',
      title: 'mermaid',
      category: 'People & Body',
    },
    {
      character: '🧝',
      title: 'elf',
      category: 'People & Body',
    },
    {
      character: '🧝‍♂️',
      title: 'man elf',
      category: 'People & Body',
    },
    {
      character: '🧝‍♀️',
      title: 'woman elf',
      category: 'People & Body',
    },
    {
      character: '🧞',
      title: 'genie',
      category: 'People & Body',
    },
    {
      character: '🧞‍♂️',
      title: 'man genie',
      category: 'People & Body',
    },
    {
      character: '🧞‍♀️',
      title: 'woman genie',
      category: 'People & Body',
    },
    {
      character: '🧟',
      title: 'zombie',
      category: 'People & Body',
    },
    {
      character: '🧟‍♂️',
      title: 'man zombie',
      category: 'People & Body',
    },
    {
      character: '🧟‍♀️',
      title: 'woman zombie',
      category: 'People & Body',
    },
    {
      character: '🧌',
      title: 'troll',
      category: 'People & Body',
    },
    {
      character: '💆',
      title: 'person getting massage',
      category: 'People & Body',
    },
    {
      character: '💆‍♂️',
      title: 'man getting massage',
      category: 'People & Body',
    },
    {
      character: '💆‍♀️',
      title: 'woman getting massage',
      category: 'People & Body',
    },
    {
      character: '💇',
      title: 'person getting haircut',
      category: 'People & Body',
    },
    {
      character: '💇‍♂️',
      title: 'man getting haircut',
      category: 'People & Body',
    },
    {
      character: '💇‍♀️',
      title: 'woman getting haircut',
      category: 'People & Body',
    },
    {
      character: '🚶',
      title: 'person walking',
      category: 'People & Body',
    },
    {
      character: '🚶‍♂️',
      title: 'man walking',
      category: 'People & Body',
    },
    {
      character: '🚶‍♀️',
      title: 'woman walking',
      category: 'People & Body',
    },
    {
      character: '🧍',
      title: 'person standing',
      category: 'People & Body',
    },
    {
      character: '🧍‍♂️',
      title: 'man standing',
      category: 'People & Body',
    },
    {
      character: '🧍‍♀️',
      title: 'woman standing',
      category: 'People & Body',
    },
    {
      character: '🧎',
      title: 'person kneeling',
      category: 'People & Body',
    },
    {
      character: '🧎‍♂️',
      title: 'man kneeling',
      category: 'People & Body',
    },
    {
      character: '🧎‍♀️',
      title: 'woman kneeling',
      category: 'People & Body',
    },
    {
      character: '🧑‍🦯',
      title: 'person with white cane',
      category: 'People & Body',
    },
    {
      character: '👨‍🦯',
      title: 'man with white cane',
      category: 'People & Body',
    },
    {
      character: '👩‍🦯',
      title: 'woman with white cane',
      category: 'People & Body',
    },
    {
      character: '🧑‍🦼',
      title: 'person in motorized wheelchair',
      category: 'People & Body',
    },
    {
      character: '👨‍🦼',
      title: 'man in motorized wheelchair',
      category: 'People & Body',
    },
    {
      character: '👩‍🦼',
      title: 'woman in motorized wheelchair',
      category: 'People & Body',
    },
    {
      character: '🧑‍🦽',
      title: 'person in manual wheelchair',
      category: 'People & Body',
    },
    {
      character: '👨‍🦽',
      title: 'man in manual wheelchair',
      category: 'People & Body',
    },
    {
      character: '👩‍🦽',
      title: 'woman in manual wheelchair',
      category: 'People & Body',
    },
    {
      character: '🏃',
      title: 'person running',
      category: 'People & Body',
    },
    {
      character: '🏃‍♂️',
      title: 'man running',
      category: 'People & Body',
    },
    {
      character: '🏃‍♀️',
      title: 'woman running',
      category: 'People & Body',
    },
    {
      character: '💃',
      title: 'woman dancing',
      category: 'People & Body',
    },
    {
      character: '🕺',
      title: 'man dancing',
      category: 'People & Body',
    },
    {
      character: '🕴️',
      title: 'person in suit levitating',
      category: 'People & Body',
    },
    {
      character: '👯',
      title: 'people with bunny ears',
      category: 'People & Body',
    },
    {
      character: '👯‍♂️',
      title: 'men with bunny ears',
      category: 'People & Body',
    },
    {
      character: '👯‍♀️',
      title: 'women with bunny ears',
      category: 'People & Body',
    },
    {
      character: '🧖',
      title: 'person in steamy room',
      category: 'People & Body',
    },
    {
      character: '🧖‍♂️',
      title: 'man in steamy room',
      category: 'People & Body',
    },
    {
      character: '🧖‍♀️',
      title: 'woman in steamy room',
      category: 'People & Body',
    },
    {
      character: '🧗',
      title: 'person climbing',
      category: 'People & Body',
    },
    {
      character: '🧗‍♂️',
      title: 'man climbing',
      category: 'People & Body',
    },
    {
      character: '🧗‍♀️',
      title: 'woman climbing',
      category: 'People & Body',
    },
    {
      character: '🤺',
      title: 'person fencing',
      category: 'People & Body',
    },
    {
      character: '🏇',
      title: 'horse racing',
      category: 'People & Body',
    },
    {
      character: '⛷️',
      title: 'skier',
      category: 'People & Body',
    },
    {
      character: '🏂',
      title: 'snowboarder',
      category: 'People & Body',
    },
    {
      character: '🏌️',
      title: 'person golfing',
      category: 'People & Body',
    },
    {
      character: '🏌️‍♂️',
      title: 'man golfing',
      category: 'People & Body',
    },
    {
      character: '🏌️‍♀️',
      title: 'woman golfing',
      category: 'People & Body',
    },
    {
      character: '🏄',
      title: 'person surfing',
      category: 'People & Body',
    },
    {
      character: '🏄‍♂️',
      title: 'man surfing',
      category: 'People & Body',
    },
    {
      character: '🏄‍♀️',
      title: 'woman surfing',
      category: 'People & Body',
    },
    {
      character: '🚣',
      title: 'person rowing boat',
      category: 'People & Body',
    },
    {
      character: '🚣‍♂️',
      title: 'man rowing boat',
      category: 'People & Body',
    },
    {
      character: '🚣‍♀️',
      title: 'woman rowing boat',
      category: 'People & Body',
    },
    {
      character: '🏊',
      title: 'person swimming',
      category: 'People & Body',
    },
    {
      character: '🏊‍♂️',
      title: 'man swimming',
      category: 'People & Body',
    },
    {
      character: '🏊‍♀️',
      title: 'woman swimming',
      category: 'People & Body',
    },
    {
      character: '⛹️',
      title: 'person bouncing ball',
      category: 'People & Body',
    },
    {
      character: '⛹️‍♂️',
      title: 'man bouncing ball',
      category: 'People & Body',
    },
    {
      character: '⛹️‍♀️',
      title: 'woman bouncing ball',
      category: 'People & Body',
    },
    {
      character: '🏋️',
      title: 'person lifting weights',
      category: 'People & Body',
    },
    {
      character: '🏋️‍♂️',
      title: 'man lifting weights',
      category: 'People & Body',
    },
    {
      character: '🏋️‍♀️',
      title: 'woman lifting weights',
      category: 'People & Body',
    },
    {
      character: '🚴',
      title: 'person biking',
      category: 'People & Body',
    },
    {
      character: '🚴‍♂️',
      title: 'man biking',
      category: 'People & Body',
    },
    {
      character: '🚴‍♀️',
      title: 'woman biking',
      category: 'People & Body',
    },
    {
      character: '🚵',
      title: 'person mountain biking',
      category: 'People & Body',
    },
    {
      character: '🚵‍♂️',
      title: 'man mountain biking',
      category: 'People & Body',
    },
    {
      character: '🚵‍♀️',
      title: 'woman mountain biking',
      category: 'People & Body',
    },
    {
      character: '🤸',
      title: 'person cartwheeling',
      category: 'People & Body',
    },
    {
      character: '🤸‍♂️',
      title: 'man cartwheeling',
      category: 'People & Body',
    },
    {
      character: '🤸‍♀️',
      title: 'woman cartwheeling',
      category: 'People & Body',
    },
    {
      character: '🤼',
      title: 'people wrestling',
      category: 'People & Body',
    },
    {
      character: '🤼‍♂️',
      title: 'men wrestling',
      category: 'People & Body',
    },
    {
      character: '🤼‍♀️',
      title: 'women wrestling',
      category: 'People & Body',
    },
    {
      character: '🤽',
      title: 'person playing water polo',
      category: 'People & Body',
    },
    {
      character: '🤽‍♂️',
      title: 'man playing water polo',
      category: 'People & Body',
    },
    {
      character: '🤽‍♀️',
      title: 'woman playing water polo',
      category: 'People & Body',
    },
    {
      character: '🤾',
      title: 'person playing handball',
      category: 'People & Body',
    },
    {
      character: '🤾‍♂️',
      title: 'man playing handball',
      category: 'People & Body',
    },
    {
      character: '🤾‍♀️',
      title: 'woman playing handball',
      category: 'People & Body',
    },
    {
      character: '🤹',
      title: 'person juggling',
      category: 'People & Body',
    },
    {
      character: '🤹‍♂️',
      title: 'man juggling',
      category: 'People & Body',
    },
    {
      character: '🤹‍♀️',
      title: 'woman juggling',
      category: 'People & Body',
    },
    {
      character: '🧘',
      title: 'person in lotus position',
      category: 'People & Body',
    },
    {
      character: '🧘‍♂️',
      title: 'man in lotus position',
      category: 'People & Body',
    },
    {
      character: '🧘‍♀️',
      title: 'woman in lotus position',
      category: 'People & Body',
    },
    {
      character: '🛀',
      title: 'person taking bath',
      category: 'People & Body',
    },
    {
      character: '🛌',
      title: 'person in bed',
      category: 'People & Body',
    },
    {
      character: '🧑‍🤝‍🧑',
      title: 'people holding hands',
      category: 'People & Body',
    },
    {
      character: '👭',
      title: 'women holding hands',
      category: 'People & Body',
    },
    {
      character: '👫',
      title: 'woman and man holding hands',
      category: 'People & Body',
    },
    {
      character: '👬',
      title: 'men holding hands',
      category: 'People & Body',
    },
    {
      character: '💏',
      title: 'kiss',
      category: 'People & Body',
    },
    {
      character: '👩‍❤️‍💋‍👨',
      title: 'kiss: woman, man',
      category: 'People & Body',
    },
    {
      character: '👨‍❤️‍💋‍👨',
      title: 'kiss: man, man',
      category: 'People & Body',
    },
    {
      character: '👩‍❤️‍💋‍👩',
      title: 'kiss: woman, woman',
      category: 'People & Body',
    },
    {
      character: '💑',
      title: 'couple with heart',
      category: 'People & Body',
    },
    {
      character: '👩‍❤️‍👨',
      title: 'couple with heart: woman, man',
      category: 'People & Body',
    },
    {
      character: '👨‍❤️‍👨',
      title: 'couple with heart: man, man',
      category: 'People & Body',
    },
    {
      character: '👩‍❤️‍👩',
      title: 'couple with heart: woman, woman',
      category: 'People & Body',
    },
    {
      character: '👪',
      title: 'family',
      category: 'People & Body',
    },
    {
      character: '👨‍👩‍👦',
      title: 'family: man, woman, boy',
      category: 'People & Body',
    },
    {
      character: '👨‍👩‍👧',
      title: 'family: man, woman, girl',
      category: 'People & Body',
    },
    {
      character: '👨‍👩‍👧‍👦',
      title: 'family: man, woman, girl, boy',
      category: 'People & Body',
    },
    {
      character: '👨‍👩‍👦‍👦',
      title: 'family: man, woman, boy, boy',
      category: 'People & Body',
    },
    {
      character: '👨‍👩‍👧‍👧',
      title: 'family: man, woman, girl, girl',
      category: 'People & Body',
    },
    {
      character: '👨‍👨‍👦',
      title: 'family: man, man, boy',
      category: 'People & Body',
    },
    {
      character: '👨‍👨‍👧',
      title: 'family: man, man, girl',
      category: 'People & Body',
    },
    {
      character: '👨‍👨‍👧‍👦',
      title: 'family: man, man, girl, boy',
      category: 'People & Body',
    },
    {
      character: '👨‍👨‍👦‍👦',
      title: 'family: man, man, boy, boy',
      category: 'People & Body',
    },
    {
      character: '👨‍👨‍👧‍👧',
      title: 'family: man, man, girl, girl',
      category: 'People & Body',
    },
    {
      character: '👩‍👩‍👦',
      title: 'family: woman, woman, boy',
      category: 'People & Body',
    },
    {
      character: '👩‍👩‍👧',
      title: 'family: woman, woman, girl',
      category: 'People & Body',
    },
    {
      character: '👩‍👩‍👧‍👦',
      title: 'family: woman, woman, girl, boy',
      category: 'People & Body',
    },
    {
      character: '👩‍👩‍👦‍👦',
      title: 'family: woman, woman, boy, boy',
      category: 'People & Body',
    },
    {
      character: '👩‍👩‍👧‍👧',
      title: 'family: woman, woman, girl, girl',
      category: 'People & Body',
    },
    {
      character: '👨‍👦',
      title: 'family: man, boy',
      category: 'People & Body',
    },
    {
      character: '👨‍👦‍👦',
      title: 'family: man, boy, boy',
      category: 'People & Body',
    },
    {
      character: '👨‍👧',
      title: 'family: man, girl',
      category: 'People & Body',
    },
    {
      character: '👨‍👧‍👦',
      title: 'family: man, girl, boy',
      category: 'People & Body',
    },
    {
      character: '👨‍👧‍👧',
      title: 'family: man, girl, girl',
      category: 'People & Body',
    },
    {
      character: '👩‍👦',
      title: 'family: woman, boy',
      category: 'People & Body',
    },
    {
      character: '👩‍👦‍👦',
      title: 'family: woman, boy, boy',
      category: 'People & Body',
    },
    {
      character: '👩‍👧',
      title: 'family: woman, girl',
      category: 'People & Body',
    },
    {
      character: '👩‍👧‍👦',
      title: 'family: woman, girl, boy',
      category: 'People & Body',
    },
    {
      character: '👩‍👧‍👧',
      title: 'family: woman, girl, girl',
      category: 'People & Body',
    },
    {
      character: '🗣️',
      title: 'speaking head',
      category: 'People & Body',
    },
    {
      character: '👤',
      title: 'bust in silhouette',
      category: 'People & Body',
    },
    {
      character: '👥',
      title: 'busts in silhouette',
      category: 'People & Body',
    },
    {
      character: '🫂',
      title: 'people hugging',
      category: 'People & Body',
    },
    {
      character: '👣',
      title: 'footprints',
      category: 'People & Body',
    },
    {
      character: '🐵',
      title: 'monkey face',
      category: 'Animals & Nature',
    },
    {
      character: '🐒',
      title: 'monkey',
      category: 'Animals & Nature',
    },
    {
      character: '🦍',
      title: 'gorilla',
      category: 'Animals & Nature',
    },
    {
      character: '🦧',
      title: 'orangutan',
      category: 'Animals & Nature',
    },
    {
      character: '🐶',
      title: 'dog face',
      category: 'Animals & Nature',
    },
    {
      character: '🐕',
      title: 'dog',
      category: 'Animals & Nature',
    },
    {
      character: '🦮',
      title: 'guide dog',
      category: 'Animals & Nature',
    },
    {
      character: '🐕‍🦺',
      title: 'service dog',
      category: 'Animals & Nature',
    },
    {
      character: '🐩',
      title: 'poodle',
      category: 'Animals & Nature',
    },
    {
      character: '🐺',
      title: 'wolf',
      category: 'Animals & Nature',
    },
    {
      character: '🦊',
      title: 'fox',
      category: 'Animals & Nature',
    },
    {
      character: '🦝',
      title: 'raccoon',
      category: 'Animals & Nature',
    },
    {
      character: '🐱',
      title: 'cat face',
      category: 'Animals & Nature',
    },
    {
      character: '🐈',
      title: 'cat',
      category: 'Animals & Nature',
    },
    {
      character: '🐈‍⬛',
      title: 'black cat',
      category: 'Animals & Nature',
    },
    {
      character: '🦁',
      title: 'lion',
      category: 'Animals & Nature',
    },
    {
      character: '🐯',
      title: 'tiger face',
      category: 'Animals & Nature',
    },
    {
      character: '🐅',
      title: 'tiger',
      category: 'Animals & Nature',
    },
    {
      character: '🐆',
      title: 'leopard',
      category: 'Animals & Nature',
    },
    {
      character: '🐴',
      title: 'horse face',
      category: 'Animals & Nature',
    },
    {
      character: '🐎',
      title: 'horse',
      category: 'Animals & Nature',
    },
    {
      character: '🦄',
      title: 'unicorn',
      category: 'Animals & Nature',
    },
    {
      character: '🦓',
      title: 'zebra',
      category: 'Animals & Nature',
    },
    {
      character: '🦌',
      title: 'deer',
      category: 'Animals & Nature',
    },
    {
      character: '🦬',
      title: 'bison',
      category: 'Animals & Nature',
    },
    {
      character: '🐮',
      title: 'cow face',
      category: 'Animals & Nature',
    },
    {
      character: '🐂',
      title: 'ox',
      category: 'Animals & Nature',
    },
    {
      character: '🐃',
      title: 'water buffalo',
      category: 'Animals & Nature',
    },
    {
      character: '🐄',
      title: 'cow',
      category: 'Animals & Nature',
    },
    {
      character: '🐷',
      title: 'pig face',
      category: 'Animals & Nature',
    },
    {
      character: '🐖',
      title: 'pig',
      category: 'Animals & Nature',
    },
    {
      character: '🐗',
      title: 'boar',
      category: 'Animals & Nature',
    },
    {
      character: '🐽',
      title: 'pig nose',
      category: 'Animals & Nature',
    },
    {
      character: '🐏',
      title: 'ram',
      category: 'Animals & Nature',
    },
    {
      character: '🐑',
      title: 'ewe',
      category: 'Animals & Nature',
    },
    {
      character: '🐐',
      title: 'goat',
      category: 'Animals & Nature',
    },
    {
      character: '🐪',
      title: 'camel',
      category: 'Animals & Nature',
    },
    {
      character: '🐫',
      title: 'two-hump camel',
      category: 'Animals & Nature',
    },
    {
      character: '🦙',
      title: 'llama',
      category: 'Animals & Nature',
    },
    {
      character: '🦒',
      title: 'giraffe',
      category: 'Animals & Nature',
    },
    {
      character: '🐘',
      title: 'elephant',
      category: 'Animals & Nature',
    },
    {
      character: '🦣',
      title: 'mammoth',
      category: 'Animals & Nature',
    },
    {
      character: '🦏',
      title: 'rhinoceros',
      category: 'Animals & Nature',
    },
    {
      character: '🦛',
      title: 'hippopotamus',
      category: 'Animals & Nature',
    },
    {
      character: '🐭',
      title: 'mouse face',
      category: 'Animals & Nature',
    },
    {
      character: '🐁',
      title: 'mouse',
      category: 'Animals & Nature',
    },
    {
      character: '🐀',
      title: 'rat',
      category: 'Animals & Nature',
    },
    {
      character: '🐹',
      title: 'hamster',
      category: 'Animals & Nature',
    },
    {
      character: '🐰',
      title: 'rabbit face',
      category: 'Animals & Nature',
    },
    {
      character: '🐇',
      title: 'rabbit',
      category: 'Animals & Nature',
    },
    {
      character: '🐿️',
      title: 'chipmunk',
      category: 'Animals & Nature',
    },
    {
      character: '🦫',
      title: 'beaver',
      category: 'Animals & Nature',
    },
    {
      character: '🦔',
      title: 'hedgehog',
      category: 'Animals & Nature',
    },
    {
      character: '🦇',
      title: 'bat',
      category: 'Animals & Nature',
    },
    {
      character: '🐻',
      title: 'bear',
      category: 'Animals & Nature',
    },
    {
      character: '🐻‍❄️',
      title: 'polar bear',
      category: 'Animals & Nature',
    },
    {
      character: '🐨',
      title: 'koala',
      category: 'Animals & Nature',
    },
    {
      character: '🐼',
      title: 'panda',
      category: 'Animals & Nature',
    },
    {
      character: '🦥',
      title: 'sloth',
      category: 'Animals & Nature',
    },
    {
      character: '🦦',
      title: 'otter',
      category: 'Animals & Nature',
    },
    {
      character: '🦨',
      title: 'skunk',
      category: 'Animals & Nature',
    },
    {
      character: '🦘',
      title: 'kangaroo',
      category: 'Animals & Nature',
    },
    {
      character: '🦡',
      title: 'badger',
      category: 'Animals & Nature',
    },
    {
      character: '🐾',
      title: 'paw prints',
      category: 'Animals & Nature',
    },
    {
      character: '🦃',
      title: 'turkey',
      category: 'Animals & Nature',
    },
    {
      character: '🐔',
      title: 'chicken',
      category: 'Animals & Nature',
    },
    {
      character: '🐓',
      title: 'rooster',
      category: 'Animals & Nature',
    },
    {
      character: '🐣',
      title: 'hatching chick',
      category: 'Animals & Nature',
    },
    {
      character: '🐤',
      title: 'baby chick',
      category: 'Animals & Nature',
    },
    {
      character: '🐥',
      title: 'front-facing baby chick',
      category: 'Animals & Nature',
    },
    {
      character: '🐦',
      title: 'bird',
      category: 'Animals & Nature',
    },
    {
      character: '🐧',
      title: 'penguin',
      category: 'Animals & Nature',
    },
    {
      character: '🕊️',
      title: 'dove',
      category: 'Animals & Nature',
    },
    {
      character: '🦅',
      title: 'eagle',
      category: 'Animals & Nature',
    },
    {
      character: '🦆',
      title: 'duck',
      category: 'Animals & Nature',
    },
    {
      character: '🦢',
      title: 'swan',
      category: 'Animals & Nature',
    },
    {
      character: '🦉',
      title: 'owl',
      category: 'Animals & Nature',
    },
    {
      character: '🦤',
      title: 'dodo',
      category: 'Animals & Nature',
    },
    {
      character: '🪶',
      title: 'feather',
      category: 'Animals & Nature',
    },
    {
      character: '🦩',
      title: 'flamingo',
      category: 'Animals & Nature',
    },
    {
      character: '🦚',
      title: 'peacock',
      category: 'Animals & Nature',
    },
    {
      character: '🦜',
      title: 'parrot',
      category: 'Animals & Nature',
    },
    {
      character: '🐸',
      title: 'frog',
      category: 'Animals & Nature',
    },
    {
      character: '🐊',
      title: 'crocodile',
      category: 'Animals & Nature',
    },
    {
      character: '🐢',
      title: 'turtle',
      category: 'Animals & Nature',
    },
    {
      character: '🦎',
      title: 'lizard',
      category: 'Animals & Nature',
    },
    {
      character: '🐍',
      title: 'snake',
      category: 'Animals & Nature',
    },
    {
      character: '🐲',
      title: 'dragon face',
      category: 'Animals & Nature',
    },
    {
      character: '🐉',
      title: 'dragon',
      category: 'Animals & Nature',
    },
    {
      character: '🦕',
      title: 'sauropod',
      category: 'Animals & Nature',
    },
    {
      character: '🦖',
      title: 'T-Rex',
      category: 'Animals & Nature',
    },
    {
      character: '🐳',
      title: 'spouting whale',
      category: 'Animals & Nature',
    },
    {
      character: '🐋',
      title: 'whale',
      category: 'Animals & Nature',
    },
    {
      character: '🐬',
      title: 'dolphin',
      category: 'Animals & Nature',
    },
    {
      character: '🦭',
      title: 'seal',
      category: 'Animals & Nature',
    },
    {
      character: '🐟',
      title: 'fish',
      category: 'Animals & Nature',
    },
    {
      character: '🐠',
      title: 'tropical fish',
      category: 'Animals & Nature',
    },
    {
      character: '🐡',
      title: 'blowfish',
      category: 'Animals & Nature',
    },
    {
      character: '🦈',
      title: 'shark',
      category: 'Animals & Nature',
    },
    {
      character: '🐙',
      title: 'octopus',
      category: 'Animals & Nature',
    },
    {
      character: '🐚',
      title: 'spiral shell',
      category: 'Animals & Nature',
    },
    {
      character: '🪸',
      title: 'coral',
      category: 'Animals & Nature',
    },
    {
      character: '🐌',
      title: 'snail',
      category: 'Animals & Nature',
    },
    {
      character: '🦋',
      title: 'butterfly',
      category: 'Animals & Nature',
    },
    {
      character: '🐛',
      title: 'bug',
      category: 'Animals & Nature',
    },
    {
      character: '🐜',
      title: 'ant',
      category: 'Animals & Nature',
    },
    {
      character: '🐝',
      title: 'honeybee',
      category: 'Animals & Nature',
    },
    {
      character: '🪲',
      title: 'beetle',
      category: 'Animals & Nature',
    },
    {
      character: '🐞',
      title: 'lady beetle',
      category: 'Animals & Nature',
    },
    {
      character: '🦗',
      title: 'cricket',
      category: 'Animals & Nature',
    },
    {
      character: '🪳',
      title: 'cockroach',
      category: 'Animals & Nature',
    },
    {
      character: '🕷️',
      title: 'spider',
      category: 'Animals & Nature',
    },
    {
      character: '🕸️',
      title: 'spider web',
      category: 'Animals & Nature',
    },
    {
      character: '🦂',
      title: 'scorpion',
      category: 'Animals & Nature',
    },
    {
      character: '🦟',
      title: 'mosquito',
      category: 'Animals & Nature',
    },
    {
      character: '🪰',
      title: 'fly',
      category: 'Animals & Nature',
    },
    {
      character: '🪱',
      title: 'worm',
      category: 'Animals & Nature',
    },
    {
      character: '🦠',
      title: 'microbe',
      category: 'Animals & Nature',
    },
    {
      character: '💐',
      title: 'bouquet',
      category: 'Animals & Nature',
    },
    {
      character: '🌸',
      title: 'cherry blossom',
      category: 'Animals & Nature',
    },
    {
      character: '💮',
      title: 'white flower',
      category: 'Animals & Nature',
    },
    {
      character: '🪷',
      title: 'lotus',
      category: 'Animals & Nature',
    },
    {
      character: '🏵️',
      title: 'rosette',
      category: 'Animals & Nature',
    },
    {
      character: '🌹',
      title: 'rose',
      category: 'Animals & Nature',
    },
    {
      character: '🥀',
      title: 'wilted flower',
      category: 'Animals & Nature',
    },
    {
      character: '🌺',
      title: 'hibiscus',
      category: 'Animals & Nature',
    },
    {
      character: '🌻',
      title: 'sunflower',
      category: 'Animals & Nature',
    },
    {
      character: '🌼',
      title: 'blossom',
      category: 'Animals & Nature',
    },
    {
      character: '🌷',
      title: 'tulip',
      category: 'Animals & Nature',
    },
    {
      character: '🌱',
      title: 'seedling',
      category: 'Animals & Nature',
    },
    {
      character: '🪴',
      title: 'potted plant',
      category: 'Animals & Nature',
    },
    {
      character: '🌲',
      title: 'evergreen tree',
      category: 'Animals & Nature',
    },
    {
      character: '🌳',
      title: 'deciduous tree',
      category: 'Animals & Nature',
    },
    {
      character: '🌴',
      title: 'palm tree',
      category: 'Animals & Nature',
    },
    {
      character: '🌵',
      title: 'cactus',
      category: 'Animals & Nature',
    },
    {
      character: '🌾',
      title: 'sheaf of rice',
      category: 'Animals & Nature',
    },
    {
      character: '🌿',
      title: 'herb',
      category: 'Animals & Nature',
    },
    {
      character: '☘️',
      title: 'shamrock',
      category: 'Animals & Nature',
    },
    {
      character: '🍀',
      title: 'four leaf clover',
      category: 'Animals & Nature',
    },
    {
      character: '🍁',
      title: 'maple leaf',
      category: 'Animals & Nature',
    },
    {
      character: '🍂',
      title: 'fallen leaf',
      category: 'Animals & Nature',
    },
    {
      character: '🍃',
      title: 'leaf fluttering in wind',
      category: 'Animals & Nature',
    },
    {
      character: '🪹',
      title: 'empty nest',
      category: 'Animals & Nature',
    },
    {
      character: '🪺',
      title: 'nest with eggs',
      category: 'Animals & Nature',
    },
    {
      character: '🍇',
      title: 'grapes',
      category: 'Food & Drink',
    },
    {
      character: '🍈',
      title: 'melon',
      category: 'Food & Drink',
    },
    {
      character: '🍉',
      title: 'watermelon',
      category: 'Food & Drink',
    },
    {
      character: '🍊',
      title: 'tangerine',
      category: 'Food & Drink',
    },
    {
      character: '🍋',
      title: 'lemon',
      category: 'Food & Drink',
    },
    {
      character: '🍌',
      title: 'banana',
      category: 'Food & Drink',
    },
    {
      character: '🍍',
      title: 'pineapple',
      category: 'Food & Drink',
    },
    {
      character: '🥭',
      title: 'mango',
      category: 'Food & Drink',
    },
    {
      character: '🍎',
      title: 'red apple',
      category: 'Food & Drink',
    },
    {
      character: '🍏',
      title: 'green apple',
      category: 'Food & Drink',
    },
    {
      character: '🍐',
      title: 'pear',
      category: 'Food & Drink',
    },
    {
      character: '🍑',
      title: 'peach',
      category: 'Food & Drink',
    },
    {
      character: '🍒',
      title: 'cherries',
      category: 'Food & Drink',
    },
    {
      character: '🍓',
      title: 'strawberry',
      category: 'Food & Drink',
    },
    {
      character: '🫐',
      title: 'blueberries',
      category: 'Food & Drink',
    },
    {
      character: '🥝',
      title: 'kiwi fruit',
      category: 'Food & Drink',
    },
    {
      character: '🍅',
      title: 'tomato',
      category: 'Food & Drink',
    },
    {
      character: '🫒',
      title: 'olive',
      category: 'Food & Drink',
    },
    {
      character: '🥥',
      title: 'coconut',
      category: 'Food & Drink',
    },
    {
      character: '🥑',
      title: 'avocado',
      category: 'Food & Drink',
    },
    {
      character: '🍆',
      title: 'eggplant',
      category: 'Food & Drink',
    },
    {
      character: '🥔',
      title: 'potato',
      category: 'Food & Drink',
    },
    {
      character: '🥕',
      title: 'carrot',
      category: 'Food & Drink',
    },
    {
      character: '🌽',
      title: 'ear of corn',
      category: 'Food & Drink',
    },
    {
      character: '🌶️',
      title: 'hot pepper',
      category: 'Food & Drink',
    },
    {
      character: '🫑',
      title: 'bell pepper',
      category: 'Food & Drink',
    },
    {
      character: '🥒',
      title: 'cucumber',
      category: 'Food & Drink',
    },
    {
      character: '🥬',
      title: 'leafy green',
      category: 'Food & Drink',
    },
    {
      character: '🥦',
      title: 'broccoli',
      category: 'Food & Drink',
    },
    {
      character: '🧄',
      title: 'garlic',
      category: 'Food & Drink',
    },
    {
      character: '🧅',
      title: 'onion',
      category: 'Food & Drink',
    },
    {
      character: '🍄',
      title: 'mushroom',
      category: 'Food & Drink',
    },
    {
      character: '🥜',
      title: 'peanuts',
      category: 'Food & Drink',
    },
    {
      character: '🫘',
      title: 'beans',
      category: 'Food & Drink',
    },
    {
      character: '🌰',
      title: 'chestnut',
      category: 'Food & Drink',
    },
    {
      character: '🍞',
      title: 'bread',
      category: 'Food & Drink',
    },
    {
      character: '🥐',
      title: 'croissant',
      category: 'Food & Drink',
    },
    {
      character: '🥖',
      title: 'baguette bread',
      category: 'Food & Drink',
    },
    {
      character: '🫓',
      title: 'flatbread',
      category: 'Food & Drink',
    },
    {
      character: '🥨',
      title: 'pretzel',
      category: 'Food & Drink',
    },
    {
      character: '🥯',
      title: 'bagel',
      category: 'Food & Drink',
    },
    {
      character: '🥞',
      title: 'pancakes',
      category: 'Food & Drink',
    },
    {
      character: '🧇',
      title: 'waffle',
      category: 'Food & Drink',
    },
    {
      character: '🧀',
      title: 'cheese wedge',
      category: 'Food & Drink',
    },
    {
      character: '🍖',
      title: 'meat on bone',
      category: 'Food & Drink',
    },
    {
      character: '🍗',
      title: 'poultry leg',
      category: 'Food & Drink',
    },
    {
      character: '🥩',
      title: 'cut of meat',
      category: 'Food & Drink',
    },
    {
      character: '🥓',
      title: 'bacon',
      category: 'Food & Drink',
    },
    {
      character: '🍔',
      title: 'hamburger',
      category: 'Food & Drink',
    },
    {
      character: '🍟',
      title: 'french fries',
      category: 'Food & Drink',
    },
    {
      character: '🍕',
      title: 'pizza',
      category: 'Food & Drink',
    },
    {
      character: '🌭',
      title: 'hot dog',
      category: 'Food & Drink',
    },
    {
      character: '🥪',
      title: 'sandwich',
      category: 'Food & Drink',
    },
    {
      character: '🌮',
      title: 'taco',
      category: 'Food & Drink',
    },
    {
      character: '🌯',
      title: 'burrito',
      category: 'Food & Drink',
    },
    {
      character: '🫔',
      title: 'tamale',
      category: 'Food & Drink',
    },
    {
      character: '🥙',
      title: 'stuffed flatbread',
      category: 'Food & Drink',
    },
    {
      character: '🧆',
      title: 'falafel',
      category: 'Food & Drink',
    },
    {
      character: '🥚',
      title: 'egg',
      category: 'Food & Drink',
    },
    {
      character: '🍳',
      title: 'cooking',
      category: 'Food & Drink',
    },
    {
      character: '🥘',
      title: 'shallow pan of food',
      category: 'Food & Drink',
    },
    {
      character: '🍲',
      title: 'pot of food',
      category: 'Food & Drink',
    },
    {
      character: '🫕',
      title: 'fondue',
      category: 'Food & Drink',
    },
    {
      character: '🥣',
      title: 'bowl with spoon',
      category: 'Food & Drink',
    },
    {
      character: '🥗',
      title: 'green salad',
      category: 'Food & Drink',
    },
    {
      character: '🍿',
      title: 'popcorn',
      category: 'Food & Drink',
    },
    {
      character: '🧈',
      title: 'butter',
      category: 'Food & Drink',
    },
    {
      character: '🧂',
      title: 'salt',
      category: 'Food & Drink',
    },
    {
      character: '🥫',
      title: 'canned food',
      category: 'Food & Drink',
    },
    {
      character: '🍱',
      title: 'bento box',
      category: 'Food & Drink',
    },
    {
      character: '🍘',
      title: 'rice cracker',
      category: 'Food & Drink',
    },
    {
      character: '🍙',
      title: 'rice ball',
      category: 'Food & Drink',
    },
    {
      character: '🍚',
      title: 'cooked rice',
      category: 'Food & Drink',
    },
    {
      character: '🍛',
      title: 'curry rice',
      category: 'Food & Drink',
    },
    {
      character: '🍜',
      title: 'steaming bowl',
      category: 'Food & Drink',
    },
    {
      character: '🍝',
      title: 'spaghetti',
      category: 'Food & Drink',
    },
    {
      character: '🍠',
      title: 'roasted sweet potato',
      category: 'Food & Drink',
    },
    {
      character: '🍢',
      title: 'oden',
      category: 'Food & Drink',
    },
    {
      character: '🍣',
      title: 'sushi',
      category: 'Food & Drink',
    },
    {
      character: '🍤',
      title: 'fried shrimp',
      category: 'Food & Drink',
    },
    {
      character: '🍥',
      title: 'fish cake with swirl',
      category: 'Food & Drink',
    },
    {
      character: '🥮',
      title: 'moon cake',
      category: 'Food & Drink',
    },
    {
      character: '🍡',
      title: 'dango',
      category: 'Food & Drink',
    },
    {
      character: '🥟',
      title: 'dumpling',
      category: 'Food & Drink',
    },
    {
      character: '🥠',
      title: 'fortune cookie',
      category: 'Food & Drink',
    },
    {
      character: '🥡',
      title: 'takeout box',
      category: 'Food & Drink',
    },
    {
      character: '🦀',
      title: 'crab',
      category: 'Food & Drink',
    },
    {
      character: '🦞',
      title: 'lobster',
      category: 'Food & Drink',
    },
    {
      character: '🦐',
      title: 'shrimp',
      category: 'Food & Drink',
    },
    {
      character: '🦑',
      title: 'squid',
      category: 'Food & Drink',
    },
    {
      character: '🦪',
      title: 'oyster',
      category: 'Food & Drink',
    },
    {
      character: '🍦',
      title: 'soft ice cream',
      category: 'Food & Drink',
    },
    {
      character: '🍧',
      title: 'shaved ice',
      category: 'Food & Drink',
    },
    {
      character: '🍨',
      title: 'ice cream',
      category: 'Food & Drink',
    },
    {
      character: '🍩',
      title: 'doughnut',
      category: 'Food & Drink',
    },
    {
      character: '🍪',
      title: 'cookie',
      category: 'Food & Drink',
    },
    {
      character: '🎂',
      title: 'birthday cake',
      category: 'Food & Drink',
    },
    {
      character: '🍰',
      title: 'shortcake',
      category: 'Food & Drink',
    },
    {
      character: '🧁',
      title: 'cupcake',
      category: 'Food & Drink',
    },
    {
      character: '🥧',
      title: 'pie',
      category: 'Food & Drink',
    },
    {
      character: '🍫',
      title: 'chocolate bar',
      category: 'Food & Drink',
    },
    {
      character: '🍬',
      title: 'candy',
      category: 'Food & Drink',
    },
    {
      character: '🍭',
      title: 'lollipop',
      category: 'Food & Drink',
    },
    {
      character: '🍮',
      title: 'custard',
      category: 'Food & Drink',
    },
    {
      character: '🍯',
      title: 'honey pot',
      category: 'Food & Drink',
    },
    {
      character: '🍼',
      title: 'baby bottle',
      category: 'Food & Drink',
    },
    {
      character: '🥛',
      title: 'glass of milk',
      category: 'Food & Drink',
    },
    {
      character: '☕',
      title: 'hot beverage',
      category: 'Food & Drink',
    },
    {
      character: '🫖',
      title: 'teapot',
      category: 'Food & Drink',
    },
    {
      character: '🍵',
      title: 'teacup without handle',
      category: 'Food & Drink',
    },
    {
      character: '🍶',
      title: 'sake',
      category: 'Food & Drink',
    },
    {
      character: '🍾',
      title: 'bottle with popping cork',
      category: 'Food & Drink',
    },
    {
      character: '🍷',
      title: 'wine glass',
      category: 'Food & Drink',
    },
    {
      character: '🍸',
      title: 'cocktail glass',
      category: 'Food & Drink',
    },
    {
      character: '🍹',
      title: 'tropical drink',
      category: 'Food & Drink',
    },
    {
      character: '🍺',
      title: 'beer mug',
      category: 'Food & Drink',
    },
    {
      character: '🍻',
      title: 'clinking beer mugs',
      category: 'Food & Drink',
    },
    {
      character: '🥂',
      title: 'clinking glasses',
      category: 'Food & Drink',
    },
    {
      character: '🥃',
      title: 'tumbler glass',
      category: 'Food & Drink',
    },
    {
      character: '🫗',
      title: 'pouring liquid',
      category: 'Food & Drink',
    },
    {
      character: '🥤',
      title: 'cup with straw',
      category: 'Food & Drink',
    },
    {
      character: '🧋',
      title: 'bubble tea',
      category: 'Food & Drink',
    },
    {
      character: '🧃',
      title: 'beverage box',
      category: 'Food & Drink',
    },
    {
      character: '🧉',
      title: 'mate',
      category: 'Food & Drink',
    },
    {
      character: '🧊',
      title: 'ice',
      category: 'Food & Drink',
    },
    {
      character: '🥢',
      title: 'chopsticks',
      category: 'Food & Drink',
    },
    {
      character: '🍽️',
      title: 'fork and knife with plate',
      category: 'Food & Drink',
    },
    {
      character: '🍴',
      title: 'fork and knife',
      category: 'Food & Drink',
    },
    {
      character: '🥄',
      title: 'spoon',
      category: 'Food & Drink',
    },
    {
      character: '🔪',
      title: 'kitchen knife',
      category: 'Food & Drink',
    },
    {
      character: '🫙',
      title: 'jar',
      category: 'Food & Drink',
    },
    {
      character: '🏺',
      title: 'amphora',
      category: 'Food & Drink',
    },
    {
      character: '🌍',
      title: 'globe showing Europe-Africa',
      category: 'Travel & Places',
    },
    {
      character: '🌎',
      title: 'globe showing Americas',
      category: 'Travel & Places',
    },
    {
      character: '🌏',
      title: 'globe showing Asia-Australia',
      category: 'Travel & Places',
    },
    {
      character: '🌐',
      title: 'globe with meridians',
      category: 'Travel & Places',
    },
    {
      character: '🗺️',
      title: 'world map',
      category: 'Travel & Places',
    },
    {
      character: '🗾',
      title: 'map of Japan',
      category: 'Travel & Places',
    },
    {
      character: '🧭',
      title: 'compass',
      category: 'Travel & Places',
    },
    {
      character: '🏔️',
      title: 'snow-capped mountain',
      category: 'Travel & Places',
    },
    {
      character: '⛰️',
      title: 'mountain',
      category: 'Travel & Places',
    },
    {
      character: '🌋',
      title: 'volcano',
      category: 'Travel & Places',
    },
    {
      character: '🗻',
      title: 'mount fuji',
      category: 'Travel & Places',
    },
    {
      character: '🏕️',
      title: 'camping',
      category: 'Travel & Places',
    },
    {
      character: '🏖️',
      title: 'beach with umbrella',
      category: 'Travel & Places',
    },
    {
      character: '🏜️',
      title: 'desert',
      category: 'Travel & Places',
    },
    {
      character: '🏝️',
      title: 'desert island',
      category: 'Travel & Places',
    },
    {
      character: '🏞️',
      title: 'national park',
      category: 'Travel & Places',
    },
    {
      character: '🏟️',
      title: 'stadium',
      category: 'Travel & Places',
    },
    {
      character: '🏛️',
      title: 'classical building',
      category: 'Travel & Places',
    },
    {
      character: '🏗️',
      title: 'building construction',
      category: 'Travel & Places',
    },
    {
      character: '🧱',
      title: 'brick',
      category: 'Travel & Places',
    },
    {
      character: '🪨',
      title: 'rock',
      category: 'Travel & Places',
    },
    {
      character: '🪵',
      title: 'wood',
      category: 'Travel & Places',
    },
    {
      character: '🛖',
      title: 'hut',
      category: 'Travel & Places',
    },
    {
      character: '🏘️',
      title: 'houses',
      category: 'Travel & Places',
    },
    {
      character: '🏚️',
      title: 'derelict house',
      category: 'Travel & Places',
    },
    {
      character: '🏠',
      title: 'house',
      category: 'Travel & Places',
    },
    {
      character: '🏡',
      title: 'house with garden',
      category: 'Travel & Places',
    },
    {
      character: '🏢',
      title: 'office building',
      category: 'Travel & Places',
    },
    {
      character: '🏣',
      title: 'Japanese post office',
      category: 'Travel & Places',
    },
    {
      character: '🏤',
      title: 'post office',
      category: 'Travel & Places',
    },
    {
      character: '🏥',
      title: 'hospital',
      category: 'Travel & Places',
    },
    {
      character: '🏦',
      title: 'bank',
      category: 'Travel & Places',
    },
    {
      character: '🏨',
      title: 'hotel',
      category: 'Travel & Places',
    },
    {
      character: '🏩',
      title: 'love hotel',
      category: 'Travel & Places',
    },
    {
      character: '🏪',
      title: 'convenience store',
      category: 'Travel & Places',
    },
    {
      character: '🏫',
      title: 'school',
      category: 'Travel & Places',
    },
    {
      character: '🏬',
      title: 'department store',
      category: 'Travel & Places',
    },
    {
      character: '🏭',
      title: 'factory',
      category: 'Travel & Places',
    },
    {
      character: '🏯',
      title: 'Japanese castle',
      category: 'Travel & Places',
    },
    {
      character: '🏰',
      title: 'castle',
      category: 'Travel & Places',
    },
    {
      character: '💒',
      title: 'wedding',
      category: 'Travel & Places',
    },
    {
      character: '🗼',
      title: 'Tokyo tower',
      category: 'Travel & Places',
    },
    {
      character: '🗽',
      title: 'Statue of Liberty',
      category: 'Travel & Places',
    },
    {
      character: '⛪',
      title: 'church',
      category: 'Travel & Places',
    },
    {
      character: '🕌',
      title: 'mosque',
      category: 'Travel & Places',
    },
    {
      character: '🛕',
      title: 'hindu temple',
      category: 'Travel & Places',
    },
    {
      character: '🕍',
      title: 'synagogue',
      category: 'Travel & Places',
    },
    {
      character: '⛩️',
      title: 'shinto shrine',
      category: 'Travel & Places',
    },
    {
      character: '🕋',
      title: 'kaaba',
      category: 'Travel & Places',
    },
    {
      character: '⛲',
      title: 'fountain',
      category: 'Travel & Places',
    },
    {
      character: '⛺',
      title: 'tent',
      category: 'Travel & Places',
    },
    {
      character: '🌁',
      title: 'foggy',
      category: 'Travel & Places',
    },
    {
      character: '🌃',
      title: 'night with stars',
      category: 'Travel & Places',
    },
    {
      character: '🏙️',
      title: 'cityscape',
      category: 'Travel & Places',
    },
    {
      character: '🌄',
      title: 'sunrise over mountains',
      category: 'Travel & Places',
    },
    {
      character: '🌅',
      title: 'sunrise',
      category: 'Travel & Places',
    },
    {
      character: '🌆',
      title: 'cityscape at dusk',
      category: 'Travel & Places',
    },
    {
      character: '🌇',
      title: 'sunset',
      category: 'Travel & Places',
    },
    {
      character: '🌉',
      title: 'bridge at night',
      category: 'Travel & Places',
    },
    {
      character: '♨️',
      title: 'hot springs',
      category: 'Travel & Places',
    },
    {
      character: '🎠',
      title: 'carousel horse',
      category: 'Travel & Places',
    },
    {
      character: '🛝',
      title: 'playground slide',
      category: 'Travel & Places',
    },
    {
      character: '🎡',
      title: 'ferris wheel',
      category: 'Travel & Places',
    },
    {
      character: '🎢',
      title: 'roller coaster',
      category: 'Travel & Places',
    },
    {
      character: '💈',
      title: 'barber pole',
      category: 'Travel & Places',
    },
    {
      character: '🎪',
      title: 'circus tent',
      category: 'Travel & Places',
    },
    {
      character: '🚂',
      title: 'locomotive',
      category: 'Travel & Places',
    },
    {
      character: '🚃',
      title: 'railway car',
      category: 'Travel & Places',
    },
    {
      character: '🚄',
      title: 'high-speed train',
      category: 'Travel & Places',
    },
    {
      character: '🚅',
      title: 'bullet train',
      category: 'Travel & Places',
    },
    {
      character: '🚆',
      title: 'train',
      category: 'Travel & Places',
    },
    {
      character: '🚇',
      title: 'metro',
      category: 'Travel & Places',
    },
    {
      character: '🚈',
      title: 'light rail',
      category: 'Travel & Places',
    },
    {
      character: '🚉',
      title: 'station',
      category: 'Travel & Places',
    },
    {
      character: '🚊',
      title: 'tram',
      category: 'Travel & Places',
    },
    {
      character: '🚝',
      title: 'monorail',
      category: 'Travel & Places',
    },
    {
      character: '🚞',
      title: 'mountain railway',
      category: 'Travel & Places',
    },
    {
      character: '🚋',
      title: 'tram car',
      category: 'Travel & Places',
    },
    {
      character: '🚌',
      title: 'bus',
      category: 'Travel & Places',
    },
    {
      character: '🚍',
      title: 'oncoming bus',
      category: 'Travel & Places',
    },
    {
      character: '🚎',
      title: 'trolleybus',
      category: 'Travel & Places',
    },
    {
      character: '🚐',
      title: 'minibus',
      category: 'Travel & Places',
    },
    {
      character: '🚑',
      title: 'ambulance',
      category: 'Travel & Places',
    },
    {
      character: '🚒',
      title: 'fire engine',
      category: 'Travel & Places',
    },
    {
      character: '🚓',
      title: 'police car',
      category: 'Travel & Places',
    },
    {
      character: '🚔',
      title: 'oncoming police car',
      category: 'Travel & Places',
    },
    {
      character: '🚕',
      title: 'taxi',
      category: 'Travel & Places',
    },
    {
      character: '🚖',
      title: 'oncoming taxi',
      category: 'Travel & Places',
    },
    {
      character: '🚗',
      title: 'automobile',
      category: 'Travel & Places',
    },
    {
      character: '🚘',
      title: 'oncoming automobile',
      category: 'Travel & Places',
    },
    {
      character: '🚙',
      title: 'sport utility vehicle',
      category: 'Travel & Places',
    },
    {
      character: '🛻',
      title: 'pickup truck',
      category: 'Travel & Places',
    },
    {
      character: '🚚',
      title: 'delivery truck',
      category: 'Travel & Places',
    },
    {
      character: '🚛',
      title: 'articulated lorry',
      category: 'Travel & Places',
    },
    {
      character: '🚜',
      title: 'tractor',
      category: 'Travel & Places',
    },
    {
      character: '🏎️',
      title: 'racing car',
      category: 'Travel & Places',
    },
    {
      character: '🏍️',
      title: 'motorcycle',
      category: 'Travel & Places',
    },
    {
      character: '🛵',
      title: 'motor scooter',
      category: 'Travel & Places',
    },
    {
      character: '🦽',
      title: 'manual wheelchair',
      category: 'Travel & Places',
    },
    {
      character: '🦼',
      title: 'motorized wheelchair',
      category: 'Travel & Places',
    },
    {
      character: '🛺',
      title: 'auto rickshaw',
      category: 'Travel & Places',
    },
    {
      character: '🚲',
      title: 'bicycle',
      category: 'Travel & Places',
    },
    {
      character: '🛴',
      title: 'kick scooter',
      category: 'Travel & Places',
    },
    {
      character: '🛹',
      title: 'skateboard',
      category: 'Travel & Places',
    },
    {
      character: '🛼',
      title: 'roller skate',
      category: 'Travel & Places',
    },
    {
      character: '🚏',
      title: 'bus stop',
      category: 'Travel & Places',
    },
    {
      character: '🛣️',
      title: 'motorway',
      category: 'Travel & Places',
    },
    {
      character: '🛤️',
      title: 'railway track',
      category: 'Travel & Places',
    },
    {
      character: '🛢️',
      title: 'oil drum',
      category: 'Travel & Places',
    },
    {
      character: '⛽',
      title: 'fuel pump',
      category: 'Travel & Places',
    },
    {
      character: '🛞',
      title: 'wheel',
      category: 'Travel & Places',
    },
    {
      character: '🚨',
      title: 'police car light',
      category: 'Travel & Places',
    },
    {
      character: '🚥',
      title: 'horizontal traffic light',
      category: 'Travel & Places',
    },
    {
      character: '🚦',
      title: 'vertical traffic light',
      category: 'Travel & Places',
    },
    {
      character: '🛑',
      title: 'stop sign',
      category: 'Travel & Places',
    },
    {
      character: '🚧',
      title: 'construction',
      category: 'Travel & Places',
    },
    {
      character: '⚓',
      title: 'anchor',
      category: 'Travel & Places',
    },
    {
      character: '🛟',
      title: 'ring buoy',
      category: 'Travel & Places',
    },
    {
      character: '⛵',
      title: 'sailboat',
      category: 'Travel & Places',
    },
    {
      character: '🛶',
      title: 'canoe',
      category: 'Travel & Places',
    },
    {
      character: '🚤',
      title: 'speedboat',
      category: 'Travel & Places',
    },
    {
      character: '🛳️',
      title: 'passenger ship',
      category: 'Travel & Places',
    },
    {
      character: '⛴️',
      title: 'ferry',
      category: 'Travel & Places',
    },
    {
      character: '🛥️',
      title: 'motor boat',
      category: 'Travel & Places',
    },
    {
      character: '🚢',
      title: 'ship',
      category: 'Travel & Places',
    },
    {
      character: '✈️',
      title: 'airplane',
      category: 'Travel & Places',
    },
    {
      character: '🛩️',
      title: 'small airplane',
      category: 'Travel & Places',
    },
    {
      character: '🛫',
      title: 'airplane departure',
      category: 'Travel & Places',
    },
    {
      character: '🛬',
      title: 'airplane arrival',
      category: 'Travel & Places',
    },
    {
      character: '🪂',
      title: 'parachute',
      category: 'Travel & Places',
    },
    {
      character: '💺',
      title: 'seat',
      category: 'Travel & Places',
    },
    {
      character: '🚁',
      title: 'helicopter',
      category: 'Travel & Places',
    },
    {
      character: '🚟',
      title: 'suspension railway',
      category: 'Travel & Places',
    },
    {
      character: '🚠',
      title: 'mountain cableway',
      category: 'Travel & Places',
    },
    {
      character: '🚡',
      title: 'aerial tramway',
      category: 'Travel & Places',
    },
    {
      character: '🛰️',
      title: 'satellite',
      category: 'Travel & Places',
    },
    {
      character: '🚀',
      title: 'rocket',
      category: 'Travel & Places',
    },
    {
      character: '🛸',
      title: 'flying saucer',
      category: 'Travel & Places',
    },
    {
      character: '🛎️',
      title: 'bellhop bell',
      category: 'Travel & Places',
    },
    {
      character: '🧳',
      title: 'luggage',
      category: 'Travel & Places',
    },
    {
      character: '⌛',
      title: 'hourglass done',
      category: 'Travel & Places',
    },
    {
      character: '⏳',
      title: 'hourglass not done',
      category: 'Travel & Places',
    },
    {
      character: '⌚',
      title: 'watch',
      category: 'Travel & Places',
    },
    {
      character: '⏰',
      title: 'alarm clock',
      category: 'Travel & Places',
    },
    {
      character: '⏱️',
      title: 'stopwatch',
      category: 'Travel & Places',
    },
    {
      character: '⏲️',
      title: 'timer clock',
      category: 'Travel & Places',
    },
    {
      character: '🕰️',
      title: 'mantelpiece clock',
      category: 'Travel & Places',
    },
    {
      character: '🕛',
      title: 'twelve o’clock',
      category: 'Travel & Places',
    },
    {
      character: '🕧',
      title: 'twelve-thirty',
      category: 'Travel & Places',
    },
    {
      character: '🕐',
      title: 'one o’clock',
      category: 'Travel & Places',
    },
    {
      character: '🕜',
      title: 'one-thirty',
      category: 'Travel & Places',
    },
    {
      character: '🕑',
      title: 'two o’clock',
      category: 'Travel & Places',
    },
    {
      character: '🕝',
      title: 'two-thirty',
      category: 'Travel & Places',
    },
    {
      character: '🕒',
      title: 'three o’clock',
      category: 'Travel & Places',
    },
    {
      character: '🕞',
      title: 'three-thirty',
      category: 'Travel & Places',
    },
    {
      character: '🕓',
      title: 'four o’clock',
      category: 'Travel & Places',
    },
    {
      character: '🕟',
      title: 'four-thirty',
      category: 'Travel & Places',
    },
    {
      character: '🕔',
      title: 'five o’clock',
      category: 'Travel & Places',
    },
    {
      character: '🕠',
      title: 'five-thirty',
      category: 'Travel & Places',
    },
    {
      character: '🕕',
      title: 'six o’clock',
      category: 'Travel & Places',
    },
    {
      character: '🕡',
      title: 'six-thirty',
      category: 'Travel & Places',
    },
    {
      character: '🕖',
      title: 'seven o’clock',
      category: 'Travel & Places',
    },
    {
      character: '🕢',
      title: 'seven-thirty',
      category: 'Travel & Places',
    },
    {
      character: '🕗',
      title: 'eight o’clock',
      category: 'Travel & Places',
    },
    {
      character: '🕣',
      title: 'eight-thirty',
      category: 'Travel & Places',
    },
    {
      character: '🕘',
      title: 'nine o’clock',
      category: 'Travel & Places',
    },
    {
      character: '🕤',
      title: 'nine-thirty',
      category: 'Travel & Places',
    },
    {
      character: '🕙',
      title: 'ten o’clock',
      category: 'Travel & Places',
    },
    {
      character: '🕥',
      title: 'ten-thirty',
      category: 'Travel & Places',
    },
    {
      character: '🕚',
      title: 'eleven o’clock',
      category: 'Travel & Places',
    },
    {
      character: '🕦',
      title: 'eleven-thirty',
      category: 'Travel & Places',
    },
    {
      character: '🌑',
      title: 'new moon',
      category: 'Travel & Places',
    },
    {
      character: '🌒',
      title: 'waxing crescent moon',
      category: 'Travel & Places',
    },
    {
      character: '🌓',
      title: 'first quarter moon',
      category: 'Travel & Places',
    },
    {
      character: '🌔',
      title: 'waxing gibbous moon',
      category: 'Travel & Places',
    },
    {
      character: '🌕',
      title: 'full moon',
      category: 'Travel & Places',
    },
    {
      character: '🌖',
      title: 'waning gibbous moon',
      category: 'Travel & Places',
    },
    {
      character: '🌗',
      title: 'last quarter moon',
      category: 'Travel & Places',
    },
    {
      character: '🌘',
      title: 'waning crescent moon',
      category: 'Travel & Places',
    },
    {
      character: '🌙',
      title: 'crescent moon',
      category: 'Travel & Places',
    },
    {
      character: '🌚',
      title: 'new moon face',
      category: 'Travel & Places',
    },
    {
      character: '🌛',
      title: 'first quarter moon face',
      category: 'Travel & Places',
    },
    {
      character: '🌜',
      title: 'last quarter moon face',
      category: 'Travel & Places',
    },
    {
      character: '🌡️',
      title: 'thermometer',
      category: 'Travel & Places',
    },
    {
      character: '☀️',
      title: 'sun',
      category: 'Travel & Places',
    },
    {
      character: '🌝',
      title: 'full moon face',
      category: 'Travel & Places',
    },
    {
      character: '🌞',
      title: 'sun with face',
      category: 'Travel & Places',
    },
    {
      character: '🪐',
      title: 'ringed planet',
      category: 'Travel & Places',
    },
    {
      character: '⭐',
      title: 'star',
      category: 'Travel & Places',
    },
    {
      character: '🌟',
      title: 'glowing star',
      category: 'Travel & Places',
    },
    {
      character: '🌠',
      title: 'shooting star',
      category: 'Travel & Places',
    },
    {
      character: '🌌',
      title: 'milky way',
      category: 'Travel & Places',
    },
    {
      character: '☁️',
      title: 'cloud',
      category: 'Travel & Places',
    },
    {
      character: '⛅',
      title: 'sun behind cloud',
      category: 'Travel & Places',
    },
    {
      character: '⛈️',
      title: 'cloud with lightning and rain',
      category: 'Travel & Places',
    },
    {
      character: '🌤️',
      title: 'sun behind small cloud',
      category: 'Travel & Places',
    },
    {
      character: '🌥️',
      title: 'sun behind large cloud',
      category: 'Travel & Places',
    },
    {
      character: '🌦️',
      title: 'sun behind rain cloud',
      category: 'Travel & Places',
    },
    {
      character: '🌧️',
      title: 'cloud with rain',
      category: 'Travel & Places',
    },
    {
      character: '🌨️',
      title: 'cloud with snow',
      category: 'Travel & Places',
    },
    {
      character: '🌩️',
      title: 'cloud with lightning',
      category: 'Travel & Places',
    },
    {
      character: '🌪️',
      title: 'tornado',
      category: 'Travel & Places',
    },
    {
      character: '🌫️',
      title: 'fog',
      category: 'Travel & Places',
    },
    {
      character: '🌬️',
      title: 'wind face',
      category: 'Travel & Places',
    },
    {
      character: '🌀',
      title: 'cyclone',
      category: 'Travel & Places',
    },
    {
      character: '🌈',
      title: 'rainbow',
      category: 'Travel & Places',
    },
    {
      character: '🌂',
      title: 'closed umbrella',
      category: 'Travel & Places',
    },
    {
      character: '☂️',
      title: 'umbrella',
      category: 'Travel & Places',
    },
    {
      character: '☔',
      title: 'umbrella with rain drops',
      category: 'Travel & Places',
    },
    {
      character: '⛱️',
      title: 'umbrella on ground',
      category: 'Travel & Places',
    },
    {
      character: '⚡',
      title: 'high voltage',
      category: 'Travel & Places',
    },
    {
      character: '❄️',
      title: 'snowflake',
      category: 'Travel & Places',
    },
    {
      character: '☃️',
      title: 'snowman',
      category: 'Travel & Places',
    },
    {
      character: '⛄',
      title: 'snowman without snow',
      category: 'Travel & Places',
    },
    {
      character: '☄️',
      title: 'comet',
      category: 'Travel & Places',
    },
    {
      character: '🔥',
      title: 'fire',
      category: 'Travel & Places',
    },
    {
      character: '💧',
      title: 'droplet',
      category: 'Travel & Places',
    },
    {
      character: '🌊',
      title: 'water wave',
      category: 'Travel & Places',
    },
    {
      character: '🎃',
      title: 'jack-o-lantern',
      category: 'Activities',
    },
    {
      character: '🎄',
      title: 'Christmas tree',
      category: 'Activities',
    },
    {
      character: '🎆',
      title: 'fireworks',
      category: 'Activities',
    },
    {
      character: '🎇',
      title: 'sparkler',
      category: 'Activities',
    },
    {
      character: '🧨',
      title: 'firecracker',
      category: 'Activities',
    },
    {
      character: '✨',
      title: 'sparkles',
      category: 'Activities',
    },
    {
      character: '🎈',
      title: 'balloon',
      category: 'Activities',
    },
    {
      character: '🎉',
      title: 'party popper',
      category: 'Activities',
    },
    {
      character: '🎊',
      title: 'confetti ball',
      category: 'Activities',
    },
    {
      character: '🎋',
      title: 'tanabata tree',
      category: 'Activities',
    },
    {
      character: '🎍',
      title: 'pine decoration',
      category: 'Activities',
    },
    {
      character: '🎎',
      title: 'Japanese dolls',
      category: 'Activities',
    },
    {
      character: '🎏',
      title: 'carp streamer',
      category: 'Activities',
    },
    {
      character: '🎐',
      title: 'wind chime',
      category: 'Activities',
    },
    {
      character: '🎑',
      title: 'moon viewing ceremony',
      category: 'Activities',
    },
    {
      character: '🧧',
      title: 'red envelope',
      category: 'Activities',
    },
    {
      character: '🎀',
      title: 'ribbon',
      category: 'Activities',
    },
    {
      character: '🎁',
      title: 'wrapped gift',
      category: 'Activities',
    },
    {
      character: '🎗️',
      title: 'reminder ribbon',
      category: 'Activities',
    },
    {
      character: '🎟️',
      title: 'admission tickets',
      category: 'Activities',
    },
    {
      character: '🎫',
      title: 'ticket',
      category: 'Activities',
    },
    {
      character: '🎖️',
      title: 'military medal',
      category: 'Activities',
    },
    {
      character: '🏆',
      title: 'trophy',
      category: 'Activities',
    },
    {
      character: '🏅',
      title: 'sports medal',
      category: 'Activities',
    },
    {
      character: '🥇',
      title: '1st place medal',
      category: 'Activities',
    },
    {
      character: '🥈',
      title: '2nd place medal',
      category: 'Activities',
    },
    {
      character: '🥉',
      title: '3rd place medal',
      category: 'Activities',
    },
    {
      character: '⚽',
      title: 'soccer ball',
      category: 'Activities',
    },
    {
      character: '⚾',
      title: 'baseball',
      category: 'Activities',
    },
    {
      character: '🥎',
      title: 'softball',
      category: 'Activities',
    },
    {
      character: '🏀',
      title: 'basketball',
      category: 'Activities',
    },
    {
      character: '🏐',
      title: 'volleyball',
      category: 'Activities',
    },
    {
      character: '🏈',
      title: 'american football',
      category: 'Activities',
    },
    {
      character: '🏉',
      title: 'rugby football',
      category: 'Activities',
    },
    {
      character: '🎾',
      title: 'tennis',
      category: 'Activities',
    },
    {
      character: '🥏',
      title: 'flying disc',
      category: 'Activities',
    },
    {
      character: '🎳',
      title: 'bowling',
      category: 'Activities',
    },
    {
      character: '🏏',
      title: 'cricket game',
      category: 'Activities',
    },
    {
      character: '🏑',
      title: 'field hockey',
      category: 'Activities',
    },
    {
      character: '🏒',
      title: 'ice hockey',
      category: 'Activities',
    },
    {
      character: '🥍',
      title: 'lacrosse',
      category: 'Activities',
    },
    {
      character: '🏓',
      title: 'ping pong',
      category: 'Activities',
    },
    {
      character: '🏸',
      title: 'badminton',
      category: 'Activities',
    },
    {
      character: '🥊',
      title: 'boxing glove',
      category: 'Activities',
    },
    {
      character: '🥋',
      title: 'martial arts uniform',
      category: 'Activities',
    },
    {
      character: '🥅',
      title: 'goal net',
      category: 'Activities',
    },
    {
      character: '⛳',
      title: 'flag in hole',
      category: 'Activities',
    },
    {
      character: '⛸️',
      title: 'ice skate',
      category: 'Activities',
    },
    {
      character: '🎣',
      title: 'fishing pole',
      category: 'Activities',
    },
    {
      character: '🤿',
      title: 'diving mask',
      category: 'Activities',
    },
    {
      character: '🎽',
      title: 'running shirt',
      category: 'Activities',
    },
    {
      character: '🎿',
      title: 'skis',
      category: 'Activities',
    },
    {
      character: '🛷',
      title: 'sled',
      category: 'Activities',
    },
    {
      character: '🥌',
      title: 'curling stone',
      category: 'Activities',
    },
    {
      character: '🎯',
      title: 'bullseye',
      category: 'Activities',
    },
    {
      character: '🪀',
      title: 'yo-yo',
      category: 'Activities',
    },
    {
      character: '🪁',
      title: 'kite',
      category: 'Activities',
    },
    {
      character: '🎱',
      title: 'pool 8 ball',
      category: 'Activities',
    },
    {
      character: '🔮',
      title: 'crystal ball',
      category: 'Activities',
    },
    {
      character: '🪄',
      title: 'magic wand',
      category: 'Activities',
    },
    {
      character: '🧿',
      title: 'nazar amulet',
      category: 'Activities',
    },
    {
      character: '🪬',
      title: 'hamsa',
      category: 'Activities',
    },
    {
      character: '🎮',
      title: 'video game',
      category: 'Activities',
    },
    {
      character: '🕹️',
      title: 'joystick',
      category: 'Activities',
    },
    {
      character: '🎰',
      title: 'slot machine',
      category: 'Activities',
    },
    {
      character: '🎲',
      title: 'game die',
      category: 'Activities',
    },
    {
      character: '🧩',
      title: 'puzzle piece',
      category: 'Activities',
    },
    {
      character: '🧸',
      title: 'teddy bear',
      category: 'Activities',
    },
    {
      character: '🪅',
      title: 'piñata',
      category: 'Activities',
    },
    {
      character: '🪩',
      title: 'mirror ball',
      category: 'Activities',
    },
    {
      character: '🪆',
      title: 'nesting dolls',
      category: 'Activities',
    },
    {
      character: '♠️',
      title: 'spade suit',
      category: 'Activities',
    },
    {
      character: '♥️',
      title: 'heart suit',
      category: 'Activities',
    },
    {
      character: '♦️',
      title: 'diamond suit',
      category: 'Activities',
    },
    {
      character: '♣️',
      title: 'club suit',
      category: 'Activities',
    },
    {
      character: '♟️',
      title: 'chess pawn',
      category: 'Activities',
    },
    {
      character: '🃏',
      title: 'joker',
      category: 'Activities',
    },
    {
      character: '🀄',
      title: 'mahjong red dragon',
      category: 'Activities',
    },
    {
      character: '🎴',
      title: 'flower playing cards',
      category: 'Activities',
    },
    {
      character: '🎭',
      title: 'performing arts',
      category: 'Activities',
    },
    {
      character: '🖼️',
      title: 'framed picture',
      category: 'Activities',
    },
    {
      character: '🎨',
      title: 'artist palette',
      category: 'Activities',
    },
    {
      character: '🧵',
      title: 'thread',
      category: 'Activities',
    },
    {
      character: '🪡',
      title: 'sewing needle',
      category: 'Activities',
    },
    {
      character: '🧶',
      title: 'yarn',
      category: 'Activities',
    },
    {
      character: '🪢',
      title: 'knot',
      category: 'Activities',
    },
    {
      character: '👓',
      title: 'glasses',
      category: 'Objects',
    },
    {
      character: '🕶️',
      title: 'sunglasses',
      category: 'Objects',
    },
    {
      character: '🥽',
      title: 'goggles',
      category: 'Objects',
    },
    {
      character: '🥼',
      title: 'lab coat',
      category: 'Objects',
    },
    {
      character: '🦺',
      title: 'safety vest',
      category: 'Objects',
    },
    {
      character: '👔',
      title: 'necktie',
      category: 'Objects',
    },
    {
      character: '👕',
      title: 't-shirt',
      category: 'Objects',
    },
    {
      character: '👖',
      title: 'jeans',
      category: 'Objects',
    },
    {
      character: '🧣',
      title: 'scarf',
      category: 'Objects',
    },
    {
      character: '🧤',
      title: 'gloves',
      category: 'Objects',
    },
    {
      character: '🧥',
      title: 'coat',
      category: 'Objects',
    },
    {
      character: '🧦',
      title: 'socks',
      category: 'Objects',
    },
    {
      character: '👗',
      title: 'dress',
      category: 'Objects',
    },
    {
      character: '👘',
      title: 'kimono',
      category: 'Objects',
    },
    {
      character: '🥻',
      title: 'sari',
      category: 'Objects',
    },
    {
      character: '🩱',
      title: 'one-piece swimsuit',
      category: 'Objects',
    },
    {
      character: '🩲',
      title: 'briefs',
      category: 'Objects',
    },
    {
      character: '🩳',
      title: 'shorts',
      category: 'Objects',
    },
    {
      character: '👙',
      title: 'bikini',
      category: 'Objects',
    },
    {
      character: '👚',
      title: 'woman’s clothes',
      category: 'Objects',
    },
    {
      character: '👛',
      title: 'purse',
      category: 'Objects',
    },
    {
      character: '👜',
      title: 'handbag',
      category: 'Objects',
    },
    {
      character: '👝',
      title: 'clutch bag',
      category: 'Objects',
    },
    {
      character: '🛍️',
      title: 'shopping bags',
      category: 'Objects',
    },
    {
      character: '🎒',
      title: 'backpack',
      category: 'Objects',
    },
    {
      character: '🩴',
      title: 'thong sandal',
      category: 'Objects',
    },
    {
      character: '👞',
      title: 'man’s shoe',
      category: 'Objects',
    },
    {
      character: '👟',
      title: 'running shoe',
      category: 'Objects',
    },
    {
      character: '🥾',
      title: 'hiking boot',
      category: 'Objects',
    },
    {
      character: '🥿',
      title: 'flat shoe',
      category: 'Objects',
    },
    {
      character: '👠',
      title: 'high-heeled shoe',
      category: 'Objects',
    },
    {
      character: '👡',
      title: 'woman’s sandal',
      category: 'Objects',
    },
    {
      character: '🩰',
      title: 'ballet shoes',
      category: 'Objects',
    },
    {
      character: '👢',
      title: 'woman’s boot',
      category: 'Objects',
    },
    {
      character: '👑',
      title: 'crown',
      category: 'Objects',
    },
    {
      character: '👒',
      title: 'woman’s hat',
      category: 'Objects',
    },
    {
      character: '🎩',
      title: 'top hat',
      category: 'Objects',
    },
    {
      character: '🎓',
      title: 'graduation cap',
      category: 'Objects',
    },
    {
      character: '🧢',
      title: 'billed cap',
      category: 'Objects',
    },
    {
      character: '🪖',
      title: 'military helmet',
      category: 'Objects',
    },
    {
      character: '⛑️',
      title: 'rescue worker’s helmet',
      category: 'Objects',
    },
    {
      character: '📿',
      title: 'prayer beads',
      category: 'Objects',
    },
    {
      character: '💄',
      title: 'lipstick',
      category: 'Objects',
    },
    {
      character: '💍',
      title: 'ring',
      category: 'Objects',
    },
    {
      character: '💎',
      title: 'gem stone',
      category: 'Objects',
    },
    {
      character: '🔇',
      title: 'muted speaker',
      category: 'Objects',
    },
    {
      character: '🔈',
      title: 'speaker low volume',
      category: 'Objects',
    },
    {
      character: '🔉',
      title: 'speaker medium volume',
      category: 'Objects',
    },
    {
      character: '🔊',
      title: 'speaker high volume',
      category: 'Objects',
    },
    {
      character: '📢',
      title: 'loudspeaker',
      category: 'Objects',
    },
    {
      character: '📣',
      title: 'megaphone',
      category: 'Objects',
    },
    {
      character: '📯',
      title: 'postal horn',
      category: 'Objects',
    },
    {
      character: '🔔',
      title: 'bell',
      category: 'Objects',
    },
    {
      character: '🔕',
      title: 'bell with slash',
      category: 'Objects',
    },
    {
      character: '🎼',
      title: 'musical score',
      category: 'Objects',
    },
    {
      character: '🎵',
      title: 'musical note',
      category: 'Objects',
    },
    {
      character: '🎶',
      title: 'musical notes',
      category: 'Objects',
    },
    {
      character: '🎙️',
      title: 'studio microphone',
      category: 'Objects',
    },
    {
      character: '🎚️',
      title: 'level slider',
      category: 'Objects',
    },
    {
      character: '🎛️',
      title: 'control knobs',
      category: 'Objects',
    },
    {
      character: '🎤',
      title: 'microphone',
      category: 'Objects',
    },
    {
      character: '🎧',
      title: 'headphone',
      category: 'Objects',
    },
    {
      character: '📻',
      title: 'radio',
      category: 'Objects',
    },
    {
      character: '🎷',
      title: 'saxophone',
      category: 'Objects',
    },
    {
      character: '🪗',
      title: 'accordion',
      category: 'Objects',
    },
    {
      character: '🎸',
      title: 'guitar',
      category: 'Objects',
    },
    {
      character: '🎹',
      title: 'musical keyboard',
      category: 'Objects',
    },
    {
      character: '🎺',
      title: 'trumpet',
      category: 'Objects',
    },
    {
      character: '🎻',
      title: 'violin',
      category: 'Objects',
    },
    {
      character: '🪕',
      title: 'banjo',
      category: 'Objects',
    },
    {
      character: '🥁',
      title: 'drum',
      category: 'Objects',
    },
    {
      character: '🪘',
      title: 'long drum',
      category: 'Objects',
    },
    {
      character: '📱',
      title: 'mobile phone',
      category: 'Objects',
    },
    {
      character: '📲',
      title: 'mobile phone with arrow',
      category: 'Objects',
    },
    {
      character: '☎️',
      title: 'telephone',
      category: 'Objects',
    },
    {
      character: '📞',
      title: 'telephone receiver',
      category: 'Objects',
    },
    {
      character: '📟',
      title: 'pager',
      category: 'Objects',
    },
    {
      character: '📠',
      title: 'fax machine',
      category: 'Objects',
    },
    {
      character: '🔋',
      title: 'battery',
      category: 'Objects',
    },
    {
      character: '🪫',
      title: 'low battery',
      category: 'Objects',
    },
    {
      character: '🔌',
      title: 'electric plug',
      category: 'Objects',
    },
    {
      character: '💻',
      title: 'laptop',
      category: 'Objects',
    },
    {
      character: '🖥️',
      title: 'desktop computer',
      category: 'Objects',
    },
    {
      character: '🖨️',
      title: 'printer',
      category: 'Objects',
    },
    {
      character: '⌨️',
      title: 'keyboard',
      category: 'Objects',
    },
    {
      character: '🖱️',
      title: 'computer mouse',
      category: 'Objects',
    },
    {
      character: '🖲️',
      title: 'trackball',
      category: 'Objects',
    },
    {
      character: '💽',
      title: 'computer disk',
      category: 'Objects',
    },
    {
      character: '💾',
      title: 'floppy disk',
      category: 'Objects',
    },
    {
      character: '💿',
      title: 'optical disk',
      category: 'Objects',
    },
    {
      character: '📀',
      title: 'dvd',
      category: 'Objects',
    },
    {
      character: '🧮',
      title: 'abacus',
      category: 'Objects',
    },
    {
      character: '🎥',
      title: 'movie camera',
      category: 'Objects',
    },
    {
      character: '🎞️',
      title: 'film frames',
      category: 'Objects',
    },
    {
      character: '📽️',
      title: 'film projector',
      category: 'Objects',
    },
    {
      character: '🎬',
      title: 'clapper board',
      category: 'Objects',
    },
    {
      character: '📺',
      title: 'television',
      category: 'Objects',
    },
    {
      character: '📷',
      title: 'camera',
      category: 'Objects',
    },
    {
      character: '📸',
      title: 'camera with flash',
      category: 'Objects',
    },
    {
      character: '📹',
      title: 'video camera',
      category: 'Objects',
    },
    {
      character: '📼',
      title: 'videocassette',
      category: 'Objects',
    },
    {
      character: '🔍',
      title: 'magnifying glass tilted left',
      category: 'Objects',
    },
    {
      character: '🔎',
      title: 'magnifying glass tilted right',
      category: 'Objects',
    },
    {
      character: '🕯️',
      title: 'candle',
      category: 'Objects',
    },
    {
      character: '💡',
      title: 'light bulb',
      category: 'Objects',
    },
    {
      character: '🔦',
      title: 'flashlight',
      category: 'Objects',
    },
    {
      character: '🏮',
      title: 'red paper lantern',
      category: 'Objects',
    },
    {
      character: '🪔',
      title: 'diya lamp',
      category: 'Objects',
    },
    {
      character: '📔',
      title: 'notebook with decorative cover',
      category: 'Objects',
    },
    {
      character: '📕',
      title: 'closed book',
      category: 'Objects',
    },
    {
      character: '📖',
      title: 'open book',
      category: 'Objects',
    },
    {
      character: '📗',
      title: 'green book',
      category: 'Objects',
    },
    {
      character: '📘',
      title: 'blue book',
      category: 'Objects',
    },
    {
      character: '📙',
      title: 'orange book',
      category: 'Objects',
    },
    {
      character: '📚',
      title: 'books',
      category: 'Objects',
    },
    {
      character: '📓',
      title: 'notebook',
      category: 'Objects',
    },
    {
      character: '📒',
      title: 'ledger',
      category: 'Objects',
    },
    {
      character: '📃',
      title: 'page with curl',
      category: 'Objects',
    },
    {
      character: '📜',
      title: 'scroll',
      category: 'Objects',
    },
    {
      character: '📄',
      title: 'page facing up',
      category: 'Objects',
    },
    {
      character: '📰',
      title: 'newspaper',
      category: 'Objects',
    },
    {
      character: '🗞️',
      title: 'rolled-up newspaper',
      category: 'Objects',
    },
    {
      character: '📑',
      title: 'bookmark tabs',
      category: 'Objects',
    },
    {
      character: '🔖',
      title: 'bookmark',
      category: 'Objects',
    },
    {
      character: '🏷️',
      title: 'label',
      category: 'Objects',
    },
    {
      character: '💰',
      title: 'money bag',
      category: 'Objects',
    },
    {
      character: '🪙',
      title: 'coin',
      category: 'Objects',
    },
    {
      character: '💴',
      title: 'yen banknote',
      category: 'Objects',
    },
    {
      character: '💵',
      title: 'dollar banknote',
      category: 'Objects',
    },
    {
      character: '💶',
      title: 'euro banknote',
      category: 'Objects',
    },
    {
      character: '💷',
      title: 'pound banknote',
      category: 'Objects',
    },
    {
      character: '💸',
      title: 'money with wings',
      category: 'Objects',
    },
    {
      character: '💳',
      title: 'credit card',
      category: 'Objects',
    },
    {
      character: '🧾',
      title: 'receipt',
      category: 'Objects',
    },
    {
      character: '💹',
      title: 'chart increasing with yen',
      category: 'Objects',
    },
    {
      character: '✉️',
      title: 'envelope',
      category: 'Objects',
    },
    {
      character: '📧',
      title: 'e-mail',
      category: 'Objects',
    },
    {
      character: '📨',
      title: 'incoming envelope',
      category: 'Objects',
    },
    {
      character: '📩',
      title: 'envelope with arrow',
      category: 'Objects',
    },
    {
      character: '📤',
      title: 'outbox tray',
      category: 'Objects',
    },
    {
      character: '📥',
      title: 'inbox tray',
      category: 'Objects',
    },
    {
      character: '📦',
      title: 'package',
      category: 'Objects',
    },
    {
      character: '📫',
      title: 'closed mailbox with raised flag',
      category: 'Objects',
    },
    {
      character: '📪',
      title: 'closed mailbox with lowered flag',
      category: 'Objects',
    },
    {
      character: '📬',
      title: 'open mailbox with raised flag',
      category: 'Objects',
    },
    {
      character: '📭',
      title: 'open mailbox with lowered flag',
      category: 'Objects',
    },
    {
      character: '📮',
      title: 'postbox',
      category: 'Objects',
    },
    {
      character: '🗳️',
      title: 'ballot box with ballot',
      category: 'Objects',
    },
    {
      character: '✏️',
      title: 'pencil',
      category: 'Objects',
    },
    {
      character: '✒️',
      title: 'black nib',
      category: 'Objects',
    },
    {
      character: '🖋️',
      title: 'fountain pen',
      category: 'Objects',
    },
    {
      character: '🖊️',
      title: 'pen',
      category: 'Objects',
    },
    {
      character: '🖌️',
      title: 'paintbrush',
      category: 'Objects',
    },
    {
      character: '🖍️',
      title: 'crayon',
      category: 'Objects',
    },
    {
      character: '📝',
      title: 'memo',
      category: 'Objects',
    },
    {
      character: '💼',
      title: 'briefcase',
      category: 'Objects',
    },
    {
      character: '📁',
      title: 'file folder',
      category: 'Objects',
    },
    {
      character: '📂',
      title: 'open file folder',
      category: 'Objects',
    },
    {
      character: '🗂️',
      title: 'card index dividers',
      category: 'Objects',
    },
    {
      character: '📅',
      title: 'calendar',
      category: 'Objects',
    },
    {
      character: '📆',
      title: 'tear-off calendar',
      category: 'Objects',
    },
    {
      character: '🗒️',
      title: 'spiral notepad',
      category: 'Objects',
    },
    {
      character: '🗓️',
      title: 'spiral calendar',
      category: 'Objects',
    },
    {
      character: '📇',
      title: 'card index',
      category: 'Objects',
    },
    {
      character: '📈',
      title: 'chart increasing',
      category: 'Objects',
    },
    {
      character: '📉',
      title: 'chart decreasing',
      category: 'Objects',
    },
    {
      character: '📊',
      title: 'bar chart',
      category: 'Objects',
    },
    {
      character: '📋',
      title: 'clipboard',
      category: 'Objects',
    },
    {
      character: '📌',
      title: 'pushpin',
      category: 'Objects',
    },
    {
      character: '📍',
      title: 'round pushpin',
      category: 'Objects',
    },
    {
      character: '📎',
      title: 'paperclip',
      category: 'Objects',
    },
    {
      character: '🖇️',
      title: 'linked paperclips',
      category: 'Objects',
    },
    {
      character: '📏',
      title: 'straight ruler',
      category: 'Objects',
    },
    {
      character: '📐',
      title: 'triangular ruler',
      category: 'Objects',
    },
    {
      character: '✂️',
      title: 'scissors',
      category: 'Objects',
    },
    {
      character: '🗃️',
      title: 'card file box',
      category: 'Objects',
    },
    {
      character: '🗄️',
      title: 'file cabinet',
      category: 'Objects',
    },
    {
      character: '🗑️',
      title: 'wastebasket',
      category: 'Objects',
    },
    {
      character: '🔒',
      title: 'locked',
      category: 'Objects',
    },
    {
      character: '🔓',
      title: 'unlocked',
      category: 'Objects',
    },
    {
      character: '🔏',
      title: 'locked with pen',
      category: 'Objects',
    },
    {
      character: '🔐',
      title: 'locked with key',
      category: 'Objects',
    },
    {
      character: '🔑',
      title: 'key',
      category: 'Objects',
    },
    {
      character: '🗝️',
      title: 'old key',
      category: 'Objects',
    },
    {
      character: '🔨',
      title: 'hammer',
      category: 'Objects',
    },
    {
      character: '🪓',
      title: 'axe',
      category: 'Objects',
    },
    {
      character: '⛏️',
      title: 'pick',
      category: 'Objects',
    },
    {
      character: '⚒️',
      title: 'hammer and pick',
      category: 'Objects',
    },
    {
      character: '🛠️',
      title: 'hammer and wrench',
      category: 'Objects',
    },
    {
      character: '🗡️',
      title: 'dagger',
      category: 'Objects',
    },
    {
      character: '⚔️',
      title: 'crossed swords',
      category: 'Objects',
    },
    {
      character: '🔫',
      title: 'water pistol',
      category: 'Objects',
    },
    {
      character: '🪃',
      title: 'boomerang',
      category: 'Objects',
    },
    {
      character: '🏹',
      title: 'bow and arrow',
      category: 'Objects',
    },
    {
      character: '🛡️',
      title: 'shield',
      category: 'Objects',
    },
    {
      character: '🪚',
      title: 'carpentry saw',
      category: 'Objects',
    },
    {
      character: '🔧',
      title: 'wrench',
      category: 'Objects',
    },
    {
      character: '🪛',
      title: 'screwdriver',
      category: 'Objects',
    },
    {
      character: '🔩',
      title: 'nut and bolt',
      category: 'Objects',
    },
    {
      character: '⚙️',
      title: 'gear',
      category: 'Objects',
    },
    {
      character: '🗜️',
      title: 'clamp',
      category: 'Objects',
    },
    {
      character: '⚖️',
      title: 'balance scale',
      category: 'Objects',
    },
    {
      character: '🦯',
      title: 'white cane',
      category: 'Objects',
    },
    {
      character: '🔗',
      title: 'link',
      category: 'Objects',
    },
    {
      character: '⛓️',
      title: 'chains',
      category: 'Objects',
    },
    {
      character: '🪝',
      title: 'hook',
      category: 'Objects',
    },
    {
      character: '🧰',
      title: 'toolbox',
      category: 'Objects',
    },
    {
      character: '🧲',
      title: 'magnet',
      category: 'Objects',
    },
    {
      character: '🪜',
      title: 'ladder',
      category: 'Objects',
    },
    {
      character: '⚗️',
      title: 'alembic',
      category: 'Objects',
    },
    {
      character: '🧪',
      title: 'test tube',
      category: 'Objects',
    },
    {
      character: '🧫',
      title: 'petri dish',
      category: 'Objects',
    },
    {
      character: '🧬',
      title: 'dna',
      category: 'Objects',
    },
    {
      character: '🔬',
      title: 'microscope',
      category: 'Objects',
    },
    {
      character: '🔭',
      title: 'telescope',
      category: 'Objects',
    },
    {
      character: '📡',
      title: 'satellite antenna',
      category: 'Objects',
    },
    {
      character: '💉',
      title: 'syringe',
      category: 'Objects',
    },
    {
      character: '🩸',
      title: 'drop of blood',
      category: 'Objects',
    },
    {
      character: '💊',
      title: 'pill',
      category: 'Objects',
    },
    {
      character: '🩹',
      title: 'adhesive bandage',
      category: 'Objects',
    },
    {
      character: '🩼',
      title: 'crutch',
      category: 'Objects',
    },
    {
      character: '🩺',
      title: 'stethoscope',
      category: 'Objects',
    },
    {
      character: '🩻',
      title: 'x-ray',
      category: 'Objects',
    },
    {
      character: '🚪',
      title: 'door',
      category: 'Objects',
    },
    {
      character: '🛗',
      title: 'elevator',
      category: 'Objects',
    },
    {
      character: '🪞',
      title: 'mirror',
      category: 'Objects',
    },
    {
      character: '🪟',
      title: 'window',
      category: 'Objects',
    },
    {
      character: '🛏️',
      title: 'bed',
      category: 'Objects',
    },
    {
      character: '🛋️',
      title: 'couch and lamp',
      category: 'Objects',
    },
    {
      character: '🪑',
      title: 'chair',
      category: 'Objects',
    },
    {
      character: '🚽',
      title: 'toilet',
      category: 'Objects',
    },
    {
      character: '🪠',
      title: 'plunger',
      category: 'Objects',
    },
    {
      character: '🚿',
      title: 'shower',
      category: 'Objects',
    },
    {
      character: '🛁',
      title: 'bathtub',
      category: 'Objects',
    },
    {
      character: '🪤',
      title: 'mouse trap',
      category: 'Objects',
    },
    {
      character: '🪒',
      title: 'razor',
      category: 'Objects',
    },
    {
      character: '🧴',
      title: 'lotion bottle',
      category: 'Objects',
    },
    {
      character: '🧷',
      title: 'safety pin',
      category: 'Objects',
    },
    {
      character: '🧹',
      title: 'broom',
      category: 'Objects',
    },
    {
      character: '🧺',
      title: 'basket',
      category: 'Objects',
    },
    {
      character: '🧻',
      title: 'roll of paper',
      category: 'Objects',
    },
    {
      character: '🪣',
      title: 'bucket',
      category: 'Objects',
    },
    {
      character: '🧼',
      title: 'soap',
      category: 'Objects',
    },
    {
      character: '🫧',
      title: 'bubbles',
      category: 'Objects',
    },
    {
      character: '🪥',
      title: 'toothbrush',
      category: 'Objects',
    },
    {
      character: '🧽',
      title: 'sponge',
      category: 'Objects',
    },
    {
      character: '🧯',
      title: 'fire extinguisher',
      category: 'Objects',
    },
    {
      character: '🛒',
      title: 'shopping cart',
      category: 'Objects',
    },
    {
      character: '🚬',
      title: 'cigarette',
      category: 'Objects',
    },
    {
      character: '⚰️',
      title: 'coffin',
      category: 'Objects',
    },
    {
      character: '🪦',
      title: 'headstone',
      category: 'Objects',
    },
    {
      character: '⚱️',
      title: 'funeral urn',
      category: 'Objects',
    },
    {
      character: '🗿',
      title: 'moai',
      category: 'Objects',
    },
    {
      character: '🪧',
      title: 'placard',
      category: 'Objects',
    },
    {
      character: '🪪',
      title: 'identification card',
      category: 'Objects',
    },
    {
      character: '🏧',
      title: 'ATM sign',
      category: 'Symbols',
    },
    {
      character: '🚮',
      title: 'litter in bin sign',
      category: 'Symbols',
    },
    {
      character: '🚰',
      title: 'potable water',
      category: 'Symbols',
    },
    {
      character: '♿',
      title: 'wheelchair symbol',
      category: 'Symbols',
    },
    {
      character: '🚹',
      title: 'men’s room',
      category: 'Symbols',
    },
    {
      character: '🚺',
      title: 'women’s room',
      category: 'Symbols',
    },
    {
      character: '🚻',
      title: 'restroom',
      category: 'Symbols',
    },
    {
      character: '🚼',
      title: 'baby symbol',
      category: 'Symbols',
    },
    {
      character: '🚾',
      title: 'water closet',
      category: 'Symbols',
    },
    {
      character: '🛂',
      title: 'passport control',
      category: 'Symbols',
    },
    {
      character: '🛃',
      title: 'customs',
      category: 'Symbols',
    },
    {
      character: '🛄',
      title: 'baggage claim',
      category: 'Symbols',
    },
    {
      character: '🛅',
      title: 'left luggage',
      category: 'Symbols',
    },
    {
      character: '⚠️',
      title: 'warning',
      category: 'Symbols',
    },
    {
      character: '🚸',
      title: 'children crossing',
      category: 'Symbols',
    },
    {
      character: '⛔',
      title: 'no entry',
      category: 'Symbols',
    },
    {
      character: '🚫',
      title: 'prohibited',
      category: 'Symbols',
    },
    {
      character: '🚳',
      title: 'no bicycles',
      category: 'Symbols',
    },
    {
      character: '🚭',
      title: 'no smoking',
      category: 'Symbols',
    },
    {
      character: '🚯',
      title: 'no littering',
      category: 'Symbols',
    },
    {
      character: '🚱',
      title: 'non-potable water',
      category: 'Symbols',
    },
    {
      character: '🚷',
      title: 'no pedestrians',
      category: 'Symbols',
    },
    {
      character: '📵',
      title: 'no mobile phones',
      category: 'Symbols',
    },
    {
      character: '🔞',
      title: 'no one under eighteen',
      category: 'Symbols',
    },
    {
      character: '☢️',
      title: 'radioactive',
      category: 'Symbols',
    },
    {
      character: '☣️',
      title: 'biohazard',
      category: 'Symbols',
    },
    {
      character: '⬆️',
      title: 'up arrow',
      category: 'Symbols',
    },
    {
      character: '↗️',
      title: 'up-right arrow',
      category: 'Symbols',
    },
    {
      character: '➡️',
      title: 'right arrow',
      category: 'Symbols',
    },
    {
      character: '↘️',
      title: 'down-right arrow',
      category: 'Symbols',
    },
    {
      character: '⬇️',
      title: 'down arrow',
      category: 'Symbols',
    },
    {
      character: '↙️',
      title: 'down-left arrow',
      category: 'Symbols',
    },
    {
      character: '⬅️',
      title: 'left arrow',
      category: 'Symbols',
    },
    {
      character: '↖️',
      title: 'up-left arrow',
      category: 'Symbols',
    },
    {
      character: '↕️',
      title: 'up-down arrow',
      category: 'Symbols',
    },
    {
      character: '↔️',
      title: 'left-right arrow',
      category: 'Symbols',
    },
    {
      character: '↩️',
      title: 'right arrow curving left',
      category: 'Symbols',
    },
    {
      character: '↪️',
      title: 'left arrow curving right',
      category: 'Symbols',
    },
    {
      character: '⤴️',
      title: 'right arrow curving up',
      category: 'Symbols',
    },
    {
      character: '⤵️',
      title: 'right arrow curving down',
      category: 'Symbols',
    },
    {
      character: '🔃',
      title: 'clockwise vertical arrows',
      category: 'Symbols',
    },
    {
      character: '🔄',
      title: 'counterclockwise arrows button',
      category: 'Symbols',
    },
    {
      character: '🔙',
      title: 'BACK arrow',
      category: 'Symbols',
    },
    {
      character: '🔚',
      title: 'END arrow',
      category: 'Symbols',
    },
    {
      character: '🔛',
      title: 'ON! arrow',
      category: 'Symbols',
    },
    {
      character: '🔜',
      title: 'SOON arrow',
      category: 'Symbols',
    },
    {
      character: '🔝',
      title: 'TOP arrow',
      category: 'Symbols',
    },
    {
      character: '🛐',
      title: 'place of worship',
      category: 'Symbols',
    },
    {
      character: '⚛️',
      title: 'atom symbol',
      category: 'Symbols',
    },
    {
      character: '🕉️',
      title: 'om',
      category: 'Symbols',
    },
    {
      character: '✡️',
      title: 'star of David',
      category: 'Symbols',
    },
    {
      character: '☸️',
      title: 'wheel of dharma',
      category: 'Symbols',
    },
    {
      character: '☯️',
      title: 'yin yang',
      category: 'Symbols',
    },
    {
      character: '✝️',
      title: 'latin cross',
      category: 'Symbols',
    },
    {
      character: '☦️',
      title: 'orthodox cross',
      category: 'Symbols',
    },
    {
      character: '☪️',
      title: 'star and crescent',
      category: 'Symbols',
    },
    {
      character: '☮️',
      title: 'peace symbol',
      category: 'Symbols',
    },
    {
      character: '🕎',
      title: 'menorah',
      category: 'Symbols',
    },
    {
      character: '🔯',
      title: 'dotted six-pointed star',
      category: 'Symbols',
    },
    {
      character: '♈',
      title: 'Aries',
      category: 'Symbols',
    },
    {
      character: '♉',
      title: 'Taurus',
      category: 'Symbols',
    },
    {
      character: '♊',
      title: 'Gemini',
      category: 'Symbols',
    },
    {
      character: '♋',
      title: 'Cancer',
      category: 'Symbols',
    },
    {
      character: '♌',
      title: 'Leo',
      category: 'Symbols',
    },
    {
      character: '♍',
      title: 'Virgo',
      category: 'Symbols',
    },
    {
      character: '♎',
      title: 'Libra',
      category: 'Symbols',
    },
    {
      character: '♏',
      title: 'Scorpio',
      category: 'Symbols',
    },
    {
      character: '♐',
      title: 'Sagittarius',
      category: 'Symbols',
    },
    {
      character: '♑',
      title: 'Capricorn',
      category: 'Symbols',
    },
    {
      character: '♒',
      title: 'Aquarius',
      category: 'Symbols',
    },
    {
      character: '♓',
      title: 'Pisces',
      category: 'Symbols',
    },
    {
      character: '⛎',
      title: 'Ophiuchus',
      category: 'Symbols',
    },
    {
      character: '🔀',
      title: 'shuffle tracks button',
      category: 'Symbols',
    },
    {
      character: '🔁',
      title: 'repeat button',
      category: 'Symbols',
    },
    {
      character: '🔂',
      title: 'repeat single button',
      category: 'Symbols',
    },
    {
      character: '▶️',
      title: 'play button',
      category: 'Symbols',
    },
    {
      character: '⏩',
      title: 'fast-forward button',
      category: 'Symbols',
    },
    {
      character: '⏭️',
      title: 'next track button',
      category: 'Symbols',
    },
    {
      character: '⏯️',
      title: 'play or pause button',
      category: 'Symbols',
    },
    {
      character: '◀️',
      title: 'reverse button',
      category: 'Symbols',
    },
    {
      character: '⏪',
      title: 'fast reverse button',
      category: 'Symbols',
    },
    {
      character: '⏮️',
      title: 'last track button',
      category: 'Symbols',
    },
    {
      character: '🔼',
      title: 'upwards button',
      category: 'Symbols',
    },
    {
      character: '⏫',
      title: 'fast up button',
      category: 'Symbols',
    },
    {
      character: '🔽',
      title: 'downwards button',
      category: 'Symbols',
    },
    {
      character: '⏬',
      title: 'fast down button',
      category: 'Symbols',
    },
    {
      character: '⏸️',
      title: 'pause button',
      category: 'Symbols',
    },
    {
      character: '⏹️',
      title: 'stop button',
      category: 'Symbols',
    },
    {
      character: '⏺️',
      title: 'record button',
      category: 'Symbols',
    },
    {
      character: '⏏️',
      title: 'eject button',
      category: 'Symbols',
    },
    {
      character: '🎦',
      title: 'cinema',
      category: 'Symbols',
    },
    {
      character: '🔅',
      title: 'dim button',
      category: 'Symbols',
    },
    {
      character: '🔆',
      title: 'bright button',
      category: 'Symbols',
    },
    {
      character: '📶',
      title: 'antenna bars',
      category: 'Symbols',
    },
    {
      character: '📳',
      title: 'vibration mode',
      category: 'Symbols',
    },
    {
      character: '📴',
      title: 'mobile phone off',
      category: 'Symbols',
    },
    {
      character: '♀️',
      title: 'female sign',
      category: 'Symbols',
    },
    {
      character: '♂️',
      title: 'male sign',
      category: 'Symbols',
    },
    {
      character: '⚧️',
      title: 'transgender symbol',
      category: 'Symbols',
    },
    {
      character: '✖️',
      title: 'multiply',
      category: 'Symbols',
    },
    {
      character: '➕',
      title: 'plus',
      category: 'Symbols',
    },
    {
      character: '➖',
      title: 'minus',
      category: 'Symbols',
    },
    {
      character: '➗',
      title: 'divide',
      category: 'Symbols',
    },
    {
      character: '🟰',
      title: 'heavy equals sign',
      category: 'Symbols',
    },
    {
      character: '♾️',
      title: 'infinity',
      category: 'Symbols',
    },
    {
      character: '‼️',
      title: 'double exclamation mark',
      category: 'Symbols',
    },
    {
      character: '⁉️',
      title: 'exclamation question mark',
      category: 'Symbols',
    },
    {
      character: '❓',
      title: 'red question mark',
      category: 'Symbols',
    },
    {
      character: '❔',
      title: 'white question mark',
      category: 'Symbols',
    },
    {
      character: '❕',
      title: 'white exclamation mark',
      category: 'Symbols',
    },
    {
      character: '❗',
      title: 'red exclamation mark',
      category: 'Symbols',
    },
    {
      character: '〰️',
      title: 'wavy dash',
      category: 'Symbols',
    },
    {
      character: '💱',
      title: 'currency exchange',
      category: 'Symbols',
    },
    {
      character: '💲',
      title: 'heavy dollar sign',
      category: 'Symbols',
    },
    {
      character: '⚕️',
      title: 'medical symbol',
      category: 'Symbols',
    },
    {
      character: '♻️',
      title: 'recycling symbol',
      category: 'Symbols',
    },
    {
      character: '⚜️',
      title: 'fleur-de-lis',
      category: 'Symbols',
    },
    {
      character: '🔱',
      title: 'trident emblem',
      category: 'Symbols',
    },
    {
      character: '📛',
      title: 'name badge',
      category: 'Symbols',
    },
    {
      character: '🔰',
      title: 'Japanese symbol for beginner',
      category: 'Symbols',
    },
    {
      character: '⭕',
      title: 'hollow red circle',
      category: 'Symbols',
    },
    {
      character: '✅',
      title: 'check mark button',
      category: 'Symbols',
    },
    {
      character: '☑️',
      title: 'check box with check',
      category: 'Symbols',
    },
    {
      character: '✔️',
      title: 'check mark',
      category: 'Symbols',
    },
    {
      character: '❌',
      title: 'cross mark',
      category: 'Symbols',
    },
    {
      character: '❎',
      title: 'cross mark button',
      category: 'Symbols',
    },
    {
      character: '➰',
      title: 'curly loop',
      category: 'Symbols',
    },
    {
      character: '➿',
      title: 'double curly loop',
      category: 'Symbols',
    },
    {
      character: '〽️',
      title: 'part alternation mark',
      category: 'Symbols',
    },
    {
      character: '✳️',
      title: 'eight-spoked asterisk',
      category: 'Symbols',
    },
    {
      character: '✴️',
      title: 'eight-pointed star',
      category: 'Symbols',
    },
    {
      character: '❇️',
      title: 'sparkle',
      category: 'Symbols',
    },
    {
      character: '©️',
      title: 'copyright',
      category: 'Symbols',
    },
    {
      character: '®️',
      title: 'registered',
      category: 'Symbols',
    },
    {
      character: '™️',
      title: 'trade mark',
      category: 'Symbols',
    },
    {
      character: '#️⃣',
      title: 'keycap: #',
      category: 'Symbols',
    },
    {
      character: '*️⃣',
      title: 'keycap: *',
      category: 'Symbols',
    },
    {
      character: '0️⃣',
      title: 'keycap: 0',
      category: 'Symbols',
    },
    {
      character: '1️⃣',
      title: 'keycap: 1',
      category: 'Symbols',
    },
    {
      character: '2️⃣',
      title: 'keycap: 2',
      category: 'Symbols',
    },
    {
      character: '3️⃣',
      title: 'keycap: 3',
      category: 'Symbols',
    },
    {
      character: '4️⃣',
      title: 'keycap: 4',
      category: 'Symbols',
    },
    {
      character: '5️⃣',
      title: 'keycap: 5',
      category: 'Symbols',
    },
    {
      character: '6️⃣',
      title: 'keycap: 6',
      category: 'Symbols',
    },
    {
      character: '7️⃣',
      title: 'keycap: 7',
      category: 'Symbols',
    },
    {
      character: '8️⃣',
      title: 'keycap: 8',
      category: 'Symbols',
    },
    {
      character: '9️⃣',
      title: 'keycap: 9',
      category: 'Symbols',
    },
    {
      character: '🔟',
      title: 'keycap: 10',
      category: 'Symbols',
    },
    {
      character: '🔠',
      title: 'input latin uppercase',
      category: 'Symbols',
    },
    {
      character: '🔡',
      title: 'input latin lowercase',
      category: 'Symbols',
    },
    {
      character: '🔢',
      title: 'input numbers',
      category: 'Symbols',
    },
    {
      character: '🔣',
      title: 'input symbols',
      category: 'Symbols',
    },
    {
      character: '🔤',
      title: 'input latin letters',
      category: 'Symbols',
    },
    {
      character: '🅰️',
      title: 'A button (blood type)',
      category: 'Symbols',
    },
    {
      character: '🆎',
      title: 'AB button (blood type)',
      category: 'Symbols',
    },
    {
      character: '🅱️',
      title: 'B button (blood type)',
      category: 'Symbols',
    },
    {
      character: '🆑',
      title: 'CL button',
      category: 'Symbols',
    },
    {
      character: '🆒',
      title: 'COOL button',
      category: 'Symbols',
    },
    {
      character: '🆓',
      title: 'FREE button',
      category: 'Symbols',
    },
    {
      character: 'ℹ️',
      title: 'information',
      category: 'Symbols',
    },
    {
      character: '🆔',
      title: 'ID button',
      category: 'Symbols',
    },
    {
      character: 'Ⓜ️',
      title: 'circled M',
      category: 'Symbols',
    },
    {
      character: '🆕',
      title: 'NEW button',
      category: 'Symbols',
    },
    {
      character: '🆖',
      title: 'NG button',
      category: 'Symbols',
    },
    {
      character: '🅾️',
      title: 'O button (blood type)',
      category: 'Symbols',
    },
    {
      character: '🆗',
      title: 'OK button',
      category: 'Symbols',
    },
    {
      character: '🅿️',
      title: 'P button',
      category: 'Symbols',
    },
    {
      character: '🆘',
      title: 'SOS button',
      category: 'Symbols',
    },
    {
      character: '🆙',
      title: 'UP! button',
      category: 'Symbols',
    },
    {
      character: '🆚',
      title: 'VS button',
      category: 'Symbols',
    },
    {
      character: '🈁',
      title: 'Japanese “here” button',
      category: 'Symbols',
    },
    {
      character: '🈂️',
      title: 'Japanese “service charge” button',
      category: 'Symbols',
    },
    {
      character: '🈷️',
      title: 'Japanese “monthly amount” button',
      category: 'Symbols',
    },
    {
      character: '🈶',
      title: 'Japanese “not free of charge” button',
      category: 'Symbols',
    },
    {
      character: '🈯',
      title: 'Japanese “reserved” button',
      category: 'Symbols',
    },
    {
      character: '🉐',
      title: 'Japanese “bargain” button',
      category: 'Symbols',
    },
    {
      character: '🈹',
      title: 'Japanese “discount” button',
      category: 'Symbols',
    },
    {
      character: '🈚',
      title: 'Japanese “free of charge” button',
      category: 'Symbols',
    },
    {
      character: '🈲',
      title: 'Japanese “prohibited” button',
      category: 'Symbols',
    },
    {
      character: '🉑',
      title: 'Japanese “acceptable” button',
      category: 'Symbols',
    },
    {
      character: '🈸',
      title: 'Japanese “application” button',
      category: 'Symbols',
    },
    {
      character: '🈴',
      title: 'Japanese “passing grade” button',
      category: 'Symbols',
    },
    {
      character: '🈳',
      title: 'Japanese “vacancy” button',
      category: 'Symbols',
    },
    {
      character: '㊗️',
      title: 'Japanese “congratulations” button',
      category: 'Symbols',
    },
    {
      character: '㊙️',
      title: 'Japanese “secret” button',
      category: 'Symbols',
    },
    {
      character: '🈺',
      title: 'Japanese “open for business” button',
      category: 'Symbols',
    },
    {
      character: '🈵',
      title: 'Japanese “no vacancy” button',
      category: 'Symbols',
    },
    {
      character: '🔴',
      title: 'red circle',
      category: 'Symbols',
    },
    {
      character: '🟠',
      title: 'orange circle',
      category: 'Symbols',
    },
    {
      character: '🟡',
      title: 'yellow circle',
      category: 'Symbols',
    },
    {
      character: '🟢',
      title: 'green circle',
      category: 'Symbols',
    },
    {
      character: '🔵',
      title: 'blue circle',
      category: 'Symbols',
    },
    {
      character: '🟣',
      title: 'purple circle',
      category: 'Symbols',
    },
    {
      character: '🟤',
      title: 'brown circle',
      category: 'Symbols',
    },
    {
      character: '⚫',
      title: 'black circle',
      category: 'Symbols',
    },
    {
      character: '⚪',
      title: 'white circle',
      category: 'Symbols',
    },
    {
      character: '🟥',
      title: 'red square',
      category: 'Symbols',
    },
    {
      character: '🟧',
      title: 'orange square',
      category: 'Symbols',
    },
    {
      character: '🟨',
      title: 'yellow square',
      category: 'Symbols',
    },
    {
      character: '🟩',
      title: 'green square',
      category: 'Symbols',
    },
    {
      character: '🟦',
      title: 'blue square',
      category: 'Symbols',
    },
    {
      character: '🟪',
      title: 'purple square',
      category: 'Symbols',
    },
    {
      character: '🟫',
      title: 'brown square',
      category: 'Symbols',
    },
    {
      character: '⬛',
      title: 'black large square',
      category: 'Symbols',
    },
    {
      character: '⬜',
      title: 'white large square',
      category: 'Symbols',
    },
    {
      character: '◼️',
      title: 'black medium square',
      category: 'Symbols',
    },
    {
      character: '◻️',
      title: 'white medium square',
      category: 'Symbols',
    },
    {
      character: '◾',
      title: 'black medium-small square',
      category: 'Symbols',
    },
    {
      character: '◽',
      title: 'white medium-small square',
      category: 'Symbols',
    },
    {
      character: '▪️',
      title: 'black small square',
      category: 'Symbols',
    },
    {
      character: '▫️',
      title: 'white small square',
      category: 'Symbols',
    },
    {
      character: '🔶',
      title: 'large orange diamond',
      category: 'Symbols',
    },
    {
      character: '🔷',
      title: 'large blue diamond',
      category: 'Symbols',
    },
    {
      character: '🔸',
      title: 'small orange diamond',
      category: 'Symbols',
    },
    {
      character: '🔹',
      title: 'small blue diamond',
      category: 'Symbols',
    },
    {
      character: '🔺',
      title: 'red triangle pointed up',
      category: 'Symbols',
    },
    {
      character: '🔻',
      title: 'red triangle pointed down',
      category: 'Symbols',
    },
    {
      character: '💠',
      title: 'diamond with a dot',
      category: 'Symbols',
    },
    {
      character: '🔘',
      title: 'radio button',
      category: 'Symbols',
    },
    {
      character: '🔳',
      title: 'white square button',
      category: 'Symbols',
    },
    {
      character: '🔲',
      title: 'black square button',
      category: 'Symbols',
    },
    {
      character: '🏁',
      title: 'chequered flag',
      category: 'Flags',
    },
    {
      character: '🚩',
      title: 'triangular flag',
      category: 'Flags',
    },
    {
      character: '🎌',
      title: 'crossed flags',
      category: 'Flags',
    },
    {
      character: '🏴',
      title: 'black flag',
      category: 'Flags',
    },
    {
      character: '🏳️',
      title: 'white flag',
      category: 'Flags',
    },
    {
      character: '🏳️‍🌈',
      title: 'rainbow flag',
      category: 'Flags',
    },
    {
      character: '🏳️‍⚧️',
      title: 'transgender flag',
      category: 'Flags',
    },
    {
      character: '🏴‍☠️',
      title: 'pirate flag',
      category: 'Flags',
    },
    {
      character: '🇦🇨',
      title: 'flag: Ascension Island',
      category: 'Flags',
    },
    {
      character: '🇦🇩',
      title: 'flag: Andorra',
      category: 'Flags',
    },
    {
      character: '🇦🇪',
      title: 'flag: United Arab Emirates',
      category: 'Flags',
    },
    {
      character: '🇦🇫',
      title: 'flag: Afghanistan',
      category: 'Flags',
    },
    {
      character: '🇦🇬',
      title: 'flag: Antigua & Barbuda',
      category: 'Flags',
    },
    {
      character: '🇦🇮',
      title: 'flag: Anguilla',
      category: 'Flags',
    },
    {
      character: '🇦🇱',
      title: 'flag: Albania',
      category: 'Flags',
    },
    {
      character: '🇦🇲',
      title: 'flag: Armenia',
      category: 'Flags',
    },
    {
      character: '🇦🇴',
      title: 'flag: Angola',
      category: 'Flags',
    },
    {
      character: '🇦🇶',
      title: 'flag: Antarctica',
      category: 'Flags',
    },
    {
      character: '🇦🇷',
      title: 'flag: Argentina',
      category: 'Flags',
    },
    {
      character: '🇦🇸',
      title: 'flag: American Samoa',
      category: 'Flags',
    },
    {
      character: '🇦🇹',
      title: 'flag: Austria',
      category: 'Flags',
    },
    {
      character: '🇦🇺',
      title: 'flag: Australia',
      category: 'Flags',
    },
    {
      character: '🇦🇼',
      title: 'flag: Aruba',
      category: 'Flags',
    },
    {
      character: '🇦🇽',
      title: 'flag: Åland Islands',
      category: 'Flags',
    },
    {
      character: '🇦🇿',
      title: 'flag: Azerbaijan',
      category: 'Flags',
    },
    {
      character: '🇧🇦',
      title: 'flag: Bosnia & Herzegovina',
      category: 'Flags',
    },
    {
      character: '🇧🇧',
      title: 'flag: Barbados',
      category: 'Flags',
    },
    {
      character: '🇧🇩',
      title: 'flag: Bangladesh',
      category: 'Flags',
    },
    {
      character: '🇧🇪',
      title: 'flag: Belgium',
      category: 'Flags',
    },
    {
      character: '🇧🇫',
      title: 'flag: Burkina Faso',
      category: 'Flags',
    },
    {
      character: '🇧🇬',
      title: 'flag: Bulgaria',
      category: 'Flags',
    },
    {
      character: '🇧🇭',
      title: 'flag: Bahrain',
      category: 'Flags',
    },
    {
      character: '🇧🇮',
      title: 'flag: Burundi',
      category: 'Flags',
    },
    {
      character: '🇧🇯',
      title: 'flag: Benin',
      category: 'Flags',
    },
    {
      character: '🇧🇱',
      title: 'flag: St. Barthélemy',
      category: 'Flags',
    },
    {
      character: '🇧🇲',
      title: 'flag: Bermuda',
      category: 'Flags',
    },
    {
      character: '🇧🇳',
      title: 'flag: Brunei',
      category: 'Flags',
    },
    {
      character: '🇧🇴',
      title: 'flag: Bolivia',
      category: 'Flags',
    },
    {
      character: '🇧🇶',
      title: 'flag: Caribbean Netherlands',
      category: 'Flags',
    },
    {
      character: '🇧🇷',
      title: 'flag: Brazil',
      category: 'Flags',
    },
    {
      character: '🇧🇸',
      title: 'flag: Bahamas',
      category: 'Flags',
    },
    {
      character: '🇧🇹',
      title: 'flag: Bhutan',
      category: 'Flags',
    },
    {
      character: '🇧🇻',
      title: 'flag: Bouvet Island',
      category: 'Flags',
    },
    {
      character: '🇧🇼',
      title: 'flag: Botswana',
      category: 'Flags',
    },
    {
      character: '🇧🇾',
      title: 'flag: Belarus',
      category: 'Flags',
    },
    {
      character: '🇧🇿',
      title: 'flag: Belize',
      category: 'Flags',
    },
    {
      character: '🇨🇦',
      title: 'flag: Canada',
      category: 'Flags',
    },
    {
      character: '🇨🇨',
      title: 'flag: Cocos (Keeling) Islands',
      category: 'Flags',
    },
    {
      character: '🇨🇩',
      title: 'flag: Congo - Kinshasa',
      category: 'Flags',
    },
    {
      character: '🇨🇫',
      title: 'flag: Central African Republic',
      category: 'Flags',
    },
    {
      character: '🇨🇬',
      title: 'flag: Congo - Brazzaville',
      category: 'Flags',
    },
    {
      character: '🇨🇭',
      title: 'flag: Switzerland',
      category: 'Flags',
    },
    {
      character: '🇨🇮',
      title: 'flag: Côte d’Ivoire',
      category: 'Flags',
    },
    {
      character: '🇨🇰',
      title: 'flag: Cook Islands',
      category: 'Flags',
    },
    {
      character: '🇨🇱',
      title: 'flag: Chile',
      category: 'Flags',
    },
    {
      character: '🇨🇲',
      title: 'flag: Cameroon',
      category: 'Flags',
    },
    {
      character: '🇨🇳',
      title: 'flag: China',
      category: 'Flags',
    },
    {
      character: '🇨🇴',
      title: 'flag: Colombia',
      category: 'Flags',
    },
    {
      character: '🇨🇵',
      title: 'flag: Clipperton Island',
      category: 'Flags',
    },
    {
      character: '🇨🇷',
      title: 'flag: Costa Rica',
      category: 'Flags',
    },
    {
      character: '🇨🇺',
      title: 'flag: Cuba',
      category: 'Flags',
    },
    {
      character: '🇨🇻',
      title: 'flag: Cape Verde',
      category: 'Flags',
    },
    {
      character: '🇨🇼',
      title: 'flag: Curaçao',
      category: 'Flags',
    },
    {
      character: '🇨🇽',
      title: 'flag: Christmas Island',
      category: 'Flags',
    },
    {
      character: '🇨🇾',
      title: 'flag: Cyprus',
      category: 'Flags',
    },
    {
      character: '🇨🇿',
      title: 'flag: Czechia',
      category: 'Flags',
    },
    {
      character: '🇩🇪',
      title: 'flag: Germany',
      category: 'Flags',
    },
    {
      character: '🇩🇬',
      title: 'flag: Diego Garcia',
      category: 'Flags',
    },
    {
      character: '🇩🇯',
      title: 'flag: Djibouti',
      category: 'Flags',
    },
    {
      character: '🇩🇰',
      title: 'flag: Denmark',
      category: 'Flags',
    },
    {
      character: '🇩🇲',
      title: 'flag: Dominica',
      category: 'Flags',
    },
    {
      character: '🇩🇴',
      title: 'flag: Dominican Republic',
      category: 'Flags',
    },
    {
      character: '🇩🇿',
      title: 'flag: Algeria',
      category: 'Flags',
    },
    {
      character: '🇪🇦',
      title: 'flag: Ceuta & Melilla',
      category: 'Flags',
    },
    {
      character: '🇪🇨',
      title: 'flag: Ecuador',
      category: 'Flags',
    },
    {
      character: '🇪🇪',
      title: 'flag: Estonia',
      category: 'Flags',
    },
    {
      character: '🇪🇬',
      title: 'flag: Egypt',
      category: 'Flags',
    },
    {
      character: '🇪🇭',
      title: 'flag: Western Sahara',
      category: 'Flags',
    },
    {
      character: '🇪🇷',
      title: 'flag: Eritrea',
      category: 'Flags',
    },
    {
      character: '🇪🇸',
      title: 'flag: Spain',
      category: 'Flags',
    },
    {
      character: '🇪🇹',
      title: 'flag: Ethiopia',
      category: 'Flags',
    },
    {
      character: '🇪🇺',
      title: 'flag: European Union',
      category: 'Flags',
    },
    {
      character: '🇫🇮',
      title: 'flag: Finland',
      category: 'Flags',
    },
    {
      character: '🇫🇯',
      title: 'flag: Fiji',
      category: 'Flags',
    },
    {
      character: '🇫🇰',
      title: 'flag: Falkland Islands',
      category: 'Flags',
    },
    {
      character: '🇫🇲',
      title: 'flag: Micronesia',
      category: 'Flags',
    },
    {
      character: '🇫🇴',
      title: 'flag: Faroe Islands',
      category: 'Flags',
    },
    {
      character: '🇫🇷',
      title: 'flag: France',
      category: 'Flags',
    },
    {
      character: '🇬🇦',
      title: 'flag: Gabon',
      category: 'Flags',
    },
    {
      character: '🇬🇧',
      title: 'flag: United Kingdom',
      category: 'Flags',
    },
    {
      character: '🇬🇩',
      title: 'flag: Grenada',
      category: 'Flags',
    },
    {
      character: '🇬🇪',
      title: 'flag: Georgia',
      category: 'Flags',
    },
    {
      character: '🇬🇫',
      title: 'flag: French Guiana',
      category: 'Flags',
    },
    {
      character: '🇬🇬',
      title: 'flag: Guernsey',
      category: 'Flags',
    },
    {
      character: '🇬🇭',
      title: 'flag: Ghana',
      category: 'Flags',
    },
    {
      character: '🇬🇮',
      title: 'flag: Gibraltar',
      category: 'Flags',
    },
    {
      character: '🇬🇱',
      title: 'flag: Greenland',
      category: 'Flags',
    },
    {
      character: '🇬🇲',
      title: 'flag: Gambia',
      category: 'Flags',
    },
    {
      character: '🇬🇳',
      title: 'flag: Guinea',
      category: 'Flags',
    },
    {
      character: '🇬🇵',
      title: 'flag: Guadeloupe',
      category: 'Flags',
    },
    {
      character: '🇬🇶',
      title: 'flag: Equatorial Guinea',
      category: 'Flags',
    },
    {
      character: '🇬🇷',
      title: 'flag: Greece',
      category: 'Flags',
    },
    {
      character: '🇬🇸',
      title: 'flag: South Georgia & South Sandwich Islands',
      category: 'Flags',
    },
    {
      character: '🇬🇹',
      title: 'flag: Guatemala',
      category: 'Flags',
    },
    {
      character: '🇬🇺',
      title: 'flag: Guam',
      category: 'Flags',
    },
    {
      character: '🇬🇼',
      title: 'flag: Guinea-Bissau',
      category: 'Flags',
    },
    {
      character: '🇬🇾',
      title: 'flag: Guyana',
      category: 'Flags',
    },
    {
      character: '🇭🇰',
      title: 'flag: Hong Kong SAR China',
      category: 'Flags',
    },
    {
      character: '🇭🇲',
      title: 'flag: Heard & McDonald Islands',
      category: 'Flags',
    },
    {
      character: '🇭🇳',
      title: 'flag: Honduras',
      category: 'Flags',
    },
    {
      character: '🇭🇷',
      title: 'flag: Croatia',
      category: 'Flags',
    },
    {
      character: '🇭🇹',
      title: 'flag: Haiti',
      category: 'Flags',
    },
    {
      character: '🇭🇺',
      title: 'flag: Hungary',
      category: 'Flags',
    },
    {
      character: '🇮🇨',
      title: 'flag: Canary Islands',
      category: 'Flags',
    },
    {
      character: '🇮🇩',
      title: 'flag: Indonesia',
      category: 'Flags',
    },
    {
      character: '🇮🇪',
      title: 'flag: Ireland',
      category: 'Flags',
    },
    {
      character: '🇮🇱',
      title: 'flag: Israel',
      category: 'Flags',
    },
    {
      character: '🇮🇲',
      title: 'flag: Isle of Man',
      category: 'Flags',
    },
    {
      character: '🇮🇳',
      title: 'flag: India',
      category: 'Flags',
    },
    {
      character: '🇮🇴',
      title: 'flag: British Indian Ocean Territory',
      category: 'Flags',
    },
    {
      character: '🇮🇶',
      title: 'flag: Iraq',
      category: 'Flags',
    },
    {
      character: '🇮🇷',
      title: 'flag: Iran',
      category: 'Flags',
    },
    {
      character: '🇮🇸',
      title: 'flag: Iceland',
      category: 'Flags',
    },
    {
      character: '🇮🇹',
      title: 'flag: Italy',
      category: 'Flags',
    },
    {
      character: '🇯🇪',
      title: 'flag: Jersey',
      category: 'Flags',
    },
    {
      character: '🇯🇲',
      title: 'flag: Jamaica',
      category: 'Flags',
    },
    {
      character: '🇯🇴',
      title: 'flag: Jordan',
      category: 'Flags',
    },
    {
      character: '🇯🇵',
      title: 'flag: Japan',
      category: 'Flags',
    },
    {
      character: '🇰🇪',
      title: 'flag: Kenya',
      category: 'Flags',
    },
    {
      character: '🇰🇬',
      title: 'flag: Kyrgyzstan',
      category: 'Flags',
    },
    {
      character: '🇰🇭',
      title: 'flag: Cambodia',
      category: 'Flags',
    },
    {
      character: '🇰🇮',
      title: 'flag: Kiribati',
      category: 'Flags',
    },
    {
      character: '🇰🇲',
      title: 'flag: Comoros',
      category: 'Flags',
    },
    {
      character: '🇰🇳',
      title: 'flag: St. Kitts & Nevis',
      category: 'Flags',
    },
    {
      character: '🇰🇵',
      title: 'flag: North Korea',
      category: 'Flags',
    },
    {
      character: '🇰🇷',
      title: 'flag: South Korea',
      category: 'Flags',
    },
    {
      character: '🇰🇼',
      title: 'flag: Kuwait',
      category: 'Flags',
    },
    {
      character: '🇰🇾',
      title: 'flag: Cayman Islands',
      category: 'Flags',
    },
    {
      character: '🇰🇿',
      title: 'flag: Kazakhstan',
      category: 'Flags',
    },
    {
      character: '🇱🇦',
      title: 'flag: Laos',
      category: 'Flags',
    },
    {
      character: '🇱🇧',
      title: 'flag: Lebanon',
      category: 'Flags',
    },
    {
      character: '🇱🇨',
      title: 'flag: St. Lucia',
      category: 'Flags',
    },
    {
      character: '🇱🇮',
      title: 'flag: Liechtenstein',
      category: 'Flags',
    },
    {
      character: '🇱🇰',
      title: 'flag: Sri Lanka',
      category: 'Flags',
    },
    {
      character: '🇱🇷',
      title: 'flag: Liberia',
      category: 'Flags',
    },
    {
      character: '🇱🇸',
      title: 'flag: Lesotho',
      category: 'Flags',
    },
    {
      character: '🇱🇹',
      title: 'flag: Lithuania',
      category: 'Flags',
    },
    {
      character: '🇱🇺',
      title: 'flag: Luxembourg',
      category: 'Flags',
    },
    {
      character: '🇱🇻',
      title: 'flag: Latvia',
      category: 'Flags',
    },
    {
      character: '🇱🇾',
      title: 'flag: Libya',
      category: 'Flags',
    },
    {
      character: '🇲🇦',
      title: 'flag: Morocco',
      category: 'Flags',
    },
    {
      character: '🇲🇨',
      title: 'flag: Monaco',
      category: 'Flags',
    },
    {
      character: '🇲🇩',
      title: 'flag: Moldova',
      category: 'Flags',
    },
    {
      character: '🇲🇪',
      title: 'flag: Montenegro',
      category: 'Flags',
    },
    {
      character: '🇲🇫',
      title: 'flag: St. Martin',
      category: 'Flags',
    },
    {
      character: '🇲🇬',
      title: 'flag: Madagascar',
      category: 'Flags',
    },
    {
      character: '🇲🇭',
      title: 'flag: Marshall Islands',
      category: 'Flags',
    },
    {
      character: '🇲🇰',
      title: 'flag: North Macedonia',
      category: 'Flags',
    },
    {
      character: '🇲🇱',
      title: 'flag: Mali',
      category: 'Flags',
    },
    {
      character: '🇲🇲',
      title: 'flag: Myanmar (Burma)',
      category: 'Flags',
    },
    {
      character: '🇲🇳',
      title: 'flag: Mongolia',
      category: 'Flags',
    },
    {
      character: '🇲🇴',
      title: 'flag: Macao SAR China',
      category: 'Flags',
    },
    {
      character: '🇲🇵',
      title: 'flag: Northern Mariana Islands',
      category: 'Flags',
    },
    {
      character: '🇲🇶',
      title: 'flag: Martinique',
      category: 'Flags',
    },
    {
      character: '🇲🇷',
      title: 'flag: Mauritania',
      category: 'Flags',
    },
    {
      character: '🇲🇸',
      title: 'flag: Montserrat',
      category: 'Flags',
    },
    {
      character: '🇲🇹',
      title: 'flag: Malta',
      category: 'Flags',
    },
    {
      character: '🇲🇺',
      title: 'flag: Mauritius',
      category: 'Flags',
    },
    {
      character: '🇲🇻',
      title: 'flag: Maldives',
      category: 'Flags',
    },
    {
      character: '🇲🇼',
      title: 'flag: Malawi',
      category: 'Flags',
    },
    {
      character: '🇲🇽',
      title: 'flag: Mexico',
      category: 'Flags',
    },
    {
      character: '🇲🇾',
      title: 'flag: Malaysia',
      category: 'Flags',
    },
    {
      character: '🇲🇿',
      title: 'flag: Mozambique',
      category: 'Flags',
    },
    {
      character: '🇳🇦',
      title: 'flag: Namibia',
      category: 'Flags',
    },
    {
      character: '🇳🇨',
      title: 'flag: New Caledonia',
      category: 'Flags',
    },
    {
      character: '🇳🇪',
      title: 'flag: Niger',
      category: 'Flags',
    },
    {
      character: '🇳🇫',
      title: 'flag: Norfolk Island',
      category: 'Flags',
    },
    {
      character: '🇳🇬',
      title: 'flag: Nigeria',
      category: 'Flags',
    },
    {
      character: '🇳🇮',
      title: 'flag: Nicaragua',
      category: 'Flags',
    },
    {
      character: '🇳🇱',
      title: 'flag: Netherlands',
      category: 'Flags',
    },
    {
      character: '🇳🇴',
      title: 'flag: Norway',
      category: 'Flags',
    },
    {
      character: '🇳🇵',
      title: 'flag: Nepal',
      category: 'Flags',
    },
    {
      character: '🇳🇷',
      title: 'flag: Nauru',
      category: 'Flags',
    },
    {
      character: '🇳🇺',
      title: 'flag: Niue',
      category: 'Flags',
    },
    {
      character: '🇳🇿',
      title: 'flag: New Zealand',
      category: 'Flags',
    },
    {
      character: '🇴🇲',
      title: 'flag: Oman',
      category: 'Flags',
    },
    {
      character: '🇵🇦',
      title: 'flag: Panama',
      category: 'Flags',
    },
    {
      character: '🇵🇪',
      title: 'flag: Peru',
      category: 'Flags',
    },
    {
      character: '🇵🇫',
      title: 'flag: French Polynesia',
      category: 'Flags',
    },
    {
      character: '🇵🇬',
      title: 'flag: Papua New Guinea',
      category: 'Flags',
    },
    {
      character: '🇵🇭',
      title: 'flag: Philippines',
      category: 'Flags',
    },
    {
      character: '🇵🇰',
      title: 'flag: Pakistan',
      category: 'Flags',
    },
    {
      character: '🇵🇱',
      title: 'flag: Poland',
      category: 'Flags',
    },
    {
      character: '🇵🇲',
      title: 'flag: St. Pierre & Miquelon',
      category: 'Flags',
    },
    {
      character: '🇵🇳',
      title: 'flag: Pitcairn Islands',
      category: 'Flags',
    },
    {
      character: '🇵🇷',
      title: 'flag: Puerto Rico',
      category: 'Flags',
    },
    {
      character: '🇵🇸',
      title: 'flag: Palestinian Territories',
      category: 'Flags',
    },
    {
      character: '🇵🇹',
      title: 'flag: Portugal',
      category: 'Flags',
    },
    {
      character: '🇵🇼',
      title: 'flag: Palau',
      category: 'Flags',
    },
    {
      character: '🇵🇾',
      title: 'flag: Paraguay',
      category: 'Flags',
    },
    {
      character: '🇶🇦',
      title: 'flag: Qatar',
      category: 'Flags',
    },
    {
      character: '🇷🇪',
      title: 'flag: Réunion',
      category: 'Flags',
    },
    {
      character: '🇷🇴',
      title: 'flag: Romania',
      category: 'Flags',
    },
    {
      character: '🇷🇸',
      title: 'flag: Serbia',
      category: 'Flags',
    },
    {
      character: '🇷🇺',
      title: 'flag: Russia',
      category: 'Flags',
    },
    {
      character: '🇷🇼',
      title: 'flag: Rwanda',
      category: 'Flags',
    },
    {
      character: '🇸🇦',
      title: 'flag: Saudi Arabia',
      category: 'Flags',
    },
    {
      character: '🇸🇧',
      title: 'flag: Solomon Islands',
      category: 'Flags',
    },
    {
      character: '🇸🇨',
      title: 'flag: Seychelles',
      category: 'Flags',
    },
    {
      character: '🇸🇩',
      title: 'flag: Sudan',
      category: 'Flags',
    },
    {
      character: '🇸🇪',
      title: 'flag: Sweden',
      category: 'Flags',
    },
    {
      character: '🇸🇬',
      title: 'flag: Singapore',
      category: 'Flags',
    },
    {
      character: '🇸🇭',
      title: 'flag: St. Helena',
      category: 'Flags',
    },
    {
      character: '🇸🇮',
      title: 'flag: Slovenia',
      category: 'Flags',
    },
    {
      character: '🇸🇯',
      title: 'flag: Svalbard & Jan Mayen',
      category: 'Flags',
    },
    {
      character: '🇸🇰',
      title: 'flag: Slovakia',
      category: 'Flags',
    },
    {
      character: '🇸🇱',
      title: 'flag: Sierra Leone',
      category: 'Flags',
    },
    {
      character: '🇸🇲',
      title: 'flag: San Marino',
      category: 'Flags',
    },
    {
      character: '🇸🇳',
      title: 'flag: Senegal',
      category: 'Flags',
    },
    {
      character: '🇸🇴',
      title: 'flag: Somalia',
      category: 'Flags',
    },
    {
      character: '🇸🇷',
      title: 'flag: Suriname',
      category: 'Flags',
    },
    {
      character: '🇸🇸',
      title: 'flag: South Sudan',
      category: 'Flags',
    },
    {
      character: '🇸🇹',
      title: 'flag: São Tomé & Príncipe',
      category: 'Flags',
    },
    {
      character: '🇸🇻',
      title: 'flag: El Salvador',
      category: 'Flags',
    },
    {
      character: '🇸🇽',
      title: 'flag: Sint Maarten',
      category: 'Flags',
    },
    {
      character: '🇸🇾',
      title: 'flag: Syria',
      category: 'Flags',
    },
    {
      character: '🇸🇿',
      title: 'flag: Eswatini',
      category: 'Flags',
    },
    {
      character: '🇹🇦',
      title: 'flag: Tristan da Cunha',
      category: 'Flags',
    },
    {
      character: '🇹🇨',
      title: 'flag: Turks & Caicos Islands',
      category: 'Flags',
    },
    {
      character: '🇹🇩',
      title: 'flag: Chad',
      category: 'Flags',
    },
    {
      character: '🇹🇫',
      title: 'flag: French Southern Territories',
      category: 'Flags',
    },
    {
      character: '🇹🇬',
      title: 'flag: Togo',
      category: 'Flags',
    },
    {
      character: '🇹🇭',
      title: 'flag: Thailand',
      category: 'Flags',
    },
    {
      character: '🇹🇯',
      title: 'flag: Tajikistan',
      category: 'Flags',
    },
    {
      character: '🇹🇰',
      title: 'flag: Tokelau',
      category: 'Flags',
    },
    {
      character: '🇹🇱',
      title: 'flag: Timor-Leste',
      category: 'Flags',
    },
    {
      character: '🇹🇲',
      title: 'flag: Turkmenistan',
      category: 'Flags',
    },
    {
      character: '🇹🇳',
      title: 'flag: Tunisia',
      category: 'Flags',
    },
    {
      character: '🇹🇴',
      title: 'flag: Tonga',
      category: 'Flags',
    },
    {
      character: '🇹🇷',
      title: 'flag: Turkey',
      category: 'Flags',
    },
    {
      character: '🇹🇹',
      title: 'flag: Trinidad & Tobago',
      category: 'Flags',
    },
    {
      character: '🇹🇻',
      title: 'flag: Tuvalu',
      category: 'Flags',
    },
    {
      character: '🇹🇼',
      title: 'flag: Taiwan',
      category: 'Flags',
    },
    {
      character: '🇹🇿',
      title: 'flag: Tanzania',
      category: 'Flags',
    },
    {
      character: '🇺🇦',
      title: 'flag: Ukraine',
      category: 'Flags',
    },
    {
      character: '🇺🇬',
      title: 'flag: Uganda',
      category: 'Flags',
    },
    {
      character: '🇺🇲',
      title: 'flag: U.S. Outlying Islands',
      category: 'Flags',
    },
    {
      character: '🇺🇳',
      title: 'flag: United Nations',
      category: 'Flags',
    },
    {
      character: '🇺🇸',
      title: 'flag: United States',
      category: 'Flags',
    },
    {
      character: '🇺🇾',
      title: 'flag: Uruguay',
      category: 'Flags',
    },
    {
      character: '🇺🇿',
      title: 'flag: Uzbekistan',
      category: 'Flags',
    },
    {
      character: '🇻🇦',
      title: 'flag: Vatican City',
      category: 'Flags',
    },
    {
      character: '🇻🇨',
      title: 'flag: St. Vincent & Grenadines',
      category: 'Flags',
    },
    {
      character: '🇻🇪',
      title: 'flag: Venezuela',
      category: 'Flags',
    },
    {
      character: '🇻🇬',
      title: 'flag: British Virgin Islands',
      category: 'Flags',
    },
    {
      character: '🇻🇮',
      title: 'flag: U.S. Virgin Islands',
      category: 'Flags',
    },
    {
      character: '🇻🇳',
      title: 'flag: Vietnam',
      category: 'Flags',
    },
    {
      character: '🇻🇺',
      title: 'flag: Vanuatu',
      category: 'Flags',
    },
    {
      character: '🇼🇫',
      title: 'flag: Wallis & Futuna',
      category: 'Flags',
    },
    {
      character: '🇼🇸',
      title: 'flag: Samoa',
      category: 'Flags',
    },
    {
      character: '🇽🇰',
      title: 'flag: Kosovo',
      category: 'Flags',
    },
    {
      character: '🇾🇪',
      title: 'flag: Yemen',
      category: 'Flags',
    },
    {
      character: '🇾🇹',
      title: 'flag: Mayotte',
      category: 'Flags',
    },
    {
      character: '🇿🇦',
      title: 'flag: South Africa',
      category: 'Flags',
    },
    {
      character: '🇿🇲',
      title: 'flag: Zambia',
      category: 'Flags',
    },
    {
      character: '🇿🇼',
      title: 'flag: Zimbabwe',
      category: 'Flags',
    },
    {
      character: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
      title: 'flag: England',
      category: 'Flags',
    },
    {
      character: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
      title: 'flag: Scotland',
      category: 'Flags',
    },
    {
      character: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
      title: 'flag: Wales',
      category: 'Flags',
    }, */
  ]);
}
