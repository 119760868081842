
import { defineComponent, ref } from 'vue';
import useUtils from '@/composables/useUtils';
import useI18nUtils from '@/composables/useI18nUtils';
import { ModalProgrammatic } from '@oruga-ui/oruga';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/stores/app.store';
import { useUserStore } from '@/stores/user.store';
import { useAuthStore } from '@/stores/auth.store';
import { useRightsStore } from '@/stores/rights.store';
import { useBotsStore } from '@/stores/bots.store';

export default defineComponent({
  setup() {
    const isReduced = ref(true);

    function toggle() {
      isReduced.value = !isReduced.value;
    }

    const { copy } = useUtils();
    const { currentLocale, locales } = useI18nUtils();

    const appStore = useAppStore();
    const { version } = storeToRefs(appStore);

    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    const botsStore = useBotsStore();
    const { bot } = storeToRefs(botsStore);

    const rightsStore = useRightsStore();
    const { rights } = storeToRefs(rightsStore);

    const authStore = useAuthStore();
    const { jwt, decodedJwt, profileUuid } = storeToRefs(authStore);

    return {
      // data
      isReduced,
      currentLocale,
      locales,
      version,
      user,
      // useAuthStore
      jwt,
      decodedJwt,
      profileUuid,
      // useRightsStore
      rights,
      // useBotsStore
      bot,

      // methods
      toggle,
      copy,
    };
  },

  methods: {
    openDecodedJwtModal() {
      const { decodedJwt } = this;

      const h = this.$createElement;
      const vnode = h('pre', { class: 'ui-modal-card overflow-auto' }, [
        JSON.stringify(decodedJwt, null, 2),
      ]);

      ModalProgrammatic.open({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        content: [vnode] as any,
      });
    },

    openUserModal() {
      const { user } = this;

      const h = this.$createElement;
      const vnode = h('pre', { class: 'ui-modal-card overflow-auto' }, [
        JSON.stringify(user, null, 2),
      ]);

      ModalProgrammatic.open({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        content: [vnode] as any,
      });
    },

    openWorkspaceModal() {
      const { bot } = this;

      const h = this.$createElement;
      const vnode = h('pre', { class: 'ui-modal-card overflow-auto' }, [
        JSON.stringify(bot, null, 2),
      ]);

      ModalProgrammatic.open({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        content: [vnode] as any,
      });
    },

    openRightsModal() {
      const { rights } = this;

      const h = this.$createElement;
      const vnode = h('pre', { class: 'ui-modal-card overflow-auto' }, [
        JSON.stringify(rights, null, 2),
      ]);

      ModalProgrammatic.open({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        content: [vnode] as any,
      });
    },
  },
});
