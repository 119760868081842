import ApiProtected from './ApiProtected';

class ChannelService extends ApiProtected {
  private static classInstance?: ChannelService;

  private constructor() {
    super(new URL(
      process.env.VUE_APP_CBM_CHANNEL_API_PREFIX,
      process.env.VUE_APP_CBM_CHANNEL_API_URL,
    ).href);
  }

  public static getInstance(): ChannelService {
    if (!this.classInstance) {
      this.classInstance = new ChannelService();
    }

    return this.classInstance;
  }
}

export default ChannelService;
