
import { useLicenseStore } from '@/stores/license.store';
import { useUserStore } from '@/stores/user.store';
import { storeToRefs } from 'pinia';
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  setup() {
    const licenseStore = useLicenseStore();

    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    const isExpired = ref(false);

    onMounted(() => {
      if (licenseStore.license) {
        isExpired.value = !licenseStore.license.active;
      }
    });

    return {
      isExpired,
      user,
    };
  },
});
