
import { defineComponent, PropType } from 'vue';
import { BaseEntry } from '@/models/entry.schema';

export default defineComponent({
  props: {
    knowledge: {
      type: Object as PropType<BaseEntry>,
      required: true,
    },
  },
});
