
import { defineComponent, PropType } from 'vue';

interface BaseSelectOptions {
  text: string,
  value: string | number
}

export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      required: true,
    },

    options: {
      type: Array as PropType<BaseSelectOptions[]>,
      required: false,
      default: () => [] as BaseSelectOptions[],
    },
  },

  methods: {
    isSelected(option: BaseSelectOptions) {
      return this.value === option.value;
    },
  },
});
