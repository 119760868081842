
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    image: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'user',
    },
  },
});
