/* eslint-disable import/prefer-default-export */
import { v4 as uuidv4 } from 'uuid';
/**
 * JSON.parse(JSON.stringify(o)) is faster than lodash.cloneDeep(o)
 * when the object to clone is JSON compatible.
 * See: https://github.com/lodash/lodash/issues/1984
 *
 * @param object the object to clone
 * @returns the cloned object
 */
export const JSONclone = (object: unknown) => JSON.parse(JSON.stringify(object));

/**
 * Transform any string into a safe, normalized string that can be used to
 *
 * @param text the original text
 * @param {{lowercase: boolean}} options
 * @returns the slugified text
 */
export function slugify(text = 'undefined', options: { lowercase: boolean; } = { lowercase: true }): string {
  let val = `${text}`.toString().trim();
  if (options.lowercase) val = val.toLowerCase();

  return val.normalize('NFD') // separate accent from letter
    .replace(/[\u0300-\u036f]/g, '') // remove all separated accents
    .replace(/\s+/g, '-') // replace spaces with -
    .replace(/&/g, '-and-') // replace & with 'and'
    .replace(/[^\w-]+/g, '') // remove all non-word chars
    .replace(/--+/g, '-'); // replace multiple '-' with single '-'
}

/**
 * Generate a short, sufficiently unique ID, optionally prefixed with a given string.
 * Example: uniqId('Some String') => some-string-dc1126de
 *
 * @param text
 * @returns {string}
 */
export function shortId(text?: string): string {
  return `${text ? `${slugify(text)}-` : ''}${uuidv4().split('-')[0]}`;
}

/**
 * Creates a new URL by combining the specified URLs
 * See: https://github.com/axios/axios/blob/v0.x/lib/helpers/combineURLs.js
 *
 * @param {string} baseURL The base URL
 * @param {string} relativeURL The relative URL
 *
 * @returns {string} The combined URL
 */
export function combineURLs(baseURL: string, relativeURL: string) {
  return relativeURL
    ? `${baseURL.replace(/\/+$/, '')}/${relativeURL.replace(/^\/+/, '')}`
    : baseURL;
}
