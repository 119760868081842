import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import onError from './handlers/onError';

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],

    appVersion: '1.1.0',
    collectUserIp: true,
    releaseStage: process.env.VUE_APP_ENVIRONMENT || 'local',

    redactedKeys: [
      /.*authorization.*/i,
      /.*key.*/i,
      /.*secret.*/i,
      /.*token.*/i,
      /.*password.*/i,
    ],

    onError,
  });

  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue?.installVueErrorHandler(Vue);
}
