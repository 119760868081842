
import { defineComponent } from 'vue';

/**
 * Inspired from https://github.com/eliep/vue-avatar/blob/master/src/Avatar.vue.
 */
export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    initials: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'base',
    },
    shape: {
      type: String,
      default: 'circle',
    },
  },
  data() {
    return {
      backgroundColors: [
        '#F44336', '#FF4081', '#9C27B0', '#673AB7',
        '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688',
        '#4CAF50', '#8BC34A', '#CDDC39', '#FFC107',
        '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B',
      ],
    };
  },
  computed: {
    background(): string {
      return this.backgroundColor
        || this.randomBackgroundColor(this.label.length, this.backgroundColors);
    },
    computedInitials(): string {
      const initials = this.initials || this.getInitials(this.label);
      return initials;
    },
  },
  mounted() {
    this.$emit('avatar-initials', this.label, this.computedInitials);
  },
  methods: {
    getInitials(username: string) {
      const parts = username.split(/[ -]/);
      let initials = '';
      for (let i = 0; i < parts.length; i += 1) {
        initials += parts[i].charAt(0);
      }
      if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '');
      }
      initials = initials.substr(0, 3).toUpperCase();
      return initials;
    },
    randomBackgroundColor(seed: number, colors: Array<string>) {
      return colors[seed % (colors.length)];
    },
  },
});
