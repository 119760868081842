import { AxiosRequestConfig } from 'axios';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth.store';
import HttpClient from '../HttpClient';

class ApiProtected extends HttpClient {
  public constructor(baseURL: string) {
    super(baseURL);

    this.initializeRequestInterceptor();
  }

  private initializeRequestInterceptor() {
    this.instance.interceptors.request.use(
      this.handleRequest,
      this.handleError,
    );
  }

  private handleRequest(config: AxiosRequestConfig) {
    if (!config?.headers) {
      throw new Error('Expected \'config\' and \'config.headers\' to be defined.');
    }

    const authStore = useAuthStore();
    const { jwt } = storeToRefs(authStore);
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    if (jwt.value) config.headers.Authorization = `Bearer ${jwt.value}`;

    return config;
  }
}

export default ApiProtected;
