/* eslint-disable camelcase */
import actions from './common/actions.json';
import errors from './common/errors.json';
import fields from './common/fields.json';
import labels from './common/labels.json';
import notifications from './common/notifications.json';
import validations from './common/validations';
import sidebar_menu from './components/sidebar_menu.json';
import blocks from './components/blocks.json';

export default {
  message: 'bienvenue i18n !!',

  // Validation messages
  validations: validations.fr,

  // Common
  actions: actions.fr,
  errors: errors.fr,
  fields: fields.fr,
  labels: labels.fr,
  notifications: notifications.fr,

  // Components
  sidebar_menu: sidebar_menu.fr,
  blocks: blocks.fr,
};
