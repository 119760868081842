
import {
  computed, defineComponent, PropType, ref, watchEffect,
} from 'vue';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import List from '@ckeditor/ckeditor5-list/src/list';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import Markdown from '@ckeditor/ckeditor5-markdown-gfm/src/markdown';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import SpecialCharactersEmojis from '@/components/base/base-editor/plugins/CbmSpecialCharactersEmojis';
import CbmImageUploadAdapterPlugin from '@/components/base/base-editor/plugins/CbmImageUploadAdapter';
import CbmAddEntryPlugin from '@/components/base/base-editor/plugins/CbmAddEntryPlugin';
import CbmFileUploadPlugin from '@/components/base/base-editor/plugins/CbmFileUploadPlugin';
import CbmGiphyPlugin from '@/components/base/base-editor/plugins/CbmGiphyPlugin';
import { BaseFile } from '@/models/files.schema';
import { ModalProgrammatic } from '@oruga-ui/oruga';
import FileUploadModal from '@/components/common/_modals/FileUploadModal.vue';
import useI18nUtils from '@/composables/useI18nUtils';

/**
 * Base input with form validation provided by Vuelidate.
 */
export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
    },

    botUuid: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    v: {
      type: Object,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    canAddEntries: {
      type: Boolean,
      default: false,
    },

    canUploadFiles: {
      type: Boolean,
      default: false,
    },

    files: {
      type: Array as PropType<BaseFile[]>,
      default: () => [],
    },

    limitHeight: {
      type: Boolean,
      default: false,
    },

    hasShadow: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const editorInstance = ref(null) as null | any;

    const editorData = computed({
      get() { return props.value; },
      set(value) { emit('input', value); },
    });

    const localFiles = computed({
      get() { return props.files; },
      set(value) { emit('update:files', value); },
    });

    watchEffect(() => {
      if (editorInstance.value) {
        if (props.disabled) {
          editorInstance.value.enableReadOnlyMode('ready-only');
        } else {
          editorInstance.value.disableReadOnlyMode('ready-only');
        }
      }
    });

    const { currentLocale } = useI18nUtils();

    return {
      editorInstance,
      editorData,
      localFiles,
      currentLocale,
    };
  },

  data() {
    return {
      editor: ClassicEditor,
    };
  },

  computed: {
    editorConfig(): Record<string, unknown> {
      const options = {
        language: this.currentLocale.slice(0, 2),
        placeholder: this.placeholder || this.$t('placeholder'),

        plugins: [
          Essentials,
          Bold,
          Italic,
          Underline,
          Link,
          Paragraph,
          Heading,
          List,

          Image,
          ImageCaption,
          ImageInsert,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          ImageUpload,

          Markdown,
          SpecialCharacters,
          SpecialCharactersEssentials,
          SpecialCharactersEmojis,
          CbmImageUploadAdapterPlugin,
          CbmGiphyPlugin,
        ],

        toolbar: {
          items: [
            'heading',
            'bold',
            'italic',
            'underline',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'link',
            'uploadImage',
            // 'insertImage', // Uncomment to enable adding images by URL
            'addEntry',
            'fileUpload',
            'giphy',
            'specialCharacters',
            '|',
            'undo',
            'redo',
          ],
        },

        image: {
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null,
              label: '100%',
            },
            {
              name: 'resizeImage:50',
              value: '50',
              label: '50%',
            },
            {
              name: 'resizeImage:25',
              value: '25',
              label: '25%',
            },
          ],
          toolbar: [
            'resizeImage',
            'imageTextAlternative',
          ],
        },

        cbmImageUpload: {
          botUuid: this.botUuid,
        },

        cbmFileUpload: {
          botUuid: this.botUuid,
        },

        cbmAddEntry: {
          botUuid: this.botUuid,
        },
      };

      if (this.canAddEntries) {
        options.plugins.push(CbmAddEntryPlugin);
      }

      if (this.canUploadFiles) {
        options.plugins.push(CbmFileUploadPlugin);
      }

      return options;
    },
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onReady(editor: any) {
      this.editorInstance = editor;

      // Insert the toolbar before the editable area.
      editor.model.document.on('fileUpload', () => {
        this.openAddFileModal();
      });

      const toolbarElement = editor.ui.view.toolbar.element;

      editor.on('change:isReadOnly', (_event: unknown, _propertyName: string, isReadOnly: boolean) => {
        if (isReadOnly) {
          toolbarElement.style.display = 'none';
        } else {
          toolbarElement.style.display = 'flex';
        }
      });
    },

    openAddFileModal() {
      ModalProgrammatic.open({
        parent: this,
        component: FileUploadModal,
        props: {
          botUuid: this.botUuid,
          files: this.localFiles,
        },
        events: {
          submit: (value: BaseFile[]) => {
            this.localFiles = value;
          },
        },
      });
    },

    deleteFile(index: number) {
      this.localFiles.splice(index, 1);
    },
  },
});
