
import { defineComponent } from 'vue';
import { isString } from 'lodash';

export default defineComponent({
  props: {
    rootClass: {
      type: [String, Array],
      default: () => [] as unknown[],
    },

    light: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedRootClass(): unknown[] {
      let { rootClass } = this;

      if (isString(this.rootClass)) {
        rootClass = [this.rootClass];
      }

      return [...rootClass,
        this.light ? 'o-tabs--light' : '',
      ];
    },
  },
});
