
import useVuelidate from '@vuelidate/core';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },

    open: {
      type: Boolean,
      required: false,
    },

    ariaId: {
      type: String,
      required: false,
      default: '',
    },

    showButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup() {
    const v$ = useVuelidate({
      $autoDirty: true,
    });

    return {
      v$,
    };
  },

  data() {
    return {
      isOpen: this.open,
    };
  },

  watch: {
    open(newValue: boolean) {
      this.isOpen = newValue;
    },
  },

  methods: {
    handleToggle() {
      if (!this.isOpen) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
      this.isOpen = !this.isOpen;
    },
  },
});
