
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },

    size: {
      type: String,
      required: false,
      default: '',
    },

    icon: {
      type: String,
      required: false,
      default: 'triangle-exclamation',
    },

    triggers: {
      type: Array,
      required: false,
      default: () => ['click'],
    },
  },

  data() {
    return {
      isActive: !this.triggers.includes('click'),
    };
  },

  computed: {
    triggerOnClick(): boolean {
      return this.triggers.includes('click');
    },
  },

  methods: {
    toggleTooltip() {
      if (this.triggerOnClick) {
        this.isActive = !this.isActive;
      }
    },
  },
});
