import { combineURLs } from '@/utils/Helpers';
import ApiProtected from './ApiProtected';

class MediaService extends ApiProtected {
  private static classInstance?: MediaService;

  private constructor() {
    super(new URL(
      process.env.VUE_APP_CBM_MEDIA_API_PREFIX,
      process.env.VUE_APP_CBM_MEDIA_API_URL,
    ).href);
  }

  public static getInstance(): MediaService {
    if (!this.classInstance) {
      this.classInstance = new MediaService();
    }

    return this.classInstance;
  }

  getDownloadUrl(botUuid: string, fileUuid: string) {
    return combineURLs(this.baseUrl, `processes/bots/${botUuid}/files/${fileUuid}/redirect`);
  }
}

export default MediaService;
