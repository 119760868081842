var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.loading)?_c('button',{staticClass:"w-full flex items-center overflow-hidden outline-none px-4 hover:bg-gray-50 focus:bg-gray-100",class:[
    _vm.active ? 'font-bold text-gray-800' : 'font-medium',
    {
      'h-12 text-sm': _vm.size === 'base',
      'h-10 text-sm': _vm.size === 'sm',
      'h-8 text-xs': _vm.size === 'xs',
    },
  ],attrs:{"title":_vm.label,"type":"button"},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"focusin":function($event){_vm.hover = true},"focusout":function($event){_vm.hover = false}}},[(_vm.icon)?_c('div',{staticClass:"w-8 shrink-0 text-gray-400 mr-2"},[(_vm.isString(_vm.icon))?_c('base-icon',{attrs:{"icon":_vm.icon}}):(!_vm.isString(_vm.icon) && _vm.icon.type === 'svg')?_c('ui-svg-icon',{attrs:{"icon":_vm.icon.name,"variant":_vm.active ? 'active' : (_vm.hover ? 'hover' : _vm.icon.variant)}}):_vm._e()],1):_vm._t("left"),_c('span',{staticClass:"pr-4 text-left truncate",attrs:{"title":_vm.label}},[_vm._v(_vm._s(_vm.label))]),(_vm.iconRight)?_c('div',{staticClass:"shrink-0 flex items-center ml-auto text-gray-300"},[_c('base-icon',{attrs:{"icon":_vm.iconRight}})],1):_vm._t("right")],2):_c('div',{staticClass:"px-4 py-2 flex items-center gap-4"},[_c('base-skeleton',{staticClass:"w-auto",attrs:{"circle":"","width":"32px","height":"32px"}}),_c('base-skeleton',{attrs:{"size":"small"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }