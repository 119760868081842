
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    variant: {
      type: String,
      default: null,
    },
    light: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    containerClass(): unknown[] {
      return ['ui-tag', {
        'ui-tag--primary': this.variant === 'primary',
        'ui-tag--info': this.variant === 'info',
        'ui-tag--success': this.variant === 'success',
        'ui-tag--warning': this.variant === 'warning',
        'ui-tag--danger': this.variant === 'danger',
        'ui-tag--rounded': this.rounded,
        'ui-tag--light': this.light,
        'ui-tag--clickable': this.clickable,
      }];
    },
    iconClass(): unknown[] {
      return ['ui-tag-icon', this.icon];
    },
  },

  methods: {
    /**
     * Emit close event when delete button is clicked or delete key is pressed.
     */
    close() {
      if (this.disabled) return;
      this.$emit('close');
    },

    /**
     * Emit click event when tag is clicked.
     */
    click() {
      if (this.disabled) return;
      this.$emit('click');
    },
  },
});
