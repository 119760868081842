import i18n from '@/plugins/i18n';
import { notifySuccess } from '@/utils/NotificationUtils';

export default function useUtils() {
  const copy = (value: string, successNotification?: string) => {
    navigator.clipboard.writeText(value);
    notifySuccess(successNotification || i18n.t('notifications.success.text_copied') as string);
  };

  return {
    copy,
  };
}
