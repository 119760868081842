
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    payload: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      default: 'text',
    },

    noSubmit: {
      type: Boolean,
      default: false,
    },
  },
});
