
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    submit() {
      this.$router.push('/pricing');

      this.$emit('submit');
      this.$emit('close');
    },
  },
});
