
import { defineComponent } from 'vue';
import { findLast } from 'lodash';
import { RouteRecord } from 'vue-router';

const DEFAULT_LAYOUT = 'AppLayoutDefault';

export default defineComponent({
  name: 'AppLayout',

  data() {
    return {
      keepAlive: ['AppLayoutDefault'],
    };
  },

  computed: {
    layout(): { name: string, component: unknown } {
      const layout = this.findLayout();

      return {
        name: layout,
        component: () => import(`@/layouts/${layout}.vue`),
      };
    },
  },

  methods: {
    findLayout() {
      const lastRouteRecordWithLayout = findLast(
        this.$route.matched,
        (record: RouteRecord) => record.meta.layout,
      ) as RouteRecord | undefined;

      const layout = this.$route.meta?.layout
        // Get the last defined layout from parent routes
        || lastRouteRecordWithLayout?.meta?.layout
        || DEFAULT_LAYOUT;

      return layout;
    },
  },
});
