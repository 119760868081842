/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';

/**
 * App store.
 */
export const useAppStore = defineStore('app', {
  state: () => ({
    version: '1.1.0',

    ui: {
      sidebar: {
        isOpen: false,
      },
    },

    availableValues: {
      statFrequencies: ['never', 'day', 'week', 'month'],
    },
  }),

  getters: {
    isSidebarOpen: (state) => state.ui.sidebar.isOpen,
  },

  actions: {
    toggleSidebar(payload?: boolean) {
      this.ui.sidebar.isOpen = payload !== undefined ? payload : !this.ui.sidebar.isOpen;
    },
  },
});
