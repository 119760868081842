import { notifyError, notifyWarning } from '@/utils/NotificationUtils';
import { v4 as uuidv4 } from 'uuid';
import ErrorTypes from './models/ErrorTypes';

abstract class ErrorHandler {
  protected error: Error;

  protected message: string | null | undefined;

  protected type: ErrorTypes;

  protected uuid: string;

  public constructor(
    error: Error,
    message?: string | null,
    type: ErrorTypes = ErrorTypes.DANGER,
  ) {
    this.error = error;
    this.message = message;
    this.type = type;
    this.uuid = uuidv4();

    this.handleError();
  }

  handleError(): void {
    this.logError();
    if (this.message) this.notifyError(this.message);
  }

  logError(): void {
    console.log(`[Clevy][${this.constructor.name}]`, this.error);
  }

  notifyError(message: string, params?: Record<string, unknown>): void {
    switch (this.type) {
      case ErrorTypes.WARNING:
        notifyWarning(message, params);
        break;
      default:
        notifyError(message, params);
        break;
    }
  }
}

export default ErrorHandler;
