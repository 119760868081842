/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export default {
  request(request, token) {
    this.drivers.http.setHeaders.call(this, request, {
      Authorization: `Bearer ${token}`,
    });
  },

  // eslint-disable-next-line consistent-return
  response(response) {
    if (response.data) {
      const { token } = response.data;
      if (token) return token;
    }
  },
};
