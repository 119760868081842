import ApiProtected from './ApiProtected';

class KnowledgeService extends ApiProtected {
  private static classInstance?: KnowledgeService;

  private constructor() {
    super(new URL(
      process.env.VUE_APP_CBM_INBOX_API_PREFIX,
      process.env.VUE_APP_CBM_INBOX_API_URL,
    ).href);
  }

  public static getInstance(): KnowledgeService {
    if (!this.classInstance) {
      this.classInstance = new KnowledgeService();
    }

    return this.classInstance;
  }
}

export default KnowledgeService;
