import showdown from 'showdown';

showdown.setFlavor('github');

const converter = new showdown.Converter({
  literalMidWordUnderscores: true,
  noHeaderId: true,
  omitExtraWLInCodeBlocks: true,
  simplifiedAutoLink: true,
  requireSpaceBeforeHeadingText: true,
  openLinksInNewWindow: true,
  strikethrough: true,
  excludeTrailingPunctuationFromURLs: true,
  parseImgDimensions: true,
});

class MarkdownUtils {
  /**
   * Transform HTML to markdown.
   *
   * @param {String} markdown
   * @return {String}
   */
  static htmlToMarkdown(html: string): string {
    if (!html) return '';

    return converter.makeMarkdown(html);
  }

  /**
   * Transform markdown to HTML.
   * @param {string} markdown
   * @return {string}
   */
  static markdownToHtml(markdown: string) {
    if (!markdown) return '';

    return converter.makeHtml(markdown);
  }

  /**
   * Transform markdown to a raw string
   * @param {string} markdown
   * @return {string}
   */
  static markdownToRawText(markdown: string) {
    // Convert to pure HTML
    const html = converter.makeHtml(markdown);

    // Create a new div element
    const tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }
}

export default MarkdownUtils;
