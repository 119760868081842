import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import users from '@/views/users/users';
import groups from '@/views/groups/groups';
import training from '@/views/training/routes';
import { ActionCbmBotKnowledge, ActionCbmBotKnowledgeForm } from '@clevy/cbm-mixed-types/dist/src/services/security/acl/subTypes.acl';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true,
    },
    redirect: '/inbox',
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
    meta: {
      layout: 'AppLayoutBlank',
    },
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Pricing.vue'),
    meta: {
      allowExpired: true,
    },
  },

  {
    path: '/auth',
    component: () => import('@/views/auth/index.vue'),
    meta: {
      auth: false,
      layout: 'AppLayoutBlank',
      allowExpired: true,
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
        props: (route) => ({ t: route.query.t, p: route.query.p }),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/auth/Register.vue'),
        meta: {
          auth: undefined,
        },
        props: (route) => ({ t: route.query.t, p: route.query.p }),
      },
      {
        path: 'signup',
        name: 'signup',
        component: () => import('@/views/auth/Signup.vue'),
        props: (route) => ({ email: route.query.email }),
        meta: {
          auth: undefined,
        },
      },
      {
        path: 'signup-success',
        name: 'signup-success',
        component: () => import('@/views/auth/SignupSuccess.vue'),
        props: (route) => ({ email: route.query.email }),
        meta: {
          auth: undefined,
        },
      },
      {
        path: 'register-internal',
        name: 'register-internal',
        component: () => import('@/views/auth/RegisterInternal.vue'),
        meta: {
          auth: undefined,
        },
      },
      {
        path: 'oauth-callback',
        name: 'oauth-callback',
        component: () => import('@/views/auth/OauthCallback.vue'),
        meta: {
          auth: undefined,
        },
        props: (route) => ({
          p: route.query.p,
          jwt: route.query.jwt,
          email: route.query.email,
          state: route.query.state,
          profileMetadata: route.query.profileMetadata,
        }),
      },
      {
        path: 'password/new',
        name: 'auth_password_new',
        component: () => import('@/views/auth/PasswordNew.vue'),
      },
      {
        path: 'password/edit',
        component: () => import('@/views/auth/PasswordChange.vue'),
        props: (route) => ({ t: route.query.t }),
      },
    ],
  },

  {
    path: '/errors',
    name: 'errors',
    components: {
      default: () => import('@/views/errors/index.vue'),
    },
    // meta: {
    //   auth: false, // TODO: set back to true when redirect issue is fixed
    // },
  },
  {
    path: '/errors/:errorUuid',
    name: 'error_uuid',
    component: () => import('@/views/errors/_errorUuid/index.vue'),
    meta: {
      // auth: false, // TODO: set back to true when redirect issue is fixed
      routes: {
        previous: { name: 'errors' },
      },
    },
    props: true,
  },

  {
    path: '/public/event/:eventUuid/webticket',
    name: 'public_webticket',
    component: () => import(/* webpackChunkName: "public_webticket" */ '@/views/public/event/Webticket.vue'),
    meta: {
      layout: 'AppLayoutBlank',
    },
    props: (route) => ({ ...route.query, ...route.params }),
  },

  {
    path: '/inbox',
    alias: '/inbox/created',
    components: {
      default: () => import('@/views/inbox/index.vue'),
    },
    beforeEnter: (to, from, next) => {
      // If the "from" route is not part of the inbox, clean filters and sort options
      if (!from.path.includes('inbox')) {
        sessionStorage.removeItem('persist.InboxFilters.formData');
        sessionStorage.removeItem('persist.InboxView.filter');
        sessionStorage.removeItem('persist.InboxView.filterQuery');
        sessionStorage.removeItem('persist.InboxView.sortOptions');
      }
      next();
    },
    meta: {
      auth: true,
    },
  },
  {
    path: '/inbox/handled',
    components: {
      default: () => import('@/views/inbox/handled.vue'),
    },
    beforeEnter: (to, from, next) => {
      // If the "from" route is not part of the inbox, clean filters and sort options
      if (!from.path.includes('inbox')) {
        sessionStorage.removeItem('persist.InboxFilters.formData');
        sessionStorage.removeItem('persist.InboxView.filter');
        sessionStorage.removeItem('persist.InboxView.filterQuery');
        sessionStorage.removeItem('persist.InboxView.sortOptions');
      }
      next();
    },
    meta: {
      auth: true,
    },
  },
  {
    path: '/inbox/archived',
    components: {
      default: () => import('@/views/inbox/archived.vue'),
    },
    beforeEnter: (to, from, next) => {
      // If the "from" route is not part of the inbox, clean filters and sort options
      if (!from.path.includes('inbox')) {
        sessionStorage.removeItem('persist.InboxFilters.formData');
        sessionStorage.removeItem('persist.InboxView.filter');
        sessionStorage.removeItem('persist.InboxView.filterQuery');
        sessionStorage.removeItem('persist.InboxView.sortOptions');
      }
      next();
    },
    meta: {
      auth: true,
    },
  },

  {
    path: '/bots',
    components: {
      default: () => import('@/views/bots/index.vue'),
    },
    meta: {
      auth: true,
    },
    children: [
      {
        path: ':botUuid',
        name: 'bot_uuid',
        redirect: '/bots/:botUuid/knowledge',
      },
      {
        path: ':botUuid/channels',
        name: 'bot_uuid_channels',
        component: () => import('@/views/bots/_botUuid/channels/index.vue'),
        props: true,
      },
      {
        path: '/bots/:botUuid/channel/edit/:entryUuid?',
        alias: '/bots/:botUuid/channel/new/:type',
        name: 'bot_uuid_channel_new',
        component: () => import('@/views/bots/_botUuid/channelNew/index.vue'),
        props: (route) => ({ ...route.query, ...route.params }),
        meta: {
          auth: true,
          routes: {
            previous: { name: 'bot_uuid_channels' },
          },
        },
      },
      {
        path: ':botUuid/settings',
        name: 'bot_uuid_settings',
        component: () => import('@/views/bots/_botUuid/settings/index.vue'),
        props: true,
        meta: {
          auth: true,
        },
      },
    ],
  },

  {
    path: '/settings/organization',
    name: 'settings_organization',
    component: () => import('@/views/settingsOrganization/index.vue'),
    props: true,
    meta: {
      auth: true,
    },
  },

  {
    path: '/bots/:botUuid/knowledge',
    name: 'bot_uuid_knowledge',
    component: () => import('@/views/bots/_botUuid/knowledge/index.vue'),
    props: true,
    beforeEnter: (to, from, next) => {
      if (!from.path.includes('knowledge')) {
        sessionStorage.removeItem('persist.KnowledgeFilters.formData');
        sessionStorage.removeItem('persist.KnowledgeList.formData');
        sessionStorage.removeItem('persist.KnowledgeList.filter');
        sessionStorage.removeItem('persist.KnowledgeList.filterQuery');
        sessionStorage.removeItem('persist.KnowledgeList.filterValues');
      }
      next();
    },
    meta: {
      auth: true,
      right: ActionCbmBotKnowledge.ACCESS_KNOWLEDGE,
    },
  },
  {
    path: '/bots/:botUuid/knowledge/new/:entryUuid?',
    alias: '/bots/:botUuid/knowledge/edit/:entryUuid',
    name: 'bot_uuid_knowledge_new',
    component: () => import('@/views/bots/_botUuid/knowledgeNew/index.vue'),
    props: true,
    meta: {
      auth: true,
      layout: 'AppLayoutBlank',
      routes: {
        previous: { name: 'bot_uuid_knowledge' },
      },
    },
  },
  {
    path: '/bots/:botUuid/vocabulary',
    name: 'bot_uuid_vocabulary',
    component: () => import('@/views/bots/_botUuid/BotVocabulary.vue'),
    props: true,
    meta: {
      auth: true,
    },
  },
  {
    path: '/bots/:botUuid/files/:folderUuid?',
    name: 'bot_uuid_files',
    component: () => import('@/views/bots/_botUuid/files/index.vue'),
    props: true,
    meta: {
      auth: true,
    },
  },

  {
    path: '/bots/:botUuid/forms',
    name: 'bot_uuid_forms',
    component: () => import('@/views/bots/_botUuid/forms/index.vue'),
    props: true,
    meta: {
      auth: true,
      right: ActionCbmBotKnowledgeForm.ACCESS_FORMS,
    },
  },
  {
    path: '/bots/:botUuid/form/new/:formUuid?',
    alias: '/bots/:botUuid/form/edit/:formUuid',
    name: 'bot_uuid_form_new',
    component: () => import('@/views/bots/_botUuid/formNew/index.vue'),
    props: true,
    meta: {
      auth: true,
      layout: 'AppLayoutBlank',
      routes: {
        previous: { name: 'bot_uuid_forms' },
      },
    },
  },

  {
    path: '/bots/:botUuid/apps',
    name: 'bot_uuid_apps',
    component: () => import('@/views/bots/_botUuid/apps/index.vue'),
    props: true,
    meta: {
      auth: true,
    },
  },
  {
    path: '/bots/:botUuid/apps/:appUuid',
    name: 'bot_uuid_app_new',
    component: () => import('@/views/bots/_botUuid/appNew/index.vue'),
    props: true,
    meta: {
      auth: true,
    },
  },
  {
    path: '/bots/:botUuid/bundles',
    name: 'bot_uuid_bundles',
    component: () => import('@/views/bots/_botUuid/bundles/index.vue'),
    props: true,
    meta: {
      auth: true,
    },
  },
  {
    path: '/bots/:botUuid/bundles/:bundleUuid',
    name: 'bot_uuid_bundle_new',
    component: () => import('@/views/bots/_botUuid/bundleNew/index.vue'),
    props: true,
    meta: {
      auth: true,
      routes: {
        previous: { name: 'bot_uuid_bundles' },
      },
    },
  },
  {
    path: '/bots/:botUuid/statistics',
    name: 'bot_uuid_statistics',
    component: () => import('@/views/bots/_botUuid/statistics/index.vue'),
    props: true,
    meta: {
      auth: true,
    },
  },
  {
    path: '/bots/:botUuid/tags',
    name: 'bot_uuid_tags',
    component: () => import('@/views/bots/_botUuid/tags/index.vue'),
    props: true,
    meta: {
      auth: true,
    },
  },

  {
    path: '/inbox/associate',
    name: 'event_uuid_associate',
    component: () => import('@/views/inboxAssociate/AssociateMultiple.vue'),
    props: (route) => ({
      eventUuids: Array.isArray(route.query.eventUuids)
        ? route.query.eventUuids
        : [route.query.eventUuids],
    }),
    meta: {
      auth: true,
    },
  },

  {
    path: '/inbox/message',
    name: 'event_uuid_message',
    component: () => import('@/views/inboxMessage/index.vue'),
    props: (route) => ({
      eventUuids: Array.isArray(route.query.eventUuids)
        ? route.query.eventUuids
        : [route.query.eventUuids],
    }),
    meta: {
      auth: true,
      layout: 'AppLayoutSidebar',
    },
  },

  {
    path: '/inbox/ticket',
    name: 'event_uuid_ticket',
    component: () => import('@/views/inboxTicket/index.vue'),
    props: (route) => ({
      eventUuids: Array.isArray(route.query.eventUuids)
        ? route.query.eventUuids
        : [route.query.eventUuids],
    }),
    meta: {
      auth: true,
      layout: 'AppLayoutSidebar',
    },
  },

  {
    path: '/user',
    component: () => import('@/views/user/index.vue'),
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'user_profile',
        component: () => import('@/views/user/UserProfile.vue'),
      },
      {
        path: 'password/edit',
        component: () => import('@/views/user/UserPassword.vue'),
      },
    ],
  },

  ...users,
  ...groups,
  ...training,

  {
    path: '/404',
    component: () => import('@/views/errors/NotFound.vue'),
    meta: {
      layout: 'AppLayoutBlank',
    },
  },

  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.afterEach((to, from) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window as any).analytics) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).analytics.page(to.path, {
      fullPath: to.fullPath,
    });
  }
});

export default router;
