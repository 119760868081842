
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },

    active: {
      type: Boolean,
      required: false,
      default: false,
    },

    size: {
      type: String,
      required: false,
      default: '',
    },

    variant: {
      type: String,
      default: 'text',
    },
  },

  computed: {
    computedSize(): string[] {
      switch (this.size) {
        case 'is-small': return ['h-6 w-6'];
        default: return ['h-10 w-10'];
      }
    },
  },
});
