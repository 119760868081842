
import { defineComponent, PropType } from 'vue';
import { upperFirst, snakeCase } from 'lodash';
import { ModalProgrammatic } from '@oruga-ui/oruga';
import UiModalConfirm from '@/components/ui/UiModalConfirm.vue';
import useVuelidate from '@vuelidate/core';

const restrictedNames = ['mainText', 'mainEmail', 'mainAttachments', 'mainTags', 'mainTitle'];

export default defineComponent({
  props: {
    botUuid: {
      type: String,
      required: true,
    },

    block: {
      type: Object,
      required: true,
    },

    payload: {
      type: [String, Object] as PropType<string | Record<string, unknown>>,
      required: false,
      default: '',
    },

    group: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    isLast: {
      type: Boolean,
      required: true,
    },

    isFirst: {
      type: Boolean,
      required: true,
    },

    isEditing: {
      type: Boolean,
      default: -1,
    },

    index: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const v$ = useVuelidate({
      $autoDirty: true,
    });

    return {
      v$,
    };
  },

  data() {
    return {
      editOnly: false,
    };
  },

  computed: {
    isApp(): boolean {
      return this.block.type === 'csml' && this.block.subtype === 'app';
    },

    isRestricted(): boolean {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return restrictedNames.includes((this.payload as any)?.name);
    },

    /**
     * Restricted components should not be removable unless the component payload says otherwise,
     * i.e the mainEmail that can be inserted in a custom form
     */
    isRemovable(): boolean {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return !this.isRestricted || (this.payload as any).isRemovable;
    },

    translatedBlockType(): string {
      if (restrictedNames.includes(this.block.payload?.name)) {
        return this.$t('labels.widget_form_ticketing') as string;
      }
      const translatedBlockTypes = ['alternative', 'contentBlock', 'contentSlider', 'fileUpload'];
      if (translatedBlockTypes.includes(this.block.type)) {
        return this.$t(`labels.widget_${snakeCase(this.block.type)}`) as string;
      }
      return upperFirst(this.block.type);
    },

    translatedBlockSubType(): string {
      if (restrictedNames.includes(this.block.payload?.name)) {
        return this.$t(`labels.widget_form_${snakeCase(this.block.payload.name)}_subtype`) as string;
      }
      const translatedBlockSubtypes = ['publicTags'];
      if (translatedBlockSubtypes.includes(this.block.subtype)) {
        return this.$t(`labels.widget_${snakeCase(this.block.type)}_${snakeCase(this.block.subtype)}`) as string;
      }
      return upperFirst(this.block.subtype);
    },

    capitalizedBlockType(): string {
      return upperFirst(this.block.type);
    },

    capitalizedBlockSubtype(): string {
      return upperFirst(this.block.subtype);
    },

    component(): unknown {
      if (this.block.subtype) {
        const widgetName = this.capitalizedBlockType + this.capitalizedBlockSubtype;
        return () => import(`./${this.block.type}/${widgetName}Widget.vue`);
      }
      return () => import(`./${this.capitalizedBlockType}Widget.vue`);
    },
  },

  methods: {
    updatePayload(value: unknown) {
      this.$emit('update:payload', value);
    },

    deleteWidget() {
      ModalProgrammatic.open({
        parent: this,
        component: UiModalConfirm,
        props: {
          title: this.$t('actions.delete') as string,
          message: this.$t('delete_block') as string,
          confirmText: this.$t('actions.delete') as string,
          variant: 'danger',
        },
        events: {
          submit: () => this.$emit('delete'),
        },
      });
    },

    duplicateWidget() {
      ModalProgrammatic.open({
        parent: this,
        component: UiModalConfirm,
        props: {
          title: this.$t('actions.duplicate') as string,
          message: this.$t('duplicate_block') as string,
          confirmText: this.$t('actions.duplicate') as string,
          variant: 'info',
        },
        events: {
          submit: () => this.$emit('duplicate'),
        },
      });
    },
  },
});
