import Vue from 'vue';
import App from './App.vue';
import router from './router';
import pinia from './stores';

import './assets/scss/bulma.scss';

import './components/index';
import './plugins/tailwind/index';
import './plugins/ck-editor';
import './plugins/fontawesome';
import i18n from './plugins/i18n';
import './plugins/katex';
import './plugins/vue-auth/index';
import './plugins/vue-gtm';
import './plugins/bugsnag/index';
import './plugins/oruga';
import './directives/right.directive';

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  pinia,
  // @ts-ignore
  render: (h) => h(App),
}).$mount('#app');
