
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  computed, defineComponent, provide, ref,
} from 'vue';
import i18n from '@/plugins/i18n';
import AppLayout from '@/layouts/AppLayout.vue';
import AppDebugBar from '@/components/common/AppDebugBar.vue';
import AppForceResubscribe from '@/components/common/AppForceResubscribe.vue';
// import AppDenyAccess from '@/components/common/AppDenyAccess.vue';
import UiLoadingAppLayoutDefault from '@/components/ui/loaders/UiLoadingAppLayoutDefault.vue';
import { storeToRefs } from 'pinia';
import { useI18nStore } from '@/stores/i18n.store';
import { useUserStore } from '@/stores/user.store';
import { useBotsStore } from '@/stores/bots.store';
import { useSettingsStore } from '@/stores/settings.store';
import { useAuthStore } from '@/stores/auth.store';
import { useLicenseStore } from '@/stores/license.store';
import { useRightsStore } from '@/stores/rights.store';

export default defineComponent({
  components: {
    AppLayout,
    AppDebugBar,
    AppForceResubscribe,
    // AppDenyAccess,
    UiLoadingAppLayoutDefault,
  },

  setup() {
    const isLoading = ref(false);
    const i18nStore = useI18nStore();

    const locale = computed(() => i18nStore.locale);

    // Change locale if locale from localStorage is different from the default locale
    if (locale.value !== i18n.locale) {
      i18nStore.updateLocale(locale.value);
    }

    const userStore = useUserStore();
    const rightsStore = useRightsStore();
    const botsStore = useBotsStore();
    const settingsStore = useSettingsStore();
    const authStore = useAuthStore();
    const licenseStore = useLicenseStore();
    const { profileUuid } = storeToRefs(authStore);

    const organizationUuid = computed(() => userStore.organizationUuid);
    provide('organizationUuid', organizationUuid);

    const botUuid = computed(() => botsStore.bot?.uuid);
    provide('botUuid', botUuid);

    // watch(botUuid, async (value) => {
    //   if (value) {
    //     isLoading.value = true;
    //     try {
    //       await rightsStore.fetchRights(false);
    //     } finally {
    //       isLoading.value = false;
    //     }
    //   }
    // });

    return {
      locale,
      userStore,
      rightsStore,
      settingsStore,
      licenseStore,
      // useAuthStore
      profileUuid,
      botUuid,

      isLoading,
    };
  },

  computed: {
    isDebug() {
      if (this.$route.query.debug === 'true') {
        localStorage.setItem('debug', 'true');
        return true;
      }

      if (this.$route.query.debug === 'false') {
        localStorage.removeItem('debug');
        return false;
      }

      return localStorage.getItem('debug') === 'true';
    },

    allowExpired() {
      return this.$route.meta && !this.$route.meta.allowExpired;
    },
  },

  async created() {
    if (!(window as any).Chargebee) {
      const chargebeeScript = document.createElement('script');
      chargebeeScript.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
      document.head.appendChild(chargebeeScript);
    }

    if (process.env.VUE_APP_ENVIRONMENT === 'prod') {
      const posthogScript = document.createElement('script');
      // eslint-disable-next-line quotes, @typescript-eslint/quotes
      posthogScript.innerHTML = `!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);posthog.init('phc_F26YiJAOGly8sMD5Sz4iQDDZd8ILrnwQnWhNrGRat4',{api_host:'https://ph.cvy.io'})`;
      document.head.appendChild(posthogScript);
    }

    if ((window as any).Appcues) {
      (window as any).Appcues.on('all', (name: any, EventObject: any) => {
        if (!(window as any).posthog) (window as any).posthog.capture(name, EventObject);
      });
    }

    // Fetch settings
    this.isLoading = true;
    try {
      await this.settingsStore.getSettings();
    } finally {
      this.isLoading = false;
    }

    // Fetch user if a profileUuid is available
    if (this.profileUuid) {
      this.isLoading = true;
      try {
        await this.userStore.getProfile();
        await this.licenseStore.getLicense({
          organizationUuid: this.userStore.organizationUuid,
        });

        // Fetch rights
        if (this.botUuid) await this.rightsStore.fetchRights();
      } finally {
        this.isLoading = false;
      }
    }
  },
});
