
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    role: {
      type: String,
      required: true,
    },
  },

  computed: {
    variant(): string {
      switch (this.role) {
        case 'agent':
        case 'enduser':
          return 'info';
        default: return '';
      }
    },

    light(): boolean {
      return this.role === 'enduser';
    },
  },
});
