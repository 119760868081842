
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  props: {
    selected: {
      type: String,
      default: 'en-US',
    },
  },
  data() {
    return {
      selectedBox: this.selected,
    };
  },
  methods: {
    selectLanguage(str: string) {
      let language = str;
      if (str === 'other') language = 'en-GB';
      this.selectedBox = str;
      this.$emit('select', language);
    },
  },
});
