
import { defineComponent } from 'vue';

interface BaseSelectOptions {
  text: string,
  value: string | number
}

export default defineComponent({
  props: {
    options: {
      type: Array,
      required: false,
      default: () => [] as BaseSelectOptions[],
    },
  },
});
