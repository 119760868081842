/* eslint-disable import/extensions */
import Vue from 'vue';

import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';
import router from '@/router';
import { SecurityService } from '@/common/api';
import driverAuthBearer from './drivers/auth/bearer';

Vue.use(auth, {
  plugins: {
    http: SecurityService.instance,
    router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    authRedirect: { path: '/auth/login' },

    loginData: {
      url: '/processes/account/login',
      method: 'POST',
      redirect: false,
      fetchUser: false,
      staySignedIn: true,
    },
    logoutData: {
      redirect: '/auth/login',
      makeRequest: false,
    },
    fetchData: {
      enabled: false,
    },
    refreshData: {
      enabled: false,
    },
  },
});

export default auth;
