/* eslint-disable no-param-reassign */
import { useRightsStore } from '@/stores/rights.store';
import Vue from 'vue';
import { ModalProgrammatic } from '@oruga-ui/oruga';
import DenyAccessModal from '@/components/common/modals/DenyAccessModal.vue';

Vue.directive('right', {
  bind(el, binding, vnode) {
    const rightsStore = useRightsStore();
    if (binding.value) {
      if (!rightsStore.hasRoleRight(binding.value)) {
        vnode.context?.$nextTick(() => {
          vnode.elm?.parentElement?.removeChild(vnode.elm);
        });
      } else if (!rightsStore.hasLicenseRight(binding.value)) {
        el.setAttribute('disabled', '');

        const overlay = document.createElement('div');
        overlay.setAttribute('id', binding.value);
        overlay.style.position = 'absolute';
        overlay.style.top = '0';
        overlay.style.right = '0';
        overlay.style.bottom = '0';
        overlay.style.left = '0';
        overlay.style.backgroundColor = 'transparent';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (overlay.style as any).backdropFilter = 'blur(1px)';
        overlay.style.cursor = 'pointer';

        overlay.addEventListener('click', () => {
          ModalProgrammatic.open({
            parent: vnode.componentInstance,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            component: DenyAccessModal,
          });
        });

        el.style.position = 'relative';
        vnode.elm?.appendChild(overlay);
      }
    }
  },

  unbind(el, binding, vnode) {
    el.removeAttribute('disabled');
    const overlay = document.getElementById(binding.value);
    if (overlay) vnode.elm?.removeChild(overlay);
  },
});
