
import { defineComponent } from 'vue';
import { debounce } from 'lodash';
import { KnowledgeService } from '@/common/api';
import { BaseEntry } from '@/models/entry.schema';
import buildQueryParams from '@/utils/CbmQueryBuilder';

export default defineComponent({
  props: {
    botUuid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,

      formData: {
        selected: null as BaseEntry | null,
      },

      data: [],
      isFetching: false,

      page: 1,
      perPage: 10,
    };
  },

  mounted() {
    this.getAsyncData();
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, func-names
    getAsyncData: debounce(async function (this: any, name = '') {
      this.isFetching = true;

      try {
        // Fetch entries
        if (!name.length) {
          const paramsEntries = buildQueryParams({
            filter: {
              where: {
                botUuid: this.botUuid,
              },
              limit: 5,
              skip: (this.page - 1) * 5,
            },
          });

          const { data: entries } = await KnowledgeService.get('entries', `?${paramsEntries}`);
          const { data: forms } = await KnowledgeService.get('forms', `?${paramsEntries}`);

          this.data = [];
          entries.forEach((entry: BaseEntry) => this.data.push(entry));
          forms.forEach((entry: BaseEntry) => this.data.push(entry));
        } else {
          const paramsEntries = buildQueryParams({
            search: name,
            documents: ['entryIso'],
            documentsFilters: {
              entryIso: {
                filter: {
                  where: {
                    botUuid: this.botUuid,
                  },
                  limit: this.perPage,
                  skip: (this.page - 1) * this.perPage,
                },
              },
            },
          });

          const { data: entries } = await KnowledgeService.get(`processes/bots/${this.botUuid}/search`, `?${paramsEntries}`);

          this.data = [];
          entries.forEach((entry: BaseEntry) => this.data.push(entry));
        }
      } catch (error) {
        this.data = [];
        throw error;
      } finally {
        this.isFetching = false;
      }
    }, 500),

    async onSelect(option: BaseEntry) {
      this.formData.selected = option;
      this.$emit('select', this.formData.selected);
    },
  },
});
