
import { isString } from 'lodash';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseDatetimepicker',

  props: {
    value: {
      type: [Date, String],
      default: () => new Date(),
    },

    icon: {
      type: String,
      default: 'calendar',
    },

    placeholder: {
      type: String,
      default: '',
    },

    placeholderI18nKey: {
      type: String,
      default: 'placeholder',
    },

    rounded: {
      type: Boolean,
      default: true,
    },

    position: {
      type: String,
      default: 'bottom-left',
    },
  },

  setup(props, { emit }) {
    const formData = computed({
      get() { return isString(props.value) ? new Date(props.value) : props.value; },
      set(value) { emit('input', value); },
    });

    return {
      formData,
    };
  },

  computed: {
    computedPlaceholder(): string {
      return !this.placeholder ? this.$t(this.placeholderI18nKey) as string : this.placeholder;
    },
  },

  methods: {
    clearDateTime() {
      this.$emit('input', null);
    },
  },
});
