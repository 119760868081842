
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },

    message: {
      type: String,
      default: '',
    },

    confirmText: {
      type: String,
      default: '',
    },

    variant: {
      type: String,
      default: 'primary',
    },

    hasIcon: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    icon() {
      switch (this.variant) {
        case 'warning':
        case 'danger':
          return 'exclamation-triangle';
        default: return 'exclamation-circle';
      }
    },
  },

  methods: {
    submit() {
      this.$emit('submit');
      this.$emit('close');
    },
  },
});
