import Vue from 'vue';
import {
  Autocomplete,
  ConfigProgrammatic,
  Button,
  Carousel,
  Checkbox,
  Collapse,
  Datepicker,
  Datetimepicker,
  Dropdown,
  Field,
  Icon,
  Input,
  Inputitems,
  Loading,
  Modal,
  Notification,
  Pagination,
  Radio,
  Select,
  Sidebar,
  Skeleton,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Upload,
} from '@oruga-ui/oruga';
// import '@oruga-ui/oruga/dist/oruga.css';

import '@/assets/css/oruga/_animations.css';
import '@/assets/css/oruga/_variables.css';
import '@/assets/css/oruga/autocomplete.css';
import '@/assets/css/oruga/button.css';
import '@/assets/css/oruga/carousel.css';
import '@/assets/css/oruga/checkbox.css';
import '@/assets/css/oruga/collapse.css';
import '@/assets/css/oruga/datepicker.css';
import '@/assets/css/oruga/dropdown.css';
import '@/assets/css/oruga/field.css';
import '@/assets/css/oruga/icon.css';
import '@/assets/css/oruga/input.css';
import '@/assets/css/oruga/inputitems.css';
import '@/assets/css/oruga/loading.css';
import '@/assets/css/oruga/modal.css';
import '@/assets/css/oruga/notification.css';
import '@/assets/css/oruga/pagination.css';
import '@/assets/css/oruga/radio.css';
import '@/assets/css/oruga/select.css';
import '@/assets/css/oruga/sidebar.css';
import '@/assets/css/oruga/skeleton.css';
import '@/assets/css/oruga/switch.css';
import '@/assets/css/oruga/table.css';
import '@/assets/css/oruga/tabs.css';
import '@/assets/css/oruga/timepicker.css';
import '@/assets/css/oruga/tooltip.css';
import '@/assets/css/oruga/upload.css';

Vue.use(Autocomplete);
Vue.use(Button);
Vue.use(Carousel);
Vue.use(Checkbox);
Vue.use(Collapse);
Vue.use(Datepicker);
Vue.use(Datetimepicker);
Vue.use(Dropdown);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Inputitems);
Vue.use(Loading);
Vue.use(Modal);
Vue.use(Notification);
Vue.use(Pagination);
Vue.use(Radio);
Vue.use(Select);
Vue.use(Sidebar);
Vue.use(Skeleton);
Vue.use(Switch);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Tooltip);
Vue.use(Upload);

ConfigProgrammatic.setOptions({
  iconComponent: 'fa-icon',
  iconPack: 'fas',

  modal: {
    canCancel: ['escape', 'outside'],
    trapFocus: true,
    width: '100%',
  },

  pagination: {
    order: 'centered',
  },

  table: {
    mobileCards: false,
    paginationWrapperClass: 'o-table__pagination--centered',
  },
});
