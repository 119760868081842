
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',

  // @ts-ignore
  beforeRouteEnter(to, from, next) {
    const redirectAfterLogin = localStorage.getItem('redirect_after_login');
    if (redirectAfterLogin) {
      next({ name: redirectAfterLogin });
      localStorage.removeItem('redirect_after_login');
    } else {
      next();
    }
  },
});
