
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    customClass: {
      type: String,
      default: 'mb-4',
    },

    color: {
      type: String,
      default: 'bg-white',
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
