import * as validators from '@vuelidate/validators';
import {
  createI18nMessage,
  helpers,
  messageParamsFactory,
  MessageProps,
} from '@vuelidate/validators';
import i18n from '@/plugins/i18n';
import { snakeCase } from 'lodash';
import zxcvbn from 'zxcvbn';

const messagePath = ({ $validator, $params }: MessageProps) => (
  $params.i18nMessagePath ? $params.i18nMessagePath : `validations.messages.${snakeCase($validator)}`
);
const messageParams = (params: Parameters<typeof messageParamsFactory>[0]) => ({
  ...params,
  // eslint-disable-next-line no-underscore-dangle
  _field_: params._field_ ? i18n.t(`fields.${params._field_}`) : i18n.t(`fields.${snakeCase(params.property)}`),
});

// @ts-ignore
const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n), messagePath, messageParams });

export const required = withI18nMessage(validators.required);
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true });
export const email = withI18nMessage(validators.email);
export const minLength = withI18nMessage(validators.minLength, { withArguments: true });
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true });
export const minValue = withI18nMessage(validators.minValue, { withArguments: true });
export const maxValue = withI18nMessage(validators.maxValue, { withArguments: true });
export const url = withI18nMessage(validators.url);

// Password validators
export const passwordStrength = withI18nMessage(
  (min: number) => helpers.withParams(
    { min },
    (value: string) => !helpers.req(value) || zxcvbn(value).score >= min,
  ),
  { withArguments: true },
);

export const oneUpperCase = withI18nMessage(
  (value: string) => !helpers.req(value) || !!value.match(/(?=.*[A-Z])/),
);

export const oneLowerCase = withI18nMessage(
  (value: string) => !helpers.req(value) || !!value.match(/(?=.*[a-z])/),
);

export const oneSpecial = withI18nMessage(
  (value: string) => !helpers.req(value) || !!value.match(/(?=.*\W)/),
);

export const oneDigit = withI18nMessage(
  (value: string) => !helpers.req(value) || !!value.match(/(?=.*\d)/),
);
