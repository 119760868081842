
import { defineComponent, PropType, ref } from 'vue';
import { BusinessProfile } from '@/models/profile.schema';

export default defineComponent({
  props: {
    users: {
      type: Array as PropType<BusinessProfile[]>,
      required: false,
      default: () => [] as BusinessProfile[],
    },
  },

  setup() {
    const hoveredUsername = ref('');

    return {
      hoveredUsername,
    };
  },
});
