
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClass(): unknown[] {
      return [{
        'o-inputit--loading': this.loading,
      }];
    },
  },
});
