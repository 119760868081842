
import { defineComponent } from 'vue';
import SidebarButton from '../SidebarButton.vue';

export default defineComponent({
  components: {
    SidebarButton,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    parent: {
      type: Object,
      required: false,
      default: () => null,
    },

    activeItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      isActive: false,
    };
  },

  watch: {
    activeItems: {
      handler(value: []) {
        // @ts-ignore
        if (value.length && this.matchActiveRoute(this.item)) {
          // @ts-ignore
          this.navigateForward();
        }
      },
      immediate: true,
    },
  },

  methods: {
    navigateForward() {
      this.isActive = true;
      this.$emit('navigate:forward');
    },

    navigateBackward() {
      this.isActive = false;
      this.$emit('navigate:backward');
    },

    matchActiveRoute(item: Record<string, unknown>) {
      return this.activeItems.includes(item.label);
    },
  },
});
