var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"flex items-center mx-2 my-1 py-2 overflow-hidden h-12 rounded-lg outline-none",class:[
    _vm.active ? 'bg-gray-100' : 'bg-gray-50',
    _vm.disabled
      ? 'text-gray-400 cursor-not-allowed'
      : 'hover:bg-gray-100 focus:bg-gray-100',
  ],attrs:{"title":_vm.label,"disabled":_vm.disabled,"type":"button"}},[(_vm.icon)?_c('div',{staticClass:"w-16 shrink-0",class:[
      _vm.disabled ? 'text-gray-400' : 'text-gray-500',
    ]},[_c('base-icon',{attrs:{"icon":_vm.icon}})],1):_vm._t("right",function(){return [_c('div',{staticClass:"w-5 shrink-0"})]}),_c('span',{staticClass:"pr-2 text-left font-bold",attrs:{"title":_vm.label}},[_vm._v(_vm._s(_vm.label))]),(_vm.hasError)?_c('span',{staticClass:"flex items-center justify-center mt-0.5 text-sm font-normal"},[_c('span',{staticClass:"rounded-full w-2 h-2 bg-red-400"})]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }