/* eslint-disable import/no-cycle */
import SecurityService from './services/SecurityService';
import StructureService from './services/StructureService';
import KnowledgeService from './services/KnowledgeService';
import InboxService from './services/InboxService';
import ChannelService from './services/ChannelService';
import MediaService from './services/MediaService';
import StoreService from './services/StoreService';
import StatisticsService from './services/StatisticsService';
import SocialService from './services/SocialService';

const SecurityServiceInstance = SecurityService.getInstance();
const StructureServiceInstance = StructureService.getInstance();
const KnowledgeServiceInstance = KnowledgeService.getInstance();
const InboxServiceInstance = InboxService.getInstance();
const ChannelServiceInstance = ChannelService.getInstance();
const MediaServiceInstance = MediaService.getInstance();
const StoreServiceInstance = StoreService.getInstance();
const StatisticsServiceInstance = StatisticsService.getInstance();
const SocialServiceInstance = SocialService.getInstance();

export {
  SecurityServiceInstance as SecurityService,
  StructureServiceInstance as StructureService,
  KnowledgeServiceInstance as KnowledgeService,
  InboxServiceInstance as InboxService,
  ChannelServiceInstance as ChannelService,
  MediaServiceInstance as MediaService,
  StoreServiceInstance as StoreService,
  StatisticsServiceInstance as StatisticsService,
  SocialServiceInstance as SocialService,
};
