
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    botUuid: {
      type: String,
      required: true,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    files: {
      type: Array as PropType<Record<string, unknown>[]>,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const localFiles = computed({
      get() { return props.files; },
      set(value) { emit('update:files', value); },
    });

    return {
      localFiles,
    };
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async submit() {
      this.$emit('submit', this.localFiles);
      this.$emit('close');
    },

    fileUploaded(file: Record<string, unknown>) {
      this.localFiles.push(file);
    },

    deleteFile(index: number) {
      this.localFiles.splice(index, 1);
    },
  },
});
