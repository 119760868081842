import { RouteConfig } from 'vue-router';

export default [
  {
    path: '/groups',
    component: () => import('@/views/groups/index.vue'),
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'groups_list',
        component: () => import('@/views/groups/GroupsList.vue'),
      },
      {
        path: ':groupId',
        component: () => import('@/views/groups/_groupId.vue'),
        props: true,
      },
    ],
  },
] as Array<RouteConfig>;
