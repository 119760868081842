/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import Vue from 'vue';
import { AxiosResponse } from 'axios';
// eslint-disable-next-line camelcase
import jwt_decode, { JwtPayload } from 'jwt-decode';
import router from '@/router';
import { useUserStore } from '@/stores/user.store';
import { useErrorsStore } from '@/stores/errors.store';
import { useRightsStore } from './rights.store';
import { useBotsStore } from './bots.store';
import { useLicenseStore } from './license.store';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: sessionStorage.getItem('auth_token_default') || localStorage.getItem('auth_token_default') || '',
  }),

  getters: {
    user: () => Vue.auth.user(),
    jwt: (state) => state.token,

    decodedJwt(): JwtPayload & { user?: { profile: string } } | null {
      return this.jwt ? jwt_decode(this.jwt) : null;
    },

    profileUuid(): string | undefined {
      return this.decodedJwt?.user?.profile;
    },
  },

  actions: {
    fetch(payload: unknown) {
      return Vue.auth.fetch(payload);
    },

    refresh(payload: unknown) {
      return Vue.auth.refresh(payload);
    },

    login(payload: Record<string, unknown>): Promise<AxiosResponse> {
      return new Promise((resolve, reject) => {
        Vue.auth.login({
          ...payload,
        })
          .then(async (response: AxiosResponse) => {
            this.token = Vue.auth.token();

            if (response.data.connected) {
              // Set user
              try {
                const userStore = useUserStore();
                await userStore.getProfile();
                const licenseStore = useLicenseStore();
                await licenseStore.getLicense({
                  organizationUuid: userStore.organizationUuid,
                });
                const rightsStore = useRightsStore();
                await rightsStore.fetchRights(false);
              } catch (error) {
                reject(error);
              }

              router.push('/');
            }

            resolve(response);
          }, reject);
      });
    },

    register(payload: Record<string, unknown>) {
      return new Promise((resolve, reject) => {
        Vue.auth.register({
          ...payload,
        })
          .then(() => {
            if (payload.autoLogin) {
              this.login(payload).then(resolve, reject);
            }
          }, reject);
      });
    },

    logout(redirect: string | boolean = '/auth/login', makeRequest = false) {
      this.token = '';

      const userStore = useUserStore();
      userStore.reset();

      const botStore = useBotsStore();
      botStore.reset();

      const licenseStore = useLicenseStore();
      licenseStore.reset();

      const rightsStore = useRightsStore();
      rightsStore.reset();

      const errorsStore = useErrorsStore();
      errorsStore.$reset();

      return Vue.auth.logout({
        redirect,
        makeRequest,
      }).then(() => {
        if (redirect && typeof redirect === 'string') {
          window.location.href = redirect;
        }
      });
    },
  },
});
