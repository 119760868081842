import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
// Sort alphabetically
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faArrowRotateRight,
  faArrowUp,

  faBars,
  faBook,
  faBuilding,

  faCalendar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faCircleMinus,
  faCircleNotch,
  faClone,
  faCode,
  faCodeBranch,
  faCog,
  faCommentAlt,
  faCommentDots,
  faComments,
  faCommentSlash,
  faCreditCard,
  faCubes,

  faDisplay,
  faDollarSign,
  faDownload,

  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,

  faFile,
  faFileArrowUp,
  faFileImport,
  faFilter,
  faFolder,
  faFolderOpen,
  faFont,

  faGripLines,

  faHistory,

  faInbox,
  faInfoCircle,

  faKey,

  faLanguage,
  faLayerGroup,
  faLink,
  faList,

  faMinus,

  faNoteSticky,

  faPen,
  faPenToSquare,
  faPlus,

  faRectangleList,
  faReply,

  faQuestionCircle,

  faSearch,
  faShapes,
  faSignOutAlt,
  faSitemap,
  faSort,
  faStar,

  faTag,
  faTh,
  faThList,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashCan,
  faTriangleExclamation,

  faUpload,
  faUser,
  faUserCircle,
  faUserPlus,
  faUsers,
  faCopy,
  faLightbulb,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFile as farFile,
  faFileAudio as farFileAudio,
  faFileCode as farFileCode,
  faFileVideo as farFileVideo,

  faImage as farImage,

  faMessage as farMessage,

  faStar as farStar,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Sort alphabetically
library.add(faAngleDoubleLeft);
library.add(faAngleDoubleRight);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faArrowRotateRight);
library.add(faArrowUp);

library.add(faBars);
library.add(faBook);
library.add(faBuilding);

library.add(faCalendar);
library.add(faCaretDown);
library.add(faCaretLeft);
library.add(faCaretRight);
library.add(faCaretUp);
library.add(faCheck);
library.add(faCheckCircle);
library.add(faChevronDown);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faChevronUp);
library.add(faCircle);
library.add(faCircleInfo);
library.add(faCircleMinus);
library.add(faCircleNotch);
library.add(faClone);
library.add(faCode);
library.add(faCodeBranch);
library.add(faCog);
library.add(faCommentAlt);
library.add(faCommentDots);
library.add(faComments);
library.add(faCommentSlash);
library.add(faCopy);
library.add(faCreditCard);
library.add(faCubes);

library.add(faDisplay);
library.add(faDollarSign);
library.add(faDownload);

library.add(faEllipsisV);
library.add(faEnvelope);
library.add(faExclamationCircle);
library.add(faExclamationTriangle);
library.add(faExternalLinkAlt);
library.add(faEye);
library.add(faEyeSlash);

library.add(faFile);
library.add(faFileArrowUp);
library.add(faFileImport);
library.add(faFilter);
library.add(faFolder);
library.add(faFolderOpen);
library.add(faFont);

library.add(faGripLines);

library.add(faHistory);

library.add(faInbox);
library.add(faInfoCircle);

library.add(faKey);

library.add(faLanguage);
library.add(faLayerGroup);
library.add(faLink);
library.add(faList);
library.add(faLightbulb);

library.add(faMinus);

library.add(faNoteSticky);

library.add(faPen);
library.add(faPenToSquare);
library.add(faPlus);

library.add(faRectangleList);
library.add(faReply);

library.add(faQuestionCircle);

library.add(faSearch);
library.add(faShapes);
library.add(faSignOutAlt);
library.add(faSitemap);
library.add(faSort);
library.add(faStar);

library.add(faTag);
library.add(faTh);
library.add(faThList);
library.add(faTimes);
library.add(faTimesCircle);
library.add(faTrash);
library.add(faTrashCan);
library.add(faTriangleExclamation);

library.add(faUpload);
library.add(faUser);
library.add(faUserPlus);
library.add(faUserCircle);
library.add(faUsers);

library.add(farFile);
library.add(farFileAudio);
library.add(farFileCode);
library.add(farFileVideo);

library.add(farImage);

library.add(farMessage);

library.add(farStar);

Vue.component('FaIcon', FontAwesomeIcon);
