import ApiProtected from './ApiProtected';

class StructureService extends ApiProtected {
  private static classInstance?: StructureService;

  private constructor() {
    super(new URL(
      process.env.VUE_APP_CBM_STRUCTURE_API_PREFIX,
      process.env.VUE_APP_CBM_STRUCTURE_API_URL,
    ).href);
  }

  public static getInstance(): StructureService {
    if (!this.classInstance) {
      this.classInstance = new StructureService();
    }

    return this.classInstance;
  }
}

export default StructureService;
