import ApiProtected from './ApiProtected';

class StatisticsService extends ApiProtected {
  private static classInstance?: StatisticsService;

  private constructor() {
    super(new URL(
      process.env.VUE_APP_CBM_STATISTICS_API_PREFIX,
      process.env.VUE_APP_CBM_STATISTICS_API_URL,
    ).href);
  }

  public static getInstance(): StatisticsService {
    if (!this.classInstance) {
      this.classInstance = new StatisticsService();
    }

    return this.classInstance;
  }
}

export default StatisticsService;
