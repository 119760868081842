
import { MediaService } from '@/common/api';
import i18n from '@/plugins/i18n';
import { computed, defineComponent, PropType } from 'vue';
import axios from 'axios';
import { combineURLs } from '@/utils/Helpers';

export default defineComponent({
  props: {
    botUuid: {
      type: String,
      required: true,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: i18n.t('actions.upload'),
    },

    size: {
      type: String as PropType<'input' | ''>,
      default: () => '',
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const isLoading = computed({
      get() { return props.loading; },
      set(value) { emit('update:loading', value); },
    });

    return {
      isLoading,
    };
  },

  watch: {
    files(value) {
      this.$emit('submit', value);
    },
  },

  methods: {
    async handleInput(input: File | File[]) {
      this.isLoading = true;
      try {
        this.$emit('uploading', true);
        // if multiple files are uploaded, this will be an array, otherwise a single file object
        const isMultiple = Array.isArray(input as File[]);
        if (!isMultiple) await this.createFile(input as File);
        else await Promise.all((input as File[]).map((f) => this.createFile(f)));
      } finally {
        this.isLoading = false;
        this.$emit('uploading', false);
      }
    },

    async createFile(file: File | null) {
      if (!file) return;

      const { data } = await MediaService.post('files/attachment', {
        botUuid: this.botUuid,
        name: file.name,
        metadata: {
          size: file.size,
          mimeType: file.type,
          type: file.type.split('/')[0],
        },
      });

      if (data.presignData) {
        const url = combineURLs(data.presignData.url, data.presignData.fields.key);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { bucket, key, ...params } = data.presignData.fields;

        const config = {
          params,
          headers: {
            'Content-Type': file.type,
          },
        };

        await axios.put(url.toString(), file, config);

        const downloadUrl = MediaService.getDownloadUrl(this.botUuid, data.file.uuid);

        this.$emit('uploaded', {
          url: downloadUrl,
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: Date.now(),
        });
      }
    },
  },
});
