import { NotificationProgrammatic as Notification } from '@oruga-ui/oruga';
import { useErrorsStore } from '@/stores/errors.store';
import UiSnackbar from '@/components/ui/UiSnackbar.vue';

const openSnackbar = (message: string, params?: Record<string, unknown>) => {
  const errorsStore = useErrorsStore();
  if (errorsStore.isEnabled.notifications) {
    const snackbar = Notification.open({
      parent: this,
      component: UiSnackbar,
      props: {
        message,
      },
      duration: 2000,
      position: 'top-right',
      queue: true,
      ...params,
    });

    return snackbar;
  }

  return null;
};

export const notifyError = (message: string, params?: Record<string, unknown>): void => {
  openSnackbar(message, {
    variant: 'danger',
    ...params,
  });
};

export const notifySuccess = (message: string, params?: Record<string, unknown>): void => {
  openSnackbar(message, {
    variant: 'success',
    ...params,
  });
};

export const notifyWarning = (message: string, params?: Record<string, unknown>): void => {
  openSnackbar(message, {
    variant: 'warning',
    ...params,
  });
};
