
import { defineComponent } from 'vue';
import { isString } from 'lodash';

/**
 * Default button component.
 */
export default defineComponent({
  props: {
    rootClass: {
      type: [String, Array],
      default: () => [] as unknown[],
    },

    light: {
      type: Boolean,
      default: false,
    },

    backgroundless: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedRootClass(): unknown[] {
      let { rootClass } = this;

      if (isString(this.rootClass)) {
        rootClass = [this.rootClass];
      }

      return [...rootClass,
        this.light ? 'o-btn--light' : '',
        this.loading ? 'o-btn--loading' : '',
        this.backgroundless ? 'o-btn--backgroundless' : '',
      ];
    },
  },
});
