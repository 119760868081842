/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { SecurityService } from '@/common/api';
import { useBotsStore } from './bots.store';
import { useUserStore } from './user.store';

/**
 * Rights store.
 */
export const useRightsStore = defineStore('rights', {
  state: () => ({
    rights: {} as Record<string, unknown>,
  }),

  getters: {
    getAllowedRights: (state) => {
      const allowedRights: string[] = [];
      Object.entries(state.rights).forEach(([key, value]) => {
        if ((value as unknown as Record<string, unknown>).allow) {
          allowedRights.push(key);
        }
      });
      return allowedRights;
    },

    getAllowedLicenseRights: (state) => {
      const allowedLicenseRights: string[] = [];
      Object.entries(state.rights).forEach(([key, value]) => {
        if ((value as unknown as Record<string, unknown>).allowLicense) {
          allowedLicenseRights.push(key);
        }
      });
      return allowedLicenseRights;
    },

    getAllowedRoleRights: (state) => {
      const allowedRoleRights: string[] = [];
      Object.entries(state.rights).forEach(([key, value]) => {
        if ((value as unknown as Record<string, unknown>).allowRole) {
          allowedRoleRights.push(key);
        }
      });
      return allowedRoleRights;
    },

    hasRight() {
      return (right: string) => this.getAllowedRights.includes(right);
    },

    hasLicenseRight() {
      return (right: string) => this.getAllowedLicenseRights.includes(right);
    },

    hasRoleRight() {
      return (right: string) => this.getAllowedRoleRights.includes(right);
    },
  },

  actions: {
    async fetchRights(cache = true) {
      if (!cache || Object.keys(this.rights).length === 0) {
        const botsStore = useBotsStore();
        const currentBot = computed(() => botsStore.bot);

        if (!currentBot.value) {
          const userStore = useUserStore();
          const organizationUuid = computed(() => userStore.organizationUuid);
          await botsStore.getBots({
            organizationUuid: organizationUuid.value,
            cache: true,
          });
        }

        if (currentBot.value) {
          const { data: rights } = await SecurityService.get(`processes/acl/bots/${currentBot.value.uuid}/contextualized/list`);

          // Assign data
          this.rights = rights;
        }
      }
    },

    reset() {
      this.rights = {};
    },
  },
});
