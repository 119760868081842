// eslint-disable-next-line import/no-extraneous-dependencies
import { Event } from '@bugsnag/core';
import { omit } from 'lodash';
import { useUserStore } from '@/stores/user.store';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth.store';

export default function onError(event: Event) {
  console.log('bugsnag:onError', event);

  if (event.originalError.response) {
    const authStore = useAuthStore();
    const { decodedJwt } = storeToRefs(authStore);
    event.addMetadata('response', event.originalError.response);
    event.addMetadata('cbm-express', {
      jti: decodedJwt.value?.jti,
      rid: event.originalError.response.data?.errors?.[0]?.rid,
    });
  }

  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);
  if (user.value) {
    event.setUser(
      user.value.accountUuid,
      user.value.email,
      `${user.value.firstname} ${user.value.lastname}`,
    );

    event.addMetadata('cbm-user', omit(user.value, ['__v', '_id']));
  }
}
