/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import { JsonApiError } from '@/models/jsonapi.schema';

export const useErrorsStore = defineStore('errors', {
  state: () => ({
    errors: useLocalStorage('state.errors.errors', [] as (JsonApiError | Record<string, unknown>)[]),

    isEnabled: {
      notifications: true,
    },
  }),

  getters: {
    errorByUuid: (state) => (errorUuid: string) => state.errors.find(
      (error) => error.uuid === errorUuid,
    ) as JsonApiError | Record<string, unknown>,
  },

  actions: {
    logError(payload: JsonApiError | Record<string, unknown>) {
      this.errors.unshift(payload);
    },

    enableNotifications() {
      this.isEnabled.notifications = true;
    },

    disableNotifications() {
      this.isEnabled.notifications = false;
    },
  },
});
