/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { combineURLs } from '@/utils/Helpers';
import { MediaService } from '@/common/api';
import i18n from '@/plugins/i18n';

export default function CbmImageUploadAdapterPlugin(editor) {
  const options = editor.config.get('cbmImageUpload');

  if (!options) {
    return;
  }

  if (!options.botUuid) {
    console.warn('cbm-image-upload-adapter-missing-botuuid');
    return;
  }

  // eslint-disable-next-line no-param-reassign
  editor.plugins
    .get('FileRepository')
    .createUploadAdapter = (loader) => new CbmImageUploadAdapter(loader, options);
}

class CbmImageUploadAdapter {
  constructor(loader, options) {
    this.loader = loader;
    this.options = options;
  }

  async upload() {
    const { botUuid } = this.options;

    return this.loader.file
      .then((file) => new Promise((resolve, reject) => {
        this._sendRequest(resolve, reject, file, botUuid);
      }));
  }

  abort() {
    if (this.axiosController) {
      this.axiosController.abort();
    }
  }

  async _sendRequest(resolve, reject, file, botUuid) {
    this.axiosController = await MediaService.post('files/attachment', {
      botUuid,
      name: file.name,
      metadata: {
        size: file.size,
        mimeType: file.type,
        type: file.type.split('/')[0],
      },
    });

    const { data } = this.axiosController;

    if (data.presignData) {
      const url = combineURLs(data.presignData.url, data.presignData.fields.key);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { bucket, key, ...params } = data.presignData.fields;

      const config = {
        params,
        headers: {
          'Content-Type': file.type,
        },
      };

      await axios.put(url.toString(), file, config);

      const downloadUrl = MediaService.getDownloadUrl(botUuid, data.file.uuid);

      resolve({
        urls: {
          default: downloadUrl,
        },
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: Date.now(),
      });
    }

    reject({
      error: {
        message: i18n.t('errors.api.default'),
      },
    });
  }
}
