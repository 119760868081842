
import { computed, defineComponent, watch } from 'vue';

export default defineComponent({
  props: {
    current: {
      type: [Number, String],
      default: 1,
    },

    /**
     * Buttons order, optional
     * @values centered, right, left
     */
    order: {
      type: String,
      default: 'right',
    },
  },

  setup(props, { emit }) {
    const localCurrent = computed({
      get() { return props.current; },
      set(value) { emit('update:current', value); },
    });

    function updateQueryParams(num: number) {
      const url = new URL(window.location.href);
      url.searchParams.set('page', `${num}`);
      window.history.pushState(null, '', url.toString());
    }

    watch(() => props.current, (value) => updateQueryParams(+value));

    return {
      localCurrent,
    };
  },
});
