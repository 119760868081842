import ApiProtected from './ApiProtected';

class SocialService extends ApiProtected {
  private static classInstance?: SocialService;

  private constructor() {
    super(new URL(
      process.env.VUE_APP_CBM_SOCIAL_API_PREFIX,
      process.env.VUE_APP_CBM_SOCIAL_API_URL,
    ).href);
  }

  public static getInstance(): SocialService {
    if (!this.classInstance) {
      this.classInstance = new SocialService();
    }

    return this.classInstance;
  }
}

export default SocialService;
