// import router from '@/router';
import { useErrorsStore } from '@/stores/errors.store';
import { AxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ErrorHandler from './ErrorHandler';
import ErrorTypes from './models/ErrorTypes';

class ApiError extends ErrorHandler {
  protected error: AxiosError;

  protected uuid: string;

  public constructor(
    error: AxiosError,
    message?: string | null,
    type: ErrorTypes = ErrorTypes.DANGER,
  ) {
    super(error, message, type);
    this.error = error;
    this.uuid = uuidv4();

    this.handleError();
  }

  handleError(): void {
    this.logError();

    if (this.message) {
      // const { href } = router.resolve(`/errors/${this.uuid}`);
      const href = new URL(`/errors/${this.uuid}`, window.location.origin);
      let message = `${this.message}`;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((this.error.response?.data as any)?.errors?.[0]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message += ` <small class="font-medium">(${(this.error.response?.data as any).errors?.[0].code})</small>`;
      }
      if (this.error.response) {
        message += `<br><a href="${href}" target="_blank" rel="noopener noreferrer" class="text-white text-sm underline">More details</a>`;
      }
      this.notifyError(this.message, {
        props: {
          message,
        },
      });
    }

    throw this.error;
  }

  logError(): void {
    console.log(`[Clevy][${this.constructor.name}]`, this.error);

    // @ts-ignore
    this.error.uuid = this.uuid;

    if (this.error.response) {
      const error = {
        uuid: this.uuid,
        ...this.error.response,
      };

      const errorsStore = useErrorsStore();
      errorsStore.logError(error);
    }
  }
}

export default ApiError;
