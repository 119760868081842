import { computed } from 'vue';
import { useI18nStore } from '@/stores/i18n.store';
import i18n, { locales } from '@/plugins/i18n';
import { DateTimeFormatOptions } from 'vue-i18n';

export default function useI18nUtils() {
  const i18nStore = useI18nStore();

  const currentLocale = computed({
    get() {
      return i18nStore.locale;
    },
    set(newLocale: string) {
      i18nStore.updateLocale(newLocale);
    },
  });

  const relativeTimeFormat = new Intl.RelativeTimeFormat(currentLocale.value, {
    numeric: 'auto',
  });

  const units = [
    { amount: 60, name: 'seconds' },
    { amount: 60, name: 'minutes' },
    { amount: 24, name: 'hours' },
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' },
  ] as { amount: number, name: Intl.RelativeTimeFormatUnit }[];

  function formatTimeAgo(date?: Date) {
    if (date) {
      let duration = (+date - +new Date()) / 1000;

      for (let i = 0; i <= units.length; i += 1) {
        const unit = units[i];
        if (Math.abs(duration) < unit.amount) {
          return relativeTimeFormat.format(Math.round(duration), unit.name);
        }
        duration /= unit.amount;
      }
    }

    return i18n.t('labels.unknown_date');
  }

  function formatLocaleString(date: Date, options?: DateTimeFormatOptions) {
    const defaultOptions: DateTimeFormatOptions = {
      hour12: false,
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    return date.toLocaleString(currentLocale.value, {
      ...defaultOptions,
      ...options,
    });
  }

  return {
    currentLocale,
    locales,
    formatTimeAgo,
    formatLocaleString,
  };
}
