
import { defineComponent, ref } from 'vue';
import { isString } from 'lodash';

export default defineComponent({
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },

    menuClass: {
      type: [String, Array],
      default: () => [] as string[],
    },
  },

  setup() {
    const instance = ref(null);

    return {
      instance,
    };
  },

  computed: {
    computedMenuClass(): unknown[] {
      let { menuClass } = this;

      if (isString(this.menuClass)) {
        menuClass = [this.menuClass];
      }

      return [...menuClass, this.appendToBody ? 'o-drop__menu--body' : ''];
    },
  },
});
