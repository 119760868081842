/* eslint-disable no-underscore-dangle */
import iconKnowledge from '@/assets/icons/active/knowledge.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import i18n from '@/plugins/i18n';
import Vue from 'vue';
import { View, ContextualBalloon, clickOutsideHandler } from '@ckeditor/ckeditor5-ui';
import CbmAddEntryFormView from './CbmAddEntryFormView.vue';

export default class CbmAddEntryPlugin extends Plugin {
  init() {
    const { editor } = this;

    const options = editor.config.get('cbmAddEntry');

    if (!options) {
      return;
    }

    if (!options.botUuid) {
      console.warn('cbm-add-entry-missing-botuuid');
      return;
    }

    this._balloon = this.editor.plugins.get(ContextualBalloon);

    editor.ui.componentFactory.add('addEntry', (locale) => {
      const button = new ButtonView();

      button.label = i18n.t('labels.contents');
      button.icon = iconKnowledge;
      button.tooltip = true;
      button.withText = true;
      button.class = 'c-editor__button--custom';

      this.listenTo(button, 'execute', () => {
        this._showUI();
      });

      const CbmAddEntryFormViewClass = Vue.extend(CbmAddEntryFormView);
      const cbmAddEntryFormViewInstance = new CbmAddEntryFormViewClass({
        i18n,
        propsData: {
          botUuid: options.botUuid,
        },
      });
      cbmAddEntryFormViewInstance.$mount();
      cbmAddEntryFormViewInstance.$on('submit', (value) => {
        // Insert link.
        editor.model.change((writer) => {
          editor.model.insertContent(
            writer.createText(value.description, { linkHref: value.url }),
          );
        });

        this._hideUI();
      });

      const container = cbmAddEntryFormViewInstance.$el;

      this.addEntryFormView = new View(locale);
      this.addEntryFormView.setTemplate({ tag: 'div' });
      this.addEntryFormView.render();
      this.addEntryFormView.element.appendChild(container);

      clickOutsideHandler({
        emitter: this.addEntryFormView,
        activator: () => this._balloon.visibleView === this.addEntryFormView,
        contextElements: [this._balloon.view.element],
        callback: () => this._hideUI(),
      });

      return button;
    });
  }

  _getBalloonPositionData() {
    const { view } = this.editor.editing;
    const viewDocument = view.document;
    let target = null;

    // Set a target position by converting view selection range to DOM.
    target = () => view.domConverter.viewRangeToDom(
      viewDocument.selection.getFirstRange(),
    );

    return {
      target,
    };
  }

  _showUI() {
    this._balloon.add({
      view: this.addEntryFormView,
      position: this._getBalloonPositionData(),
    });
  }

  _hideUI() {
    this._balloon.remove(this.addEntryFormView);

    // Focus the editing view after closing the form view.
    this.editor.editing.view.focus();
  }
}
