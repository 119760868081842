
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    size: {
      type: String,
      default: null,
    },
  },

  computed: {
    computedClass(): unknown[] {
      return [{
        'ui-button-close--small': this.size === 'small',
      }];
    },
  },
});
