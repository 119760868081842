/* eslint-disable @typescript-eslint/no-throw-literal */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import i18n from '@/plugins/i18n';
import { useErrorsStore } from '@/stores/errors.store';
import { useAuthStore } from '@/stores/auth.store';
import ApiError from '../errors/ApiError';
import HttpStatusCodes from './models/HttpStatusCodes';

/**
 * Basic HTTP client.
 * @see https://levelup.gitconnected.com/enhance-your-http-request-with-axios-and-typescript-f52a6c6c2c8e
 * @see https://levelup.gitconnected.com/use-case-of-singleton-with-axios-and-typescript-da564e76296
 */
abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  protected readonly baseUrl: string;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });

    this.baseUrl = baseURL;

    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor() {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected handleResponse({ data }: AxiosResponse): any { return data; }

  protected handleError(error: AxiosError): Promise<never> {
    const authStore = useAuthStore();
    const errorsStore = useErrorsStore();
    const httpStatus = error.response?.status;

    switch (httpStatus) {
      case HttpStatusCodes.UNAUTHORIZED:
        authStore.logout();
        // throw new ApiError(error, i18n.t('errors.api.401') as string, ErrorTypes.WARNING);
        // notifyWarning(i18n.t('errors.api.401') as string);
        errorsStore.disableNotifications();
        setTimeout(() => {
          errorsStore.enableNotifications();
        }, 3500);
        break;
      case HttpStatusCodes.NOT_FOUND:
        throw new ApiError(error, i18n.t('errors.api.404') as string);
        break;
      case HttpStatusCodes.FORBIDDEN:
        throw new ApiError(error, i18n.t('errors.api.403') as string);
        break;
      case HttpStatusCodes.UNPROCESSABLE_ENTITY:
        // This case should be handled at the forms
        break;
      default:
        throw new ApiError(error, i18n.t('errors.api.default') as string);
        break;
    }

    return Promise.reject(error);
  }

  query(resource: string, config: AxiosRequestConfig): Promise<AxiosResponse> {
    return this.instance.get(resource, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(resource: string, slug = ''): Promise<any> {
    return this.instance.get(`${resource}/${slug}`);
  }

  post(resource: string, data: unknown): Promise<AxiosResponse> {
    return this.instance.post(`${resource}`, data);
  }

  // eslint-disable-next-line default-param-last
  update(resource: string, slug = '', data: unknown = undefined): Promise<AxiosResponse> {
    return this.instance.patch(`${resource}/${slug}`, data);
  }

  put(resource: string, data: unknown): Promise<AxiosResponse> {
    return this.instance.put(`${resource}`, data);
  }

  delete(resource: string): Promise<AxiosResponse> {
    return this.instance.delete(resource);
  }
}

export default HttpClient;
