
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },

    bodyClass: {
      type: String,
      default: '',
    },

    header: {
      type: Boolean,
      default: true,
    },

    tag: {
      type: String,
      default: 'div',
    },
  },
});
