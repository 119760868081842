
import { isString } from 'lodash';
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  props: {
    icon: {
      type: [String, Object] as PropType<string | { type: string, name: string, variant: string }>,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * The size of the button
     * @values xs, sm, base, lg, xl, 2xl
     */
    size: {
      type: String,
      default: 'base',
    },
  },

  setup() {
    const hover = ref(false);

    return {
      isString,

      hover,
    };
  },
});
