import ApiProtected from './ApiProtected';

class SecurityService extends ApiProtected {
  private static classInstance?: SecurityService;

  private constructor() {
    super(new URL(
      process.env.VUE_APP_CBM_SECURITY_API_PREFIX,
      process.env.VUE_APP_CBM_SECURITY_API_URL,
    ).href);
  }

  public static getInstance(): SecurityService {
    if (!this.classInstance) {
      this.classInstance = new SecurityService();
    }

    return this.classInstance;
  }
}

export default SecurityService;
