
import {
  defineComponent, reactive, ref,
} from 'vue';
import { renderGrid } from '@giphy/js-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { debounce, throttle } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { IGif } from '@giphy/js-types';
import i18n from '@/plugins/i18n';

export default defineComponent({
  setup(props, { emit }) {
    const formData = reactive({
      search: '',
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const grid = ref(null as null | any);

    const gf = new GiphyFetch(process.env.VUE_APP_GIPHY_API_KEY);

    function fetchGifs(offset: number) {
      if (formData.search) {
        return gf.search(formData.search, { offset, limit: 25 });
      }

      return gf.trending({ offset, limit: 25 });
    }

    // Creating a grid with window resizing and remove-ability
    function makeGrid(targetEl: HTMLElement) {
      const render = () => renderGrid(
        {
          width: 384 - 48,
          fetchGifs,
          columns: 2,
          gutter: 6,
          noResultsMessage: i18n.t('empty') as string,
          hideAttribution: true,
          onGifClick: (gif: IGif, e: Event) => {
            e.preventDefault();
            emit('select', gif);
          },
          key: formData.search,
        },
        targetEl,
      );
      const resizeRender = throttle(render, 500);
      window.addEventListener('resize', resizeRender, false);
      const remove = render();
      return {
        remove: () => {
          remove();
          window.removeEventListener('resize', resizeRender, false);
        },
      };
    }

    const debounceFormDataSearch = debounce((value: string) => {
      formData.search = value;
    }, 300);

    return {
      formData,
      grid,
      fetchGifs,
      makeGrid,
      debounceFormDataSearch,
    };
  },

  watch: {
    'formData.search': {
      handler() {
        // Update grid
        this.grid = this.makeGrid(this.$refs.giphyContainer as HTMLElement);
      },
    },
  },

  async mounted() {
    // Instantiate
    this.grid = this.makeGrid(this.$refs.giphyContainer as HTMLElement);

    this.$nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.input as any)?.$el.querySelectorAll('input')[0].focus();
    });
  },
});
