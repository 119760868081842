
import { computed, defineComponent } from 'vue';
import useI18nUtils from '@/composables/useI18nUtils';
import { isString } from 'lodash';

export default defineComponent({
  props: {
    date: {
      type: [String, Date],
      default: null,
    },

    textClass: {
      type: [String, Array],
      default: () => [] as unknown[],
    },
  },

  setup(props) {
    const { formatTimeAgo } = useI18nUtils();

    const computedTextClass = computed(() => {
      let { textClass } = props;
      if (isString(props.textClass)) {
        textClass = [props.textClass];
      }

      return [...textClass, 'ui-time-ago__text'];
    });

    return {
      formatTimeAgo,
      computedTextClass,
    };
  },
});
