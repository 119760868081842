import { RouteConfig } from 'vue-router';

export default [
  {
    path: '/training',
    redirect: '/training/unanswered',
  },
  {
    path: '/training/unanswered',
    components: {
      default: () => import('@/views/training/index.vue'),
    },
    beforeEnter: (to, from, next) => {
      // Clean filters and sort options
      if (!from.path.includes('training')) {
        sessionStorage.removeItem('persist.TrainingFilters.formData');
        sessionStorage.removeItem('persist.TrainingView.filter');
        sessionStorage.removeItem('persist.TrainingView.filterQuery');
        sessionStorage.removeItem('persist.TrainingView.sortOptions');
      }
      next();
    },
    meta: {
      auth: true,
    },
  },
  {
    path: '/training/reported',
    components: {
      default: () => import('@/views/training/reported.vue'),
    },
    beforeEnter: (to, from, next) => {
      // Clean filters and sort options
      if (!from.path.includes('training')) {
        sessionStorage.removeItem('persist.TrainingFilters.formData');
        sessionStorage.removeItem('persist.TrainingView.filter');
        sessionStorage.removeItem('persist.TrainingView.filterQuery');
        sessionStorage.removeItem('persist.TrainingView.sortOptions');
      }
      next();
    },
    meta: {
      auth: true,
    },
  },
  {
    path: '/training/archived',
    components: {
      default: () => import('@/views/training/archived.vue'),
    },
    beforeEnter: (to, from, next) => {
      // Clean filters and sort options
      if (!from.path.includes('training')) {
        sessionStorage.removeItem('persist.TrainingFilters.formData');
        sessionStorage.removeItem('persist.TrainingView.filter');
        sessionStorage.removeItem('persist.TrainingView.filterQuery');
        sessionStorage.removeItem('persist.TrainingView.sortOptions');
      }
      next();
    },
    meta: {
      auth: true,
    },
  },
] as Array<RouteConfig>;
