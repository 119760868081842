
import { defineComponent } from 'vue';

/**
 * Base input with form validation provided by Vuelidate.
 */
export default defineComponent({
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    v: {
      type: Object,
      required: true,
    },
  },
  computed: {
    innerValue: {
      get(): string | number {
        return this.value;
      },
      set(value: string) {
        this.$emit('input', value);
      },
    },
  },
});
