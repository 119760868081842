/* eslint-disable camelcase */
import actions from './common/actions.json';
import errors from './common/errors.json';
import fields from './common/fields.json';
import labels from './common/labels.json';
import notifications from './common/notifications.json';
import validations from './common/validations';
import sidebar_menu from './components/sidebar_menu.json';
import blocks from './components/blocks.json';

export default {
  message: 'hello i18n !!',

  // Validation messages
  validations: validations.en,

  // Common
  actions: actions.en,
  errors: errors.en,
  fields: fields.en,
  labels: labels.en,
  notifications: notifications.en,

  // Components
  sidebar_menu: sidebar_menu.en,
  blocks: blocks.en,
};
