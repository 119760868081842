
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    columns: {
      type: Array,
      required: true,
    },
    length: {
      type: Number,
      required: false,
      default: 3,
    },
    columnLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
