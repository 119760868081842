
import { defineComponent } from 'vue';
import MarkdownUtils from '@/utils/MarkdownUtils';

export default defineComponent({
  props: {
    markdown: {
      type: String,
      required: true,
    },
  },

  computed: {
    markdownAsHtml(): string {
      return MarkdownUtils.markdownToHtml(this.markdown);
    },
  },
});
