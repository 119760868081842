/* eslint-disable import/prefer-default-export, @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import { SecurityService } from '@/common/api';
import { BusinessProfile } from '@/models/profile.schema';
import { useAuthStore } from '@/stores/auth.store';
import { useI18nStore } from './i18n.store';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null as BusinessProfile | null,
  }),

  getters: {
    organizationUuid: (state) => state.user?.organizationUuid,

    friendlyIdentifier: (state) => (state.user?.firstname && state.user?.lastname
      ? `${state.user.firstname} ${state.user.lastname}`
      : state.user?.email || ''),
  },

  actions: {
    async getProfile() {
      const authStore = useAuthStore();
      const { data } = await SecurityService.get(`profiles/${authStore.profileUuid}`);
      this.user = data;

      const i18nStore = useI18nStore();

      if (this.user?.parameters.language) {
        i18nStore.updateLocale(`${this.user?.parameters.language}`);
      }

      if ((window as any).analytics) {
        (window as any).analytics.identify(data.accountUuid, {
          ...data,
          interfaceLanguage: i18nStore.locale,
        });
      }
      if ((window as any).posthog) (window as any).posthog.identify(data.email);
    },

    async updateProfile(payload: Partial<BusinessProfile>) {
      const authStore = useAuthStore();
      await SecurityService.update('profiles', authStore.profileUuid, payload);
      this.getProfile();
    },

    reset() {
      this.$reset();
    },
  },
});
