
import { defineComponent, PropType, reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@/plugins/vuelidate';

export default defineComponent({
  props: {
    payload: {
      type: Object as PropType<Record<string, unknown> & { title: string }>,
      required: true,
    },

    ignore: {
      type: Array as PropType<string[]>,
      default: () => [],
    },

    required: {
      type: Array as PropType<string[]>,
      default: () => ['title'],
    },

    type: {
      type: String,
      default: 'text',
    },
  },

  setup(props) {
    const formData = reactive(props.payload);

    const rules = {
      title: { required },
    };

    const v$ = useVuelidate(rules, formData, {
      $autoDirty: true,
    });

    return {
      formData,
      v$,
    };
  },
});
