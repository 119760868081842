
import { required } from '@/plugins/vuelidate';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },

    message: {
      type: String,
      default: '',
    },

    confirmText: {
      type: String,
      default: '',
    },

    variant: {
      type: String,
      default: 'primary',
    },

    hasIcon: {
      type: Boolean,
      default: false,
    },

    input: {
      type: String,
      default: '',
    },

    inputAttrs: {
      type: Object,
      default: () => ({}),
    },

    header: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const formData = reactive({
      input: props.input,
    });

    const rules = {
      input: { required: helpers.withParams({ _field_: 'value' }, required) },
    };

    const v$ = useVuelidate(rules, formData, {
      $autoDirty: true,
    });

    return {
      formData,
      v$,
    };
  },

  computed: {
    icon() {
      switch (this.variant) {
        case 'warning':
        case 'danger':
          return 'exclamation-triangle';
        default: return 'exclamation-circle';
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.input as any)?.$el.querySelectorAll('input')[0].focus();
    });
  },

  methods: {
    async submit() {
      const isFormValid = await this.v$.$validate();
      if (!isFormValid) return;

      this.$emit('submit', this.formData.input);
      this.$emit('close');
    },
  },
});
