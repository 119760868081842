import ApiProtected from './ApiProtected';

class StoreService extends ApiProtected {
  private static classInstance?: StoreService;

  private constructor() {
    super(new URL(
      process.env.VUE_APP_CBM_STORE_API_PREFIX,
      process.env.VUE_APP_CBM_STORE_API_URL,
    ).href);
  }

  public static getInstance(): StoreService {
    if (!this.classInstance) {
      this.classInstance = new StoreService();
    }

    return this.classInstance;
  }
}

export default StoreService;
