
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    subtitle: {
      type: String,
      required: false,
      default: '',
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    goBack() {
      if (this.$route.query.previous) {
        this.$router.push(this.$route.query.previous as string);
      } else if (this.$route.meta?.routes.previous.go) {
        this.$router.go(this.$route.meta?.routes.previous.go);
      } else if (this.$route.meta?.routes.previous) {
        this.$router.push(this.$route.meta?.routes.previous);
      } else {
        this.$router.go(-1);
      }
    },
  },
});
