/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import { BaseBot } from '@/models/bot.schema';
import { StructureService } from '@/common/api';
import router from '@/router';
import { RemovableRef, StorageSerializers, useLocalStorage } from '@vueuse/core';
import { useRightsStore } from './rights.store';

export const useBotsStore = defineStore('bots', {
  state: () => ({
    needUpdate: false,

    bots: [] as BaseBot[],
    bot: useLocalStorage('state.bots.bot', null as BaseBot | null, { serializer: StorageSerializers.object }) as RemovableRef<BaseBot | null>,
  }),

  actions: {
    async getBots({ organizationUuid, cache = true }: Record<string, unknown>) {
      if (!this.bots.length || cache !== true || this.needUpdate) {
        const { data } = await StructureService.get(`organizations/${organizationUuid}/bots`);
        this.bots = data;

        // Update the current bot
        if (this.bots.length) {
          // If no bot is currently selected or the selected bot doesn't exist
          if (!this.bot || !this.bots.find((bot) => bot.uuid === this.bot?.uuid)) {
            // Use the one from the current route or the first one
            this.bot = this.bots.find(
              (bot) => bot.uuid === router.currentRoute.params.botUuid,
            ) || this.bots[0];
          } else if (this.bots.find((bot) => bot.uuid === this.bot?.uuid)) {
            this.bot = this.bots[this.bots.findIndex((bot) => bot.uuid === this.bot?.uuid)];
          }
        } else {
          this.bot = null;
        }
      }
    },

    updateBot(bot: BaseBot) {
      const rightsStore = useRightsStore();
      rightsStore.reset();
      this.bot = bot;
      window.location.href = '/';
    },

    removeBot(botUuid: string) {
      const index = this.bots.findIndex((bot) => bot.uuid === botUuid);
      if (index > -1) {
        this.bots.splice(index, 1);
        this.needUpdate = true;
      }
    },

    reset() {
      this.needUpdate = false;

      this.bots = [];
      this.bot = null;
    },
  },
});
