/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia';
import { Settings } from '@/models/structure/settings.schema';
import { StructureService } from '@/common/api';

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    needUpdate: false,

    data: {
      maintenance: false,
      atlasPortal: process.env.VUE_APP_PORTAL_URL,
      atlasAssistant: process.env.VUE_APP_ASSISTANT_URL,
      atlasWidget: process.env.VUE_APP_WIDGET_URL,
    } as Settings,
  }),

  actions: {
    async getSettings() {
      const { data } = await StructureService.get('settings');
      this.data = {
        ...this.data,
        ...data,
      };
    },
  },
});
