/* eslint-disable no-underscore-dangle */
// import iconKnowledge from '@/assets/icons/active/knowledge.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import i18n from '@/plugins/i18n';
import Vue from 'vue';
import { View, ContextualBalloon, clickOutsideHandler } from '@ckeditor/ckeditor5-ui';
import CbmGiphyView from './CbmGiphyView.vue';

export default class CbmAddEntryPlugin extends Plugin {
  init() {
    const { editor } = this;

    this._balloon = this.editor.plugins.get(ContextualBalloon);

    editor.ui.componentFactory.add('giphy', (locale) => {
      const button = new ButtonView();

      button.label = 'GIF';
      // button.icon = iconKnowledge;
      button.tooltip = true;
      button.withText = true;
      button.class = 'c-editor__button--custom text-xs font-bold';

      this.listenTo(button, 'execute', () => {
        this._showUI();
      });

      const CbmGiphyViewClass = Vue.extend(CbmGiphyView);
      const cbmGiphyViewInstance = new CbmGiphyViewClass({
        i18n,
      });
      cbmGiphyViewInstance.$mount();
      cbmGiphyViewInstance.$on('select', (value) => {
        // Insert GIF.
        editor.execute('insertImage', { source: value.images.original.url });

        this._hideUI();
      });

      const container = cbmGiphyViewInstance.$el;

      this.giphyView = new View(locale);
      this.giphyView.setTemplate({ tag: 'div' });
      this.giphyView.render();
      this.giphyView.element.appendChild(container);

      clickOutsideHandler({
        emitter: this.giphyView,
        activator: () => this._balloon.visibleView === this.giphyView,
        contextElements: [this._balloon.view.element],
        callback: () => this._hideUI(),
      });

      return button;
    });
  }

  _getBalloonPositionData() {
    const { view } = this.editor.editing;
    const viewDocument = view.document;
    let target = null;

    // Set a target position by converting view selection range to DOM.
    target = () => view.domConverter.viewRangeToDom(
      viewDocument.selection.getFirstRange(),
    );

    return {
      target,
    };
  }

  _showUI() {
    this._balloon.add({
      view: this.giphyView,
      position: this._getBalloonPositionData(),
    });
  }

  _hideUI() {
    this._balloon.remove(this.giphyView);

    // Focus the editing view after closing the form view.
    this.editor.editing.view.focus();
  }
}
