
import { defineComponent } from 'vue';
import EntryAutocomplete from '@/components/common/EntryAutocomplete.vue';
import { BaseEntry } from '@/models/entry.schema';
import KnowledgeCard from '@/components/common/KnowledgeCard.vue';
import router from '@/router';

export default defineComponent({
  components: {
    EntryAutocomplete,
    KnowledgeCard,
  },

  props: {
    botUuid: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      formData: {
        knowledge: null as BaseEntry | null,
        description: this.description,
      },
    };
  },

  methods: {
    handleSelect(value: BaseEntry) {
      this.formData.knowledge = value;
      if (value.title) {
        this.formData.description = value.title;
      }
    },

    handleSubmit() {
      // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-explicit-any
      (this.$refs.addKnowledgeDropdown as any)?.instance?.toggle();

      if (this.formData.knowledge) {
        const payload = {
          flow_id: this.formData.knowledge.metadata?.flowNameCurrent,
        };

        this.$emit('submit', {
          url: `//chatbot?payload=${encodeURI(JSON.stringify(payload))}`,
          description: this.formData.description || this.formData.knowledge.title,
        });
      }

      this.formData.knowledge = null;
      this.formData.description = '';
    },

    handleToggle(active: boolean) {
      if (!active) {
        // Reset state
        this.formData.knowledge = null;
        this.formData.description = '';
      }
    },

    viewItem(entry: BaseEntry) {
      const routeData = router.resolve(`/bots/${this.botUuid}/${entry.type === 'form' ? 'form' : 'knowledge'}/edit/${entry.uuid}`);
      window.open(routeData.href, '_blank');
    },

    deleteKnowledge() {
      this.formData.knowledge = null;
    },
  },
});
