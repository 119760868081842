
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  methods: {
    isValid(field: string) {
      const pwd = this.password;
      if (!pwd) return false;
      if (field === 'min_8') return pwd.length >= 8;
      if (field === 'min_1up') return pwd.match(/[A-Z]/);
      if (field === 'min_1low') return pwd.match(/[a-z]/);
      if (field === 'min_1num') return pwd.match(/[0-9]/);
      if (field === 'min_1spe') return pwd.match(/[\W]/);
      return false;
    },
  },
});
