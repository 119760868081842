/* eslint-disable import/prefer-default-export, @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import i18n, { locales } from '@/plugins/i18n';
import { useUserStore } from './user.store';

/**
 * Handle i18n accross the application.
 * @see https://blog.logrocket.com/advanced-localization-techniques-vue-js/
 */
export const useI18nStore = defineStore('i18n', {
  state: () => ({
    locale: useLocalStorage('state.i18n.locale', i18n.locale),
  }),

  actions: {
    updateLocale(newLocale: string) {
      if (locales.includes(newLocale)) {
        i18n.locale = newLocale;
      } else {
        // Fallback to en-US
        i18n.locale = 'en-US';
      }

      this.locale = i18n.locale;

      if ((window as any).analytics) {
        const userStore = useUserStore();
        if (userStore.user && userStore.user.accountUuid) {
          (window as any).analytics.identify(userStore.user.accountUuid, {
            interfaceLanguage: this.locale,
          });
        }
      }
    },
  },
});
