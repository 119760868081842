
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    tag: {
      type: String,
      default: 'a',
    },

    active: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClass(): (string | Record<string, boolean>)[] {
      return ['ui-navbar-item', {
        'ui-navbar-item--active': this.active,
        'ui-navbar-item--hoverable': ['a'].includes(this.tag),
      }];
    },
  },
});
