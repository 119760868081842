
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    labelPosition: {
      type: String,
      default: null,
    },

    expanded: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rootClass(): string[] {
      return [
        this.labelPosition === 'on-border' ? 'o-field--floating' : '',
        this.expanded ? 'w-full' : '',
      ];
    },
  },
});
